export function leftPad(val: string, num: number) {
  return val.padStart(num, '0');
}

export function formatDateAsDate(date: Date) {
  return `${leftPad(String(date.getDate()), 2)}-${leftPad(String(date.getMonth() + 1), 2)}-${date.getFullYear()}`;
}

export function formatDateAsBackendDate(date: Date) {
  return `${date.getFullYear()}-${leftPad(String(date.getMonth() + 1), 2)}-${leftPad(String(date.getDate()), 2)}`;
}

export function formatDateAsDayOnly(date: Date) {
  return leftPad(String(date.getDate()), 2);
}

export function formatDateAsMonthAndDay(date: Date) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
}

export function formatMinutesAsTime(minutes: number | undefined) {
  if (minutes !== undefined) {
    const resultHours = leftPad(Math.floor(minutes / 60).toString(), 2);
    const resultMinutes = leftPad((minutes % 60).toString(), 2);
    return `${resultHours}:${resultMinutes}`;
  }
  return `00:00`;
}

export function formatDateAsHourAndMinute(date: Date) {
  return `${leftPad(String(date.getHours()), 2)}:${leftPad(String(date.getMinutes()), 2)}`;
}

export function formatDateAsDateHourAndMinute(date: Date) {
  return `${formatDateAsDate(date)} ${formatDateAsHourAndMinute(date)}`;
}

export function formatDateAsWeekNumber(pDate: Date) {
  const date = new Date(pDate.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
}

export function dateDifference(datepart: 'w' | 'd' | 'h' | 'n' | 's', fromdate: Date, todate: Date) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const diff: any = todate - fromdate;
  const divideBy = { w: 604800000, d: 86400000, h: 3600000, n: 60000, s: 1000 };

  return Math.floor(diff / divideBy[datepart]);
}

export function dateDifferenceHours(date1: Date, date2: Date) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Math.ceil((date1 - date2) / 1000 / 60 / 60);
}
