import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Text from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LocalStorageKeys } from '../../types';
import { useSnowFall } from './snow-fall';
import { ColoredButton } from '../../components';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import santaLogoBlack from '../../../public/images/santa-logo-black.png';
import santaLogoGreen from '../../../public/images/santa-logo-green.png';
import { ChristmasMode, ThemeMode } from '../types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Stack } from '@mui/material';

const alignmentStyle = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 5,
  justifyContent: 'center',
};

const christmasBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 600,
  boxShadow: 24,
  backgroundImage: `url('https://images.unsplash.com/photo-1482003297000-b7663a1673f1')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const textBox = {
  width: 400,
  height: 400,
  background: 'rgba(255,255,255, 0.7)',
  p: 2,
  textAlign: 'center',
};

export const ChristmasTheme = () => {
  // const { t } = useTranslation(); // TODO: Fix the translations before releasing it would be nice
  const showChristmas = localStorage.getItem(LocalStorageKeys.SHOW_CHRISTMAS_POP_UP);
  const loggedIn = localStorage.getItem(LocalStorageKeys.CHECK_LOGGED_IN);
  const [open, setOpen] = useState(showChristmas === null && loggedIn === 'True');
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(LocalStorageKeys.SHOW_CHRISTMAS_POP_UP, 'False');
    window.location.reload();
  };

  const handleSnow = () => {
    setOpen(false);
    localStorage.setItem(LocalStorageKeys.SHOW_CHRISTMAS_POP_UP, 'False');
    localStorage.setItem(LocalStorageKeys.SNOW, ChristmasMode.LET_IT_SNOW);
    window.location.reload();
  };

  const snowflakes = useSnowFall();

  return (
    <>
      <Modal disableAutoFocus={true} open={open} onClose={handleClose}>
        <Box sx={[christmasBox, alignmentStyle]}>
          <Box sx={[textBox, alignmentStyle]}>
            <img
              alt="santa"
              src={localStorage.getItem(LocalStorageKeys.THEME) === ThemeMode.LIGHT ? santaLogoGreen : santaLogoBlack}
              style={{ width: '100px' }}
            />
            <Text sx={{ fontSize: '35px' }}>Happy december!</Text>
            <Stack alignItems="center" direction="row" gap={2}>
              <AutoAwesomeIcon color="secondary" />
              <Text sx={{ fontSize: '25px' }}>Let it snow</Text>
              <AutoAwesomeIcon color="secondary" />
            </Stack>
            <ColoredButton sx={{ height: '42px' }} onClick={handleSnow}>
              <AcUnitIcon />
            </ColoredButton>
          </Box>
        </Box>
      </Modal>
      {snowflakes}
    </>
  );
};
