import React from 'react';
import { Box } from '@mui/material';
import { SaveTaxationsFilterMake } from './save-taxations-filter-make';
import { SaveTaxationsFilterModel } from './save-taxations-filter-model';
import { TextFilter, AppSearchParamKeys } from '@japieglobal/shared';

export const SavedTaxationsFilter = () => (
  <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
    <SaveTaxationsFilterMake />
    <SaveTaxationsFilterModel />
    <TextFilter searchParamKey={AppSearchParamKeys.LICENSE_PLATE} labelTranslationKey="LICENSE_PLATE" />
    <TextFilter searchParamKey={AppSearchParamKeys.USER_EMAIL} labelTranslationKey="USER" />
    <TextFilter searchParamKey={AppSearchParamKeys.DESCRIPTION} labelTranslationKey="Notes" />
  </Box>
);
