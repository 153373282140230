import React from 'react';
import Link from '@mui/material/Link';
import { Box } from '@mui/material';
import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared';
import { DataType } from '@japieglobal/shared/src/api/services';

export const LinkCell = ({ links }: { links: string }) => {
  const [{ data_type }] = useAppSearchParams([AppSearchParamKeys.DATA_TYPE]);

  const getActivityPageRoute = () => {
    if (data_type === DataType.MONITOR) return 'users/';
    else if (data_type === DataType.API) return 'service_accounts/';
    else if (data_type === DataType.PURCHASE) return 'activity_purchase_view/';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {links.split(',').map((value: string) => (
        <Link
          key={value}
          color="#000"
          href={`/admin/${getActivityPageRoute()}?switch_to_dealer=${value}&period=last_60_days&userFilter[dealer]=${value}`}
          target="_blank"
        >
          {value}
        </Link>
      ))}
    </Box>
  );
};
