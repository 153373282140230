import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterBuildYear = () => {
  const [{ make, model, fuel, gear, body }] = useAppSearchParams();
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.BUILD, make, model, fuel, gear, body }),
    [make, model, fuel, gear, body],
  );
  const filterDependencies = useMemo(
    () => ({
      [AppSearchParamKeys.MAKE]: make,
      [AppSearchParamKeys.MODEL]: model,
      [AppSearchParamKeys.FUEL]: fuel,
      [AppSearchParamKeys.GEAR]: gear,
      [AppSearchParamKeys.BODY]: body,
    }),
    [make, model, fuel, gear, body],
  );
  return (
    <Grid2 size={2}>
      <SelectFilter
        tabOnEnter
        hideResetButton
        searchParamKey={AppSearchParamKeys.BUILD}
        carHeaderOptions={carHeaderOptions}
        filterDependencies={filterDependencies}
        labelTranslationKey="BUILD_YEAR"
      />
    </Grid2>
  );
};
