import { AppSearchParamKeys, AppSearchParams } from '@japieglobal/shared';

const urlFormatBiggerSmaller = (value1: string | undefined | null, value2: string | undefined | null) => {
  const result: string[] = [];
  if (value1 != null) {
    result.push(`>${Number(value1)}`);
  }
  if (value2 != null) {
    result.push(`<${Number(value2)}`);
  }
  return result.length ? result.join(',') : undefined;
};

const getRangeValue = (value1: string | undefined | null, value2: string | undefined | null) => {
  return urlFormatBiggerSmaller(value1, value2);
};

const afterMapping = (params: any) => {
  params['build'] = getRangeValue(params[AppSearchParamKeys.BUILD_YEAR_FROM], params[AppSearchParamKeys.BUILD_YEAR_TO]);
  params['mileage'] = getRangeValue(params[AppSearchParamKeys.MILEAGE_FROM], params[AppSearchParamKeys.MILEAGE_TO]);
  params['rank_total'] = getRangeValue(
    params[AppSearchParamKeys.WINDOW_SIZE_FROM],
    params[AppSearchParamKeys.WINDOW_SIZE_TO],
  );
  params[AppSearchParamKeys.ITR] = getRangeValue(
    params[AppSearchParamKeys.ITR_FROM],
    params[AppSearchParamKeys.ITR_TO],
  );
  params[AppSearchParamKeys.ETR] = getRangeValue(
    params[AppSearchParamKeys.ETR_FROM],
    params[AppSearchParamKeys.ETR_TO],
  );
  params['itr'] = getRangeValue(params[AppSearchParamKeys.ITR_FROM], params[AppSearchParamKeys.ITR_TO]);
  params['etr'] = getRangeValue(params[AppSearchParamKeys.ETR_FROM], params[AppSearchParamKeys.ETR_TO]);
  params['apr'] = getRangeValue(params[AppSearchParamKeys.APR_FROM], params[AppSearchParamKeys.APR_TO]);
  params['price_local'] = getRangeValue(params[AppSearchParamKeys.PRICE_FROM], params[AppSearchParamKeys.PRICE_TO]);
  params['hp'] = getRangeValue(params[AppSearchParamKeys.HP_FROM], params[AppSearchParamKeys.HP_TO]);
  params['price_history_since'] = getRangeValue(
    params[AppSearchParamKeys.LAST_DISCOUNT_FROM],
    params[AppSearchParamKeys.LAST_DISCOUNT_TO],
  );
  params['rank_target_discount'] = getRangeValue(
    params[AppSearchParamKeys.DISCOUNT_FROM],
    params[AppSearchParamKeys.DISCOUNT_TO],
  );
  params['supply_type'] = params[AppSearchParamKeys.SUPPLY_TYPE];
  params['stock_days'] = getRangeValue(
    params[AppSearchParamKeys.STOCK_DAYS_FROM],
    params[AppSearchParamKeys.STOCK_DAYS_TO],
  );
  params['sold_since'] = getRangeValue(
    params[AppSearchParamKeys.SOLD_SINCE_FROM],
    params[AppSearchParamKeys.SOLD_SINCE_TO],
  );

  Object.keys(params).forEach((key) => params[key] === undefined && delete params[key]);

  return params;
};
export const getUrlParams = (filterObject: AppSearchParams) => {
  return afterMapping(filterObject);
};
