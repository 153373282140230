import React, { useCallback, useEffect } from 'react';
import { StringAppSearchParam } from '../../types';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { TextInput } from '../../../inputs';
import { useAppSearchParams } from '../../hooks';

export interface TextFilterProps extends BaseInputFilterProps<string | undefined, StringAppSearchParam> {
  width?: 'fill' | number;
  autoFocus?: boolean;
}

export const TextFilter = ({
  searchParamKey,
  labelTranslationKey,
  defaultValue,
  autoFocus,
  width,
  hideResetButton,
  tabOnEnter,
  ...rest
}: TextFilterProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();

  const value = searchParams[searchParamKey];

  const onHandleReset = () => {
    searchParams[searchParamKey] = undefined;
    setSearchParams(searchParams);
  };

  const onHandleChange = useCallback(
    (value: string | undefined) => {
      searchParams[searchParamKey] = value ? value.trim() : undefined;
      setSearchParams(searchParams);
    },
    [searchParams, searchParamKey, setSearchParams],
  );

  useEffect(() => {
    if (!value && defaultValue !== undefined) {
      onHandleChange(defaultValue);
    }
  }, [onHandleChange, value, defaultValue]);

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onHandleReset}>
      <TextInput
        tabOnEnter={tabOnEnter}
        label={t(labelTranslationKey)}
        width={width}
        autoFocus={autoFocus}
        setValue={onHandleChange}
        value={value || undefined}
        defaultValue={defaultValue}
        {...rest}
      />
    </InputWrapper>
  );
};
