import React, { useCallback, useEffect, useState } from 'react';
import { TableColDef, BaseTable, useAppSearchParams, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { salesColumns } from './sales-report-columns';
import { CellContent } from './cell-render-wrapper';
import {
  superAdminList,
  SuperAdminResponseType,
  SalesReportRow,
  CsmReportKeys,
} from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { HeaderFilterWrapper } from '../../shared/admin-and-sales-report/header-filter/header-filter-wrapper';

interface SalesReportTableProps {
  salesReportRows: SalesReportRow[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  getSalesReport: () => void;
}
export const SalesReportTable = ({
  isLoading,
  setIsLoading,
  salesReportRows,
  getSalesReport,
}: SalesReportTableProps) => {
  const [filteredSalesReportRows, setFilteredSalesReportRows] = useState<SalesReportRow[]>();
  const [searchParams] = useAppSearchParams();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [superAdmins, setSuperAdmins] = useState<SuperAdminResponseType[]>([]);

  const filterSalesReportRows = useCallback(
    (originalData: SalesReportRow[]) => {
      const searchParamsEntries = Object.entries(searchParams);
      let filteredRows = originalData;
      searchParamsEntries.forEach((entry) => {
        if (entry[0] !== AppSearchParamKeys.DATA_TYPE) {
          const column = salesColumns.find((c) => c.searchParamKey === entry[0]);
          filteredRows = filteredRows.filter((row) => {
            if (column) {
              if (column.selectType === 'multi-object') {
                return row[column.field]?.['value'].toString().includes(String(entry[1]));
              }
              if (column.selectType === 'multi') {
                return String(entry[1]).split(',').includes(String(row[column.field]));
              }
              return row[column.field]?.toString().includes(String(entry[1]));
            }
          });
        }
      });
      setFilteredSalesReportRows(filteredRows);
    },
    [searchParams],
  );

  useEffect(() => {
    filterSalesReportRows(salesReportRows);
  }, [filterSalesReportRows, salesReportRows]);

  useEffect(() => {
    superAdminList().then(setSuperAdmins).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  const columns: TableColDef<SalesReportRow>[] = salesColumns.map((column) => {
    const { field, headerName, flex, type, headerDescription, hoverRender } = column;
    return {
      field,
      flex,
      headerName,
      headerDescription,
      type,
      valueGetter: (value) => (hoverRender ? value['value'] : value),
      renderCell: (params) => (
        <CellContent
          setRowsIsLoading={setIsLoading}
          key={params.id}
          onDealerUpdated={getSalesReport}
          superAdmins={superAdmins}
          renderCellParams={params}
          cellRender={column.cellRender}
        />
      ),
      renderHeaderFilter: (headerFilterProps) => (
        <HeaderFilterWrapper column={column} headerFilterProps={headerFilterProps} superAdmins={superAdmins} />
      ),
    } as TableColDef<SalesReportRow>;
  });

  return (
    <BaseTable
      filterHeader
      rows={filteredSalesReportRows || salesReportRows}
      isLoading={isLoading}
      columns={columns}
      getRowId={(row) => row[CsmReportKeys.DEALER]}
      headerHeight={55}
      rowHeight={35}
      exportToPdf={false}
      pinnedRows={{
        top: [],
        bottom: [],
      }}
      showCellBorder={true}
    />
  );
};
