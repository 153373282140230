import { styled, Autocomplete } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTextInput, BaseTextInputProps } from '../../base-text-input/base-text-input';
import { SelectOption } from '../select-input/select-input';
import { noop } from '../../../../utils/noop';
import { LoadingWrapper } from '../../../loading-wrapper/loading-wrapper';
import { ColoredOutlinedButton } from '../../../colored-button';

const DropdownButton = styled(ColoredOutlinedButton)({
  border: 0,
});

const StyledSelectAllWrapper = styled(`div`)(({ theme }) => ({
  display: 'flex',
  borderTop: '1px solid',
  borderColor: theme.palette.info.light,
}));

const StyledMaxSelectedWrapper = styled(`span`)(({ theme }) => ({
  color: theme.palette.warning.main,
  fontSize: '13px',
  padding: '10px',
}));

interface ListBoxComponentProps {
  showSelectAll: boolean;
  showDeleteAll: boolean;
  selectAll?: (optionsToSelect: string[]) => void;
  deleteAll?: () => void;
  showError: boolean;
  maxSelectedOptions: number | undefined;
}

const ListBoxComponent = ({
  showDeleteAll,
  showSelectAll,
  deleteAll,
  selectAll,
  showError,
  maxSelectedOptions,
}: ListBoxComponentProps) =>
  React.forwardRef<HTMLDivElement>((props: { children?: any }, ref) => {
    const { t } = useTranslation();
    const selectShown = useCallback(() => {
      if (props && props.children && Array.isArray(props.children) && selectAll) {
        selectAll(getChildrenFromProps(props as any));
      }
    }, [props]);
    return (
      <div ref={ref}>
        <ul {...props} />
        {showError && (
          <StyledMaxSelectedWrapper>
            {t('MAX_SELECTED')} ({maxSelectedOptions})
          </StyledMaxSelectedWrapper>
        )}
        {(showDeleteAll || showSelectAll) && (
          <StyledSelectAllWrapper>
            {showSelectAll && <DropdownButton onMouseDown={selectShown}>{t('SELECT_ALL')}</DropdownButton>}
            {showDeleteAll && <DropdownButton onMouseDown={deleteAll}>{t('CLEAR_ALL')}</DropdownButton>}
          </StyledSelectAllWrapper>
        )}
      </div>
    );
  });

const getChildrenFromProps = (props: { children: any }) =>
  props
    .children!.filter((child: any) => child.type === 'li' && typeof child.props.children === 'string')
    .map((child: any) => child.props.children);

export interface IsolatedMultiSelectInputProps {
  freeSolo?: boolean;
  limitTags?: number;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  maxSelectedOptions?: number;
  disableClearable?: boolean;
  hideArrowIcon?: boolean;
}

export interface MultiSelectInputProps extends BaseTextInputProps<SelectOption[]>, IsolatedMultiSelectInputProps {
  isLoading?: boolean;
  options: SelectOption[];
}

export const MultiSelectInput = ({
  isLoading,
  setValue,
  value,
  options,
  disabled,
  defaultValue,
  freeSolo,
  disableCloseOnSelect,
  limitTags,
  placeholder,
  maxSelectedOptions,
  disableClearable,
  hideArrowIcon,
  ...baseProps
}: MultiSelectInputProps) => {
  const selectAll = (shownOptions: string[]) => {
    let newOptionsLength = shownOptions.length;
    if (maxSelectedOptions && value) {
      if (maxSelectedOptions - value.length <= shownOptions.length) {
        newOptionsLength = maxSelectedOptions - value.length;
      }
    }

    setValue([
      ...(value as SelectOption[]),
      ...Array(newOptionsLength)
        .fill(null)
        .map(
          (labelItem, index) => options.find((valueItem) => shownOptions[index] === valueItem.label) as SelectOption,
        ),
    ]);
  };

  const placeholderToDisplay = useMemo(() => {
    return value && value.length > 0 ? undefined : placeholder;
  }, [placeholder, value]);

  return (
    <LoadingWrapper isLoading={isLoading ?? false}>
      <Autocomplete
        popupIcon={hideArrowIcon === true ? false : undefined}
        multiple
        autoHighlight
        disabled={disabled}
        filterSelectedOptions={true}
        size="small"
        options={options}
        disableClearable={disableClearable}
        limitTags={limitTags}
        value={value || defaultValue || []}
        freeSolo={freeSolo}
        disableCloseOnSelect={disableCloseOnSelect}
        ListboxComponent={
          ListBoxComponent({
            selectAll,
            deleteAll: () => setValue([]),
            showSelectAll: true,
            showDeleteAll: true,
            showError: maxSelectedOptions && value && value.length === maxSelectedOptions ? true : false,
            maxSelectedOptions: maxSelectedOptions,
          }) as any
        }
        getOptionLabel={(option) => (typeof option === 'string' ? option : String(option.label))}
        getOptionDisabled={() => (maxSelectedOptions && value && value.length === maxSelectedOptions ? true : false)}
        onChange={(event, targetValue) => setValue(targetValue as SelectOption[])}
        isOptionEqualToValue={(option, value) => value.value === '' || option.value === value.value}
        renderInput={(params) => (
          <BaseTextInput
            {...params}
            {...baseProps}
            onChange={noop}
            setValue={noop}
            size="small"
            margin="none"
            placeholder={placeholderToDisplay}
          />
        )}
      />
    </LoadingWrapper>
  );
};
