import { SelectOption, emptySelectOption } from '..';
import { TFunction } from 'i18next';

export const valueToSelectOption = (value?: string | number | boolean): SelectOption =>
  value !== undefined
    ? {
        value: String(value),
        label: String(value),
      }
    : emptySelectOption;

export const valueToSelectOptionT = (t: TFunction, value?: string | number | boolean): SelectOption =>
  value !== undefined
    ? {
        value: String(value),
        label: t(String(value).toUpperCase()),
      }
    : emptySelectOption;

export const valuesToSelectOptions = (values?: (string | number | boolean)[]): SelectOption[] =>
  values !== undefined ? values.map(valueToSelectOption) : [];

export const valuesToSelectOptionsT = (t: TFunction, values?: string[]): SelectOption[] =>
  values !== undefined ? values.map((value) => valueToSelectOptionT(t, value)) : [];
