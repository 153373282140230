import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HintsProvider } from '../../shared/src/components/hints/hints-context';
import { FeedbackWidget } from '../../shared/src/feedback-widget';
import { UserContext } from '../../shared/src/user-context';
import { AdminMenuPage } from './admin/menu/admin-menu-page';
import { AdminPage } from './admin/admin-page';
import { AuthActivatePage } from './auth/auth-activate-page';
import { AuthForgotPage } from './auth/auth-forgot-page';
import { AuthLoginPage } from './auth/auth-login-page';
import { AuthResetPage } from './auth/auth-reset-page';
import { AuthResetPhonePage } from './auth/auth-reset-phone-page';
import { CarsPage } from './cars/cars-page';
import { UploadPage } from './cars/upload/upload-page';
import { TaxationPage } from './taxatie/taxation-page';
import { TinyUrlPage } from './tiny/tiny-url-page';
import { hasRequiredSettingsPermissions } from '@japieglobal/shared/src/components/top-row/first-bar/buttons/settings-button';
import { RedirectPage } from './redirect-page';
import { PageType } from './super-admin/super-admin-page-container';
import { RdwGetCar } from './super-admin/components/rdw/rdw-get-car';
import { RdwRefreshCar } from './super-admin/components/rdw/rdw-refresh-car';
import { RdwModelYearInfo } from './super-admin/components/rdw/rdw-modelyear-info';
import { RdwManualAdjustment } from './super-admin/components/rdw/rdw-manual-adjustment';
import { RdwAddCarToCatalog } from './super-admin/components/rdw/rdw-add-car-to-catalog';
import { EditTranslations } from './super-admin/components/edit-translations';
import { ClusterManagement } from './super-admin/components/cluster-management/cluster-management';
import { OptionsManagement } from './super-admin/components/options-management/options-management';
import { OptionsManagementEdit } from './super-admin/components/options-management/options-management-edit';
import { settings } from '@japieglobal/shared/src/settings';
import { OptionsManagementCreate } from './super-admin/components/options-management/options-management-create';
import { getUser, User, UserRoles } from '@japieglobal/shared/src/api/services';
import { RdwAddModelYear } from './super-admin/components/rdw/rdw-add-model-year';
import { SalesMap } from './super-admin/sales-map/sales-map';
import { CarErrorsDownload } from './super-admin/components/car-errors-download';
import { Taxations } from './saved-taxations/taxations';
import { RdwGetPlatesByCarType } from './super-admin/components/rdw/rdw-get-plates-by-car-type';
import { DealerUsersProvider } from './states/dealer-users-context';
import { ClicksConfigs } from './super-admin/components/clicks-configs/clicks-configs';
import { ClicksConfigEdit } from './super-admin/components/clicks-configs/clicks-config-edit';
import { ClicksConfigCreate } from './super-admin/components/clicks-configs/clicks-config-create';
import { Jpcglc } from './terms-and-conditions/jpcglc';
import { PrivacyTerms } from './terms-and-conditions/privacy-terms';
import { LinkCarToStandardOptions } from './super-admin/components/options-management/Link-car-to-standard-options/link-car-to-standard-options';
import { LocalStorageKeys } from '@japieglobal/shared/src/types';

export const AppContainer = () => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { i18n } = useTranslation();

  const defaultPath = useMemo(() => {
    if (user?.permissions.includes('monitoring')) return '/cars';
    if (user?.permissions.includes('taxation')) return '/taxatie';
    if (user?.role === UserRoles.SCRAPE_USER) return '/admin-scrape';
    return '/cars';
  }, [user]);

  const checkLoggedIn = useCallback(() => {
    getUser()
      .then((res) => {
        setUser(res);
        Sentry.setUser({ email: res.email });
        i18n.changeLanguage(res.language.toLowerCase()).then();
        const notLoggedIn = !localStorage.getItem(LocalStorageKeys.CHECK_LOGGED_IN);
        localStorage.setItem(LocalStorageKeys.CHECK_LOGGED_IN, 'True');
        if (notLoggedIn) {
          window.location.reload();
        }
      })
      .catch(() => {
        setUser(undefined);
        Sentry.setUser(null);
        i18n.changeLanguage(settings().fallbackLanguage).then();
      })
      .finally(() => setLoaded(true));
  }, [i18n]);

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  if (!loaded) return <></>;
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <HintsProvider>
      <DealerUsersProvider>
        <SnackbarProvider maxSnack={3}>
          <FeedbackWidget user={user} />
          {user ? (
            <UserContext.Provider value={{ user, setUser }}>
              <SentryRoutes>
                <Route path="/jpcglc" element={<Jpcglc />} />
                <Route path="/jpcps" element={<PrivacyTerms />} />
                <Route path="/privacyverklaring" element={<PrivacyTerms />} />
                {user.permissions.includes('monitoring') && <Route path="/cars" element={<CarsPage />} />}
                {hasRequiredSettingsPermissions(user) && <Route path="/admin" element={<AdminMenuPage />} />}
                {(user.role === UserRoles.ADMIN ||
                  user.role === UserRoles.SUPER_ADMIN ||
                  (user.role === UserRoles.SCRAPE_USER && user.permissions.includes('scrapeManagement'))) && (
                  <Route path="/admin/:pageType" element={<AdminPage />} />
                )}
                {(user.permissions.includes('fileValuation') ||
                  user.permissions.some((p) => p.startsWith('upload_'))) && (
                  <>
                    <Route path="/valuate" element={<UploadPage />} />
                    <Route path="/upload" element={<UploadPage />} />
                  </>
                )}
                {user.permissions.includes('taxation') && (
                  <>
                    <Route path="/taxatie" element={<TaxationPage />} />
                    <Route path="/taxations" element={<Taxations />} />
                  </>
                )}
                {user.role === UserRoles.SUPER_ADMIN && (
                  <Route path={`/super-admin`}>
                    <Route
                      index
                      element={
                        <Navigate to={settings().country == 'nl' ? PageType.PLATE : PageType.CLUSTER_MANAGEMENT} />
                      }
                    />
                    {settings().country == 'nl' && (
                      <>
                        <Route path={PageType.PLATE} element={<RdwGetCar />} />
                        <Route path={PageType.PLATES} element={<RdwGetPlatesByCarType />} />
                        <Route path={PageType.REFRESH} element={<RdwRefreshCar />} />
                        <Route path={PageType.MODEL_YEAR} element={<RdwModelYearInfo />} />
                        <Route path={PageType.MANUAL_ADJUSTMENT} element={<RdwManualAdjustment />} />
                        <Route path={PageType.CATALOG} element={<RdwAddCarToCatalog />} />
                        <Route path={PageType.CO2_MODEL_YEAR} element={<RdwAddModelYear />} />
                        <Route path={PageType.TRANSLATIONS} element={<EditTranslations />} />
                      </>
                    )}
                    <Route path={PageType.MAP} element={<SalesMap />} />
                    <Route path={PageType.CLUSTER_MANAGEMENT} element={<ClusterManagement />} />
                    <Route path={PageType.OPTIONS_MANAGEMENT}>
                      <Route path="" element={<OptionsManagement />} />
                      <Route path="edit/:optionId" element={<OptionsManagementEdit />} />
                      <Route path="create" element={<OptionsManagementCreate />} />
                      <Route path="car-options-linking" element={<LinkCarToStandardOptions />} />
                    </Route>
                    <Route path={PageType.CAR_ERRORS} element={<CarErrorsDownload />} />
                    <Route path={PageType.CLICKS_CONFIG}>
                      <Route path="" element={<ClicksConfigs />} />
                      <Route path="edit/:dealer/:propertyId" element={<ClicksConfigEdit />} />
                      <Route path="create" element={<ClicksConfigCreate />} />
                    </Route>
                  </Route>
                )}
                <Route path="/tiny/:key" element={<TinyUrlPage />} />
                {(user.permissions.includes('scrapeManagement') || user.role === UserRoles.SCRAPE_USER) && (
                  <Route path="/admin-scrape" element={<RedirectPage />} />
                )}
                <Route path="*" element={<Navigate replace to={defaultPath} />} />
              </SentryRoutes>
            </UserContext.Provider>
          ) : (
            <SentryRoutes>
              <Route path="/jpcglc" element={<Jpcglc />} />
              <Route path="/privacyverklaring" element={<PrivacyTerms />} />
              <Route path="/auth/activate" element={<AuthActivatePage />} />
              <Route path="/auth/reset" element={<AuthResetPage />} />
              <Route path="/auth/reset/phone" element={<AuthResetPhonePage />} />
              <Route path="/auth/forgot" element={<AuthForgotPage type={'password'} />} />
              <Route path="/auth/forgot/phone" element={<AuthForgotPage type={'phoneNumber'} />} />
              <Route path="*" element={<AuthLoginPage setLoggedIn={checkLoggedIn} />} />
            </SentryRoutes>
          )}
        </SnackbarProvider>
      </DealerUsersProvider>
    </HintsProvider>
  );
};
