import { LightThemeColors } from './theme-colors';

export const LightPalette = {
  primary: {
    main: LightThemeColors.PRIMARY,
    light: LightThemeColors.PRIMARY_LIGHT,
    contrastText: LightThemeColors.PRIMARY_TEXT,
  },
  secondary: {
    main: LightThemeColors.SECONDARY,
    dark: LightThemeColors.SECONDARY_DARK,
    contrastText: LightThemeColors.SECONDARY_TEXT,
  },
  warning: {
    main: LightThemeColors.WARNING,
    contrastText: LightThemeColors.WARNING_TEXT,
  },
  info: {
    main: LightThemeColors.INFO,
    contrastText: LightThemeColors.INFO_TEXT,
  },
  background: {
    default: LightThemeColors.BACKGROUND,
  },
};
