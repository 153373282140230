import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../../../states/click-states';
import { useTranslation } from 'react-i18next';
import { CarWindowEquipment } from './car-window-equipment';

const getColorByCar = (value: number) => {
  if (value > 0 && value < 30) return '#EFC241'; //yellow
  if (value >= 30 && value < 60) return '#E27D3B'; //orange
  return '#E34E47'; //red
};

export const CarWindowEquipmentCell = React.memo(
  ({
    car,
    clicks,
    isOfficialMake,
  }: {
    car: CarResult;
    clicks?: ClicksPassedDown;
    order?: string[];
    isOfficialMake: boolean;
  }) => {
    const { t } = useTranslation();
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
        {isOfficialMake && <CarWindowEquipment car={car} clicks={clicks} equipmentOption={'official_make'} />}
        <CarWindowEquipment car={car} clicks={clicks} equipmentOption={'is_dealer'} />
        <CarWindowEquipment
          car={car}
          clicks={clicks}
          equipmentOption={'in_stock'}
          onHoverContent={`${car.sold?.since} ${t('DAYS_AGO')}`}
          useClickState
          hideBadge={false}
          badgeColor={car.sold?.since && car.sold?.since > 0 ? getColorByCar(car.sold.since) : undefined}
        />
      </div>
    );
  },
);
