import { BooleanAppSearchParam } from './boolean-app-search-param';
import { StringAppSearchParam } from './string-app-search-param';
import { StringListAppSearchParam } from './string-list-app-search-param';
import { NumberAppSearchParam } from './number-app-search-param';
import { NumberListAppSearchParam } from './number-list-app-search-param';

export const AppSearchParamKeys = {
  ...BooleanAppSearchParam,
  ...StringAppSearchParam,
  ...NumberAppSearchParam,
  ...StringListAppSearchParam,
  ...NumberListAppSearchParam,
};

export type AppSearchParamKey =
  | StringListAppSearchParam
  | StringAppSearchParam
  | NumberAppSearchParam
  | BooleanAppSearchParam
  | NumberListAppSearchParam;
