import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ColoredButton, SelectInput, SelectOption, TextInput, ToggleInput } from '@japieglobal/shared/src/components';
import { Fuel, Gear, Body } from '@japieglobal/shared/src/types';
import { RdwPageContainer } from './rdw-page-container';
import { rdwManualAdjustment } from '@japieglobal/shared/src/api/services';

export const RdwManualAdjustment = () => {
  const [fuel, setFuel] = useState<SelectOption | undefined>();
  const [gear, setGear] = useState<SelectOption | undefined>();
  const [body, setBody] = useState<SelectOption | undefined>();
  const [photo, setPhoto] = useState<string | undefined>();
  const [licensePlate, setLicensePlate] = useState<string | undefined>();
  const [blocked, setBlocked] = useState<boolean | undefined>();
  const { t } = useTranslation();
  const { snackbarSuccessMessage, snackbarErrorHandler } = useSnackbarErrorHandler();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    rdwManualAdjustment({
      licensePlate: licensePlate!,
      fuel: fuel?.value as Fuel,
      gear: gear?.value as Gear,
      body: body?.value as Body,
      photo,
      blocked,
    })
      .then(() => snackbarSuccessMessage('License plate successfully updated'))
      .catch(snackbarErrorHandler);
  };

  return (
    <RdwPageContainer onSubmit={onSubmit}>
      <b>{t('ADJUST_RDW_CATALOG')}</b>
      <TextInput label={t('LICENSE_PLATE')} value={licensePlate} setValue={setLicensePlate} required />
      <SelectInput
        label={t('FUEL')}
        value={fuel}
        setValue={setFuel}
        options={Object.values(Fuel).map((fuel) => ({ label: t(fuel), value: fuel }))}
      />
      <SelectInput
        label={t('GEAR')}
        value={gear}
        setValue={setGear}
        options={Object.values(Gear).map((gear) => ({ label: t(gear), value: gear }))}
      />
      <SelectInput
        label={t('BODY')}
        value={body}
        setValue={setBody}
        options={Object.values(Body).map((body) => ({ label: t(body), value: body }))}
      />
      <TextInput label={t('PHOTO')} value={photo} setValue={setPhoto} />
      <ToggleInput toggleState={blocked} disableTripleState setToggleState={setBlocked} label={t('BLOCKED')} />
      <ColoredButton type="submit">{t('SUBMIT')}</ColoredButton>
    </RdwPageContainer>
  );
};
