import React, { useState, useEffect, useContext } from 'react';
import { BaseTextInputProps, BaseTextInput } from '../base-text-input/base-text-input';
import { styled } from '@mui/system';
import { TextFieldVariants } from '@mui/material';
import { UserContext } from '../../../user-context';
import { getCurrencySymbolAndPosition } from '../../../utils/currency-utils';

export interface NumberInputProps extends Omit<BaseTextInputProps<string>, 'setValue' | 'value' | 'defaultValue'> {
  value?: number;
  defaultValue?: number;
  setValue: (value: number | undefined) => void;
  min?: number;
  max?: number;
  className?: string;
  variant?: TextFieldVariants;
  isMoneyInput?: boolean;
}

const StyledNumberInput = styled(BaseTextInput)({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    'margin': 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    'margin': 0,
  },
}) as typeof BaseTextInput;

export const NumberInput = ({
  min = 0,
  max,
  setValue,
  defaultValue,
  value,
  className,
  variant,
  isMoneyInput,
  startAdornment,
  endAdornment,
  ...baseProps
}: NumberInputProps) => {
  const [tempValue, setTempValue] = useState<string | undefined>(String(value ?? defaultValue ?? ''));
  const { user } = useContext(UserContext);

  const { position, symbol } = getCurrencySymbolAndPosition(user);

  const setTempIfIsNumber = (target: string) =>
    (!isNaN(Number(target)) || target === '-') && setTempValue(target.trim() !== '' ? target : undefined);

  useEffect(() => {
    if (value === undefined) {
      setTempValue(defaultValue !== undefined ? String(defaultValue) : '');
    }
    setTempIfIsNumber(String(value));
  }, [defaultValue, value]);

  const checkIfExceedsLimits = (inputValue: string) => {
    const inputValueNumber = Number(inputValue);
    if (inputValue === '' && defaultValue === undefined) return undefined;
    else if (max && inputValueNumber > max) return max;
    else if (min && min < 0 && inputValue === '') return min;
    else if (min && inputValueNumber < min) return min;
    else return inputValueNumber;
  };

  const handleSetValue = (inputValue: string) => {
    const inputValueCapped = checkIfExceedsLimits(inputValue);
    setValue(inputValueCapped);
    setTempValue(inputValueCapped !== undefined ? String(inputValueCapped) : undefined);
  };

  return (
    <StyledNumberInput
      onChange={setTempIfIsNumber}
      value={tempValue !== undefined ? String(tempValue) : ''}
      setValue={handleSetValue}
      inputProps={{
        min,
        max,
      }}
      type="text"
      className={className}
      variant={variant}
      startAdornment={isMoneyInput && position === 'start' ? symbol : startAdornment}
      endAdornment={isMoneyInput && position === 'end' ? symbol : endAdornment}
      {...baseProps}
    />
  );
};
