import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterItr = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.ITR_FROM, AppSearchParamKeys.ITR_TO]}
    defaultValue={[0, 5]}
    min={0}
    max={5}
    labelTranslationKey="ITR"
  />
);
