import { instance } from './instance';
import { responseFileDownload } from '../utils/downloadFile';
import { CarResult } from '../../types';
import { Pageable } from '../../types/pagination';
import { ClickState } from '../../types/click-state';

export interface SavedTaxationQuery {
  license_plate?: string;
  make?: string;
  model?: string;
  fuel?: string;
  gear?: string;
  body?: string;
  build?: number;
  mileage?: number;
  page_index?: number;
}

export interface SaveTaxation extends SavedTaxationQuery {
  stat_turnover_int?: number;
  stat_turnover_ext?: number;
  apr?: number;
  rank_target_value?: number;
  rank_target?: number;
  rank_current?: number;
  rank_total?: number;
  topdown_value?: number;
  description?: string;
  user_email?: string;
  hp?: number;
  options?: string;
  equipment_four_doors?: boolean;
  equipment_is_dealer?: boolean;
  click_equipment_is_dealer?: boolean;
  click_model_year?: boolean;
  click_build?: boolean;
  click_equipment_in_stock?: ClickState | undefined;
  click_stat_local_car?: boolean;
  click_hp?: boolean;
  click_own_supply?: boolean;
  click_options?: string;
  click_dealer?: string;
  click_location?: boolean;
  created?: string;
  extra_costs?: number;
  extra_margin?: number;
}

export interface SavedTaxation extends SaveTaxation {
  id: number;
}

export const getSavedTaxation = async (
  dealer: string,
  params: SavedTaxationQuery,
): Promise<{ pageable: Pageable; results: SavedTaxation[] }> =>
  instance.get(`/api/dealers/${dealer}/taxations`, { params: params }).then((res) => res.data);

export const exportSavedTaxation = async (dealer: string, params: SavedTaxationQuery) =>
  instance
    .get(`/api/dealers/${dealer}/taxations/export`, { params: params, responseType: 'blob' })
    .then(responseFileDownload('taxations.csv'));

export const saveTaxation = async (
  car: CarResult,
  description: string | undefined,
  click_options: string | null,
  click_equipment_in_stock: string | null,
  extra_costs: string | null,
  extra_margin: string | null,
): Promise<SavedTaxation> => {
  const body: SaveTaxation = {
    license_plate: car.license_plate,
    make: car.make,
    model: car.model,
    fuel: car.fuel,
    gear: car.gear,
    body: car.body,
    build: car.build,
    mileage: car.mileage,
    stat_turnover_int: car.stat?.turnover_int,
    stat_turnover_ext: car.stat?.turnover_ext,
    apr: car.apr,
    rank_target_value: car.rank?.target_value,
    rank_target: car.rank?.target,
    rank_current: car.rank?.current,
    rank_total: car.rank?.total,
    topdown_value: car.topdown_value,
    hp: car.hp,
    description,
    options: car.options,
    equipment_four_doors: car.equipment?.four_doors,
    equipment_is_dealer: car.equipment?.is_dealer,
    click_equipment_is_dealer: car.click?.equipment_is_dealer,
    click_model_year: car.click?.model_year,
    click_build: car.click?.build,
    click_equipment_in_stock: click_equipment_in_stock as ClickState,
    click_hp: car.click?.hp,
    click_own_supply: car.click?.own_supply,
    click_options: click_options ? JSON.parse(click_options) : undefined,
    click_dealer: car.click?.dealer,
    click_location: car.click?.location,
    extra_costs: Number(extra_costs) ?? undefined,
    extra_margin: Number(extra_margin) ?? undefined,
  };
  return instance.post(`/api/dealers/${car?.dealer?.name}/taxations`, body).then((res) => res.data);
};

export const deleteSavedTaxation = async (dealer: string, taxationsId: number[]) =>
  instance.post(`/api/dealers/${dealer}/taxations/delete`, taxationsId);
