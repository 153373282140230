import React from 'react';
import { useParams } from 'react-router-dom';
import { AdminMainView } from './admin-main-view';
import { AdminPageType } from './hooks/admin-query-state';

export const AdminPage = () => {
  const { pageType } = useParams();

  return <AdminMainView pageType={pageType as AdminPageType} />;
};
