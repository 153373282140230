import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterModelYear = () => {
  const [{ make, model, fuel, gear, body, build, hp }] = useAppSearchParams();
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.MODEL_YEAR, make, model, fuel, gear, body, build, hp }),
    [make, model, fuel, gear, body, build, hp],
  );
  const filterDependencies = useMemo(
    () => ({
      [AppSearchParamKeys.MAKE]: make,
      [AppSearchParamKeys.MODEL]: model,
      [AppSearchParamKeys.FUEL]: fuel,
      [AppSearchParamKeys.GEAR]: gear,
      [AppSearchParamKeys.BODY]: body,
      [AppSearchParamKeys.BUILD]: build,
      [AppSearchParamKeys.HP]: hp,
    }),
    [make, model, fuel, gear, body, build, hp],
  );
  return (
    <Grid2 size={2}>
      <SelectFilter
        hideResetButton
        filterDependencies={filterDependencies}
        searchParamKey={AppSearchParamKeys.MODEL_YEAR}
        carHeaderOptions={carHeaderOptions}
        labelTranslationKey="MODEL_YEAR"
      />
    </Grid2>
  );
};
