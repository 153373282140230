import { createTheme } from '@mui/material/styles';
import { LightPalette } from './palettes/light-palette';
import { DarkPalette } from './palettes/dark-palette';
import { LightThemeColors, DarkThemeColors } from './palettes/theme-colors';
import { ThemeProps, ImageFilterProps, ThemeMode } from './types';

declare module '@mui/material/styles' {
  interface Theme {
    imageFilter: ImageFilterProps;
  }
  interface ThemeOptions {
    imageFilter: ImageFilterProps;
  }
}

const theme: { [key in ThemeMode]: ThemeProps } = {
  light: {
    colors: LightThemeColors,
    palette: LightPalette,
    darkImageFilter: undefined,
    greyImageFilter: undefined,
    lightImageFilter: 'brightness(0) invert(1)',
  },
  dark: {
    colors: DarkThemeColors,
    palette: DarkPalette,
    greyImageFilter: 'brightness(0) saturate(100%) invert(50%) sepia(2%)',
    darkImageFilter: 'brightness(0%)',
    lightImageFilter: 'brightness(0) invert(1)',
  },
};

export const Theme = (color: ThemeMode) => {
  const colors = theme[color].colors;
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: '"IBM Plex Sans", sans-serif',
    },
    imageFilter: {
      darkFilter: theme[color]['darkImageFilter'],
      lightFilter: theme[color]['lightImageFilter'],
      greyFilter: theme[color]['greyImageFilter'],
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          fontFamily: 'IBM Plex Sans',
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: '#fff',
            color: '#333',
          },
          input: {
            fontSize: '13px',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            fontSize: '13px',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '13px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            opacity: 0.5,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: colors.PRIMARY,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '13px',
            fontWeight: 400,
          },
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              border: `1px solid ${colors.INFO_LIGHT}`,
              textTransform: 'none',
            },
          },
        ],
      },
    },
    palette: theme[color]['palette'],
  });
};
