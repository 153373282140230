import React, { useContext } from 'react';
import { ChatMessageBubbleOther, ChatMessageBubbleOwn, EmptyChatMessageBubble } from './chat-message-bubbles';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { Message } from '@japieglobal/shared/src/api/services';

interface ChatMessageBubbleProps {
  messages?: Message[];
  onDelete?: (commentId: number) => void;
  onEdit?: (commentId: number, message: string) => void;
}

export const ChatMessageBubble = ({ messages, onDelete, onEdit }: ChatMessageBubbleProps) => {
  const { user } = useContext(UserContext);

  return (
    <>
      {messages &&
        messages.map((message) => {
          if (message.user_id !== user.id) {
            return <ChatMessageBubbleOther key={message.id} msg={message} />;
          }
          return <ChatMessageBubbleOwn key={message.id} msg={message} onDelete={onDelete} onEdit={onEdit} />;
        })}
      {(!messages || messages.length === 0) && <EmptyChatMessageBubble />}
    </>
  );
};
