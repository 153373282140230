import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../../shared/src/components';
import { UploadFileView } from './upload-file-view';
import { ValuateFileView } from './valuate-file-view';
import { UserContext } from '@japieglobal/shared/src/user-context';

const TitleStyled = styled(Typography)({
  fontSize: 15,
  color: '#484848',
});

export const UploadMain = React.memo(function UploadMain() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState('valuation');
  const { user } = useContext(UserContext);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box mt={10}>
      {user.permissions.includes('fileValuation') && (
        <Accordion expanded={expanded === 'valuation'} onChange={handleChange('valuation')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TitleStyled>{t('FILE_VALUATION')}</TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <ValuateFileView />
          </AccordionDetails>
        </Accordion>
      )}
      {user.permissions.some((p) => p.startsWith('upload_')) && (
        <Accordion expanded={expanded === 'upload'} onChange={handleChange('upload')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <TitleStyled>{t('UPLOAD_FILE')}</TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <UploadFileView />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
});

export const UploadMainView = React.memo(() => {
  const mainComponent = useMemo(() => <UploadMain />, []);

  return <PageContainer internal hideResetAllButton mainComponent={mainComponent} />;
});
