import React from 'react';
import {
  GridColDef,
  GridPaginationModel,
  GridValidRowModel,
  GridRowOrderChangeParams,
  GRID_REORDER_COL_DEF,
  DataGridPro,
  gridClasses,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { ExportToolbar } from './export-toolbar';
import { Tooltip } from '../tooltip/tooltip';
import { BaseTableProps } from './types';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  'fontSize': '12px',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    top: 0,
    zIndex: 1,
  },
  [`.${gridClasses.main}`]: {
    overflow: 'unset',
  },
  [`.${gridClasses.virtualScroller}`]: {
    marginTop: '0 !important',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.itemsAtStart': {
    alignItems: 'flex-start',
  },
  'backgroundColor': theme.palette.grey[50],
  'div[class*="even"]': {
    backgroundColor: theme.palette.grey[300],
  },
  '.MuiDataGrid-row.Mui-selected': {
    'backgroundColor': theme.palette.grey[200],
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  // TODO fix styling without adding different classes
  'div[class*="highlightedRow"]': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cellContent': {
    whiteSpace: 'normal',
    lineHeight: 'normal',
  },
  '.multipleLines': {
    whiteSpace: 'normal !important',
    textAlign: 'center',
  },
  '.MuiDataGrid-sortIcon': {
    fontSize: '11px',
    color: theme.palette.primary.contrastText,
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer svg': {
    color: theme.palette.primary.contrastText,
  },
})) as typeof DataGridPro;

export const TruncatedText = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const BaseTable = <R extends GridValidRowModel>({
  rows,
  columns,
  getRowId,
  headerHeight,
  rowHeight,
  width,
  rowGroupingModel,
  getRowClassName,
  onRowSelectionChange,
  onRowClick,
  exportToPdf = false,
  exportToCsv = false,
  pinnedRows = { top: [], bottom: [] },
  filterHeader = false,
  showCellBorder = false,
  isLoading = false,
  disableStickyHeader = false,
  columnGroupingModel = [],
  useStripedRows = true,
  columnVisibility,
  hideFooter = true,
  paginationModel,
  sortingModel,
  reorderModel,
  error,
  sx,
}: BaseTableProps<R>) => {
  const { t } = useTranslation();

  const customToolbar = () => <ExportToolbar exportToPdf={exportToPdf} exportToCsv={exportToCsv} />;

  const mappedColumns: GridColDef[] = columns.map(
    ({ field, renderCell, isTruncated, align, headerAlign, renderHeader, ...column }) =>
      ({
        align: align ?? 'center',
        headerAlign: headerAlign ?? 'center',
        field: field as string,
        display: 'flex',

        renderCell: (params) =>
          renderCell ? (
            isTruncated ? (
              <TruncatedText>{renderCell(params)}</TruncatedText>
            ) : (
              renderCell(params)
            )
          ) : undefined,

        renderHeader: (params) =>
          !renderHeader && column.headerName ? (
            <Tooltip onHoverContent={t(column.headerDescription || column.headerName)}>
              <Box style={{ whiteSpace: 'normal', lineHeight: 'normal', textAlign: headerAlign ?? 'center' }}>
                {t(column.headerName)}
              </Box>
            </Tooltip>
          ) : (
            renderHeader?.(params)
          ),

        ...column,
      }) as GridColDef,
  );

  if (reorderModel?.reorderColumn) {
    mappedColumns.push({
      ...GRID_REORDER_COL_DEF,
      ...(reorderModel?.reorderColumn ? { ...reorderModel?.reorderColumn, display: 'flex' } : undefined),
    });
  }

  return (
    <StyledDataGrid
      loading={isLoading}
      disableRowSelectionOnClick={!onRowClick}
      onRowClick={(params) => onRowClick?.(params.row)}
      disableColumnMenu
      rows={rows}
      columns={mappedColumns}
      getRowId={(row) => getRowId(row as R)}
      autoHeight={true}
      hideFooter={hideFooter}
      columnHeaderHeight={headerHeight}
      rowHeight={rowHeight}
      checkboxSelection={!!onRowSelectionChange}
      onRowSelectionModelChange={onRowSelectionChange}
      getRowHeight={!rowHeight ? () => 'auto' : undefined}
      getRowClassName={(params) => {
        const extraClassName = getRowClassName ? getRowClassName(params.row as R) : '';
        const stripedClassName = useStripedRows
          ? params.indexRelativeToCurrentPage % 2 === 0
            ? `odd ${extraClassName}`
            : `even ${extraClassName}`
          : extraClassName;
        return stripedClassName;
      }}
      slots={{
        toolbar: customToolbar,
        headerFilterMenu: null,
        // When no treedata is avaliable but it is used, than these should be put on empty.
        // groupingCriteriaCollapseIcon: <></>
        // groupingCriteriaExpandIcon: <></>
      }}
      localeText={{
        toolbarExportPrint: t('DOWNLOAD_PDF'),
        toolbarExport: t('EXPORT'),
        noRowsLabel: t(error ?? 'FAILED_DATA'),
      }}
      // Used SX because passing props in styled function of MUI was not possible.
      // Try to not us SX if not needed
      sx={[
        {
          'width': width,
          '& .MuiDataGrid-columnHeaders': {
            position: disableStickyHeader ? undefined : 'sticky',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      pinnedRows={pinnedRows}
      headerFilters={filterHeader}
      disableColumnFilter
      showCellVerticalBorder={showCellBorder}
      columnGroupingModel={columnGroupingModel}
      pagination={!!paginationModel}
      pageSizeOptions={paginationModel?.pageSizeOptions}
      paginationMode={paginationModel?.paginationMode ?? 'client'}
      rowCount={paginationModel?.rowCount}
      onPaginationModelChange={(model: GridPaginationModel) => {
        paginationModel?.onPaginationModelChange ? paginationModel?.onPaginationModelChange(model) : undefined;
      }}
      paginationModel={{
        pageSize: paginationModel?.pageSize ?? 25,
        page: paginationModel?.pageIndex ?? 0,
      }}
      sortModel={sortingModel?.sortModel}
      sortingMode={sortingModel?.sortingMode ?? 'client'}
      onSortModelChange={sortingModel?.handleSortModelChange}
      columnVisibilityModel={columnVisibility}
      treeData={!!rowGroupingModel}
      getTreeDataPath={rowGroupingModel?.getRowGroupingPath}
      isGroupExpandedByDefault={rowGroupingModel?.isGroupExpandedByDefault}
      rowReordering={!!reorderModel}
      onRowOrderChange={(params: GridRowOrderChangeParams) => {
        reorderModel?.reorder ? reorderModel?.reorder(params.oldIndex, params.targetIndex) : undefined;
      }}
      groupingColDef={{
        renderHeader: () =>
          rowGroupingModel && (
            <Tooltip onHoverContent={t(rowGroupingModel.headerDescription || rowGroupingModel.headerName)}>
              <Box
                style={{
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                  textAlign: rowGroupingModel.headerAlign ?? 'center',
                }}
              >
                {t(rowGroupingModel.headerName)}
              </Box>
            </Tooltip>
          ),
        ...rowGroupingModel,
      }}
    />
  );
};
