import { Link, styled } from '@mui/material';
import React from 'react';
import { ColoredButtonSmall } from '../../cars/results/result-table-styles';
import LanguageIcon from '@mui/icons-material/Language';

const RedirectButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});

export const RedirectPurchaseButton = ({ url }: { url: string }) => {
  return (
    <RedirectButton
      component={Link}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target="_blank"
      href={url}
    >
      <LanguageIcon fontSize="small" />
    </RedirectButton>
  );
};
