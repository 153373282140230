import React, { useContext } from 'react';
import { BaseTable, TableColDef } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { CarWindowColumn, CarWindowColumns } from './car-window-columns';
import { ClicksPassedDown } from '../../states/click-states';
import { CarWindowTableCells } from './car-window-table-cells';
import { CarWindowHeaderCells } from './car-window-table-header-cells';

interface CarWindowTableProps {
  cars: CarResult[];
  ownCar: CarResult;
  clicks: ClicksPassedDown;
  reorder: (source: number, destination: number) => void;
  removeRanking: () => void;
  taxationView: boolean;
  error?: string;
}

export const CarWindowTable = ({
  cars,
  ownCar,
  clicks,
  reorder,
  removeRanking,
  taxationView,
  error,
}: CarWindowTableProps) => {
  const { user } = useContext(UserContext);

  const mapCarWindowColumn = ({
    flex = 1,
    columnType = 'basic',
    headerType = 'basic',
    headerDescription,
    headerName,
    headerAlign = 'center',
    clickName,
    field,
    ...rest
  }: CarWindowColumn): TableColDef<CarResult> => ({
    ...rest,
    flex,
    sortable: false,
    headerAlign,
    field,
    renderHeader: () => (
      <CarWindowHeaderCells
        clicks={clicks}
        ownCar={ownCar}
        field={field}
        headerDescription={headerDescription}
        headerName={headerName}
        headerType={headerType}
      />
    ),
    renderCell: (params) => (
      <CarWindowTableCells
        car={params.row}
        columnType={columnType}
        ownCar={ownCar}
        taxationView={taxationView}
        removeRanking={removeRanking}
        clickName={clickName}
        clicks={clicks}
        value={params.value}
        ownDealer={params.row.dealer?.name === user?.dealer}
      />
    ),
  });

  const columns: TableColDef<CarResult>[] = [...CarWindowColumns.map(mapCarWindowColumn)];

  return (
    <BaseTable
      rows={cars}
      columns={columns}
      getRowId={(row) => row.id}
      headerHeight={25}
      rowHeight={68}
      exportToPdf={false}
      useStripedRows={false}
      getRowClassName={(params) => {
        const carType = params.id === ownCar.id ? 'ownCar' : 'dealerCars';
        const bannedCar = params.is_banned ? 'bannedCar' : '';
        return `${carType} ${bannedCar}`;
      }}
      reorderModel={{
        reorder: reorder,
        reorderColumn: {
          width: 10,
          minWidth: 20,
          cellClassName: (params) => (params.id === ownCar.id ? 'showIcon' : 'hideIcon'),
        },
      }}
      error={error}
      disableStickyHeader
      sx={(theme) => ({
        '.bannedCar, .bannedCar:hover': {
          backgroundColor: theme.palette.warning.main,
        },
        '.ownCar, .ownCar:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '.noPadding': {
          padding: 0,
        },
        '.hideIcon > div': {
          display: 'none',
        },
      })}
    />
  );
};
