import Container from '@mui/material/Container';
import React, { ReactNode } from 'react';
import { Grid2 } from '@mui/material';
import { TopRow } from '../top-row/top-row';
import { FiltersWrapper } from '../filter';

const ExtraButtonsRow = ({ children }: { children?: JSX.Element[] | JSX.Element }) => (
  <Grid2 marginTop="20px" marginBottom="20px" display="flex" justifyContent="flex-end">
    <div style={{ flexGrow: 1 }} />
    {children}
  </Grid2>
);

interface MainViewProps {
  leftMenuComponent?: ReactNode;
  mainComponent?: ReactNode;
  mainExtraButtons?: JSX.Element[];
  leftMenuExtraButtons?: JSX.Element[];
  onClickReset?: () => void;
  hideResetAllButton?: boolean;
  filterCount?: number;
  internal?: boolean;
}

export const PageContainer = ({
  leftMenuComponent,
  mainComponent,
  mainExtraButtons,
  leftMenuExtraButtons,
  filterCount,
  hideResetAllButton = false,
  onClickReset,
  internal = false,
}: MainViewProps) => (
  <Container maxWidth={'xl'} sx={{ paddingY: 2 }}>
    <Grid2 sx={{ marginBottom: 2, width: '100%' }}>
      <TopRow internal={internal} showHintsButton />
    </Grid2>
    <Grid2
      sx={{
        borderRight: { md: '1px solid rgba(0,0,0,0.2)' },
        width: { xs: '100%', md: '20%' },
        paddingX: 2,
        display: { xs: 'block', md: 'inline-block' },
        verticalAlign: 'top',
        marginTop: { sx: 0, md: '-90px' },
      }}
    >
      <FiltersWrapper
        extraButtons={leftMenuExtraButtons}
        hideResetAllButton={hideResetAllButton}
        filterCount={filterCount}
        onClick={onClickReset}
      >
        {leftMenuComponent}
      </FiltersWrapper>
    </Grid2>
    <Grid2
      component="main"
      sx={{
        width: { xs: '100%', md: '80%' },
        paddingLeft: 2,
        display: { xs: 'block', md: 'inline-block' },
        marginTop: { xs: 2, md: 0 },
      }}
    >
      {mainExtraButtons && mainExtraButtons.length > 0 && <ExtraButtonsRow>{mainExtraButtons}</ExtraButtonsRow>}
      {mainComponent}
    </Grid2>
  </Container>
);
