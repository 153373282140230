export const getToggleButtonStyle = (toggleState?: boolean, disableTripleState?: boolean) => {
  if (toggleState === true) {
    return 'primary';
  } else if (toggleState === false && !disableTripleState) {
    return 'warning';
  } else {
    return 'secondary';
  }
};

export const toggleThreeStates = (val: boolean | undefined) => {
  if (val === true) {
    return false;
  }
  if (val === false) {
    return undefined;
  }
  return true;
};

export const toggleTwoStates = (val: boolean | undefined) => {
  if (val === true) {
    return undefined;
  }
  return true;
};
