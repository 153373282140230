import { styled } from '@mui/styles';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { FONT_SIZES } from '../utils/font-sizes';

export const AuthTextField = styled(TextField)({
  'marginBottom': '7px',
  '& input': {
    color: '#000000',
    padding: '10px 8px',
    fontSize: FONT_SIZES.NORMAL,
    border: '1px solid #e2e2e2',
  },
  '& input:focus': {
    backgroundColor: '#F9F9F9',
    border: `1px solid #cecaca`,
    outline: 0,
  },
});

export const AuthInfoText = styled(`p`)({
  color: '#757575',
  textAlign: 'left',
});

export const AuthInfoTextWithSmallMargin = styled(AuthInfoText)({
  marginBottom: '0',
});

export const AuthInfoTextWithMargin = styled(AuthInfoText)({
  marginBottom: '20px',
});

export const AuthLink = styled(Link)({
  textAlign: 'left',
  fontSize: '14px',
  color: '#A9A9A9',
  display: 'block',
  marginTop: '10px',
});
