import { CarResult } from '../types';
import { AppSearchParamKeys, AppSearchParams } from '../components/filter/types';

export const createInternalUrlParams = (searchParams: AppSearchParams) =>
  Object.entries(searchParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');

const taxationSearchParamKeys = [
  AppSearchParamKeys.MILEAGE,
  AppSearchParamKeys.MAKE,
  AppSearchParamKeys.MODEL,
  AppSearchParamKeys.FUEL,
  AppSearchParamKeys.GEAR,
  AppSearchParamKeys.BODY,
  AppSearchParamKeys.BUILD,
  AppSearchParamKeys.HP,
  AppSearchParamKeys.MODEL_YEAR,
  AppSearchParamKeys.OPTIONS,
];
export const createTaxationUrl = (car: CarResult) =>
  '/taxatie?' +
  taxationSearchParamKeys.map((key) => `${key}=${encodeURIComponent(String(car[key as keyof CarResult]))}`).join('&');
