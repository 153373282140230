import React from 'react';
import { styled } from '@mui/material';
import { getToggleButtonStyle, toggleThreeStates, toggleTwoStates } from './utils';
import { ColoredBorderButton } from '../../colored-button';

export interface ToggleInputProps {
  label?: string;
  icon?: string | JSX.Element;
  toggleState: boolean | undefined;
  disableTripleState?: boolean;
  setToggleState: (newToggleState: boolean | undefined) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
}

const OptionImage = styled('img')<{
  toggleState: boolean | undefined;
}>(({ toggleState, theme }) => ({
  height: 'inherit',
  filter: toggleState === undefined ? theme.imageFilter.darkFilter : theme.imageFilter.lightFilter,
}));

const ButtonContentWrapper = styled('span')({
  margin: '5px',
  height: '20px',
  cursor: 'pointer',
  display: 'flex',
  columnGap: '5px',
});
export const ToggleInput = ({
  icon,
  label,
  toggleState,
  setToggleState,
  disabled,
  fullWidth = true,
  disableTripleState = false,
  className,
}: ToggleInputProps) => {
  const content = (
    <>
      {icon && typeof icon === 'string' ? <OptionImage src={icon} toggleState={toggleState} /> : icon}
      {label}
    </>
  );
  const wrappedContent = className ? content : <ButtonContentWrapper>{content}</ButtonContentWrapper>;
  return (
    <ColoredBorderButton
      fullWidth={fullWidth}
      onClick={() => setToggleState(disableTripleState ? toggleTwoStates(toggleState) : toggleThreeStates(toggleState))}
      size="small"
      disabled={disabled}
      className={className}
      color={getToggleButtonStyle(
        disableTripleState && toggleState === undefined ? false : toggleState,
        disableTripleState,
      )}
      sx={{
        justifyContent: icon ? 'start' : 'center',
      }}
    >
      {wrappedContent}
    </ColoredBorderButton>
  );
};
