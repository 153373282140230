import { grey } from '@mui/material/colors';
import { ThemeColors } from '../types';

export const Colors = {
  BLACK: '#000000',
  GREEN: '#3CD958',
  LIGHT_GREEN: '#b1efbc',
  WHITE: '#FFFFFF',
  GREY_200: grey[200],
  GREY_300: grey[300],
  GREY_400: grey[400],
  GREY_500: grey[500],
  DARK_GREY: '#485A69',
  RED: '#d32f2f',
};

export const LightThemeColors: ThemeColors = {
  PRIMARY: Colors.GREEN,
  PRIMARY_LIGHT: Colors.LIGHT_GREEN,
  PRIMARY_TEXT: Colors.WHITE,
  SECONDARY: Colors.WHITE,
  SECONDARY_DARK: Colors.GREY_200,
  SECONDARY_TEXT: Colors.DARK_GREY,
  WARNING: Colors.RED,
  WARNING_TEXT: Colors.WHITE,
  INFO_LIGHT: Colors.GREY_200,
  INFO: Colors.DARK_GREY,
  INFO_DARK: '#3b3b3b',
  INFO_TEXT: Colors.WHITE,
  BACKGROUND: Colors.GREY_200,
};

export const DarkThemeColors: ThemeColors = {
  PRIMARY: Colors.BLACK,
  PRIMARY_TEXT: Colors.WHITE,
  PRIMARY_LIGHT: Colors.GREY_300,
  SECONDARY: Colors.WHITE,
  SECONDARY_DARK: Colors.GREY_400,
  SECONDARY_TEXT: Colors.DARK_GREY,
  WARNING: Colors.RED,
  WARNING_TEXT: Colors.WHITE,
  INFO_LIGHT: Colors.GREY_200,
  INFO: Colors.DARK_GREY,
  INFO_DARK: '#3b3b3b',
  INFO_TEXT: Colors.WHITE,
  BACKGROUND: Colors.GREY_200,
};
