import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterMileage = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.MILEAGE_FROM, AppSearchParamKeys.MILEAGE_TO]}
    defaultValue={[0, undefined]}
    min={0}
    labelTranslationKey="MILEAGE"
  />
);
