import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { CarShareDialog } from './car-share-dialog';
import { ColoredButtonSmall } from '../../../cars/results/result-table-styles';
import { CarResult } from '@japieglobal/shared/src/types';

const StyledMailButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});
interface MailButtonProps {
  car: CarResult;
  isTaxation: boolean;
}

export const MailButton = ({ car, isTaxation }: MailButtonProps) => {
  const [showShareDialog, setShowShareDialog] = useState(false);
  const onClickMail = useCallback(() => setShowShareDialog(true), []);

  return (
    <>
      <StyledMailButton onClick={onClickMail} className="mailTaxationButton">
        <MailIcon fontSize="small" />
      </StyledMailButton>

      {showShareDialog && (
        <CarShareDialog
          isTaxation={isTaxation}
          car={car}
          showDialog={showShareDialog}
          setShowDialog={setShowShareDialog}
        />
      )}
    </>
  );
};
