import { Box, styled, TableCell } from '@mui/material';
import { ColoredOutlinedButton } from '@japieglobal/shared/src/components';

export const BoxFlexColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const CarTableCell = styled(TableCell)({
  padding: 5,
  fontSize: '13px',
  textAlign: 'center',
  fontWeight: 400,
});

export const ColoredButtonSmall = styled(ColoredOutlinedButton)({
  minWidth: '25px',
  height: '20px',
  width: '22px',
  fontSize: '11px',
});

export const ColoredButtonLargeText = styled(ColoredOutlinedButton)({
  minWidth: '25px',
  fontFamily: 'courier',
  height: '20px',
  width: '22px',
  fontWeight: 700,
  fontSize: '13px',
});
