import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterLastPriceAdjustment = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.LAST_DISCOUNT_FROM, AppSearchParamKeys.LAST_DISCOUNT_TO]}
    defaultValue={[0, undefined]}
    min={0}
    labelTranslationKey="LAST_PRICE_ADJUSTMENT"
  />
);
