import React from 'react';
import { BooleanAppSearchParam, ToggleFilter } from '@japieglobal/shared/src';
import { Colors } from '@japieglobal/shared/src/style/palettes/theme-colors';
import { CarResult } from '@japieglobal/shared/src/types';
import { styled } from '@mui/system';

const StyledToggleFilter = styled(ToggleFilter)<{
  trend: number | undefined;
}>(({ trend }) => ({
  'width': '25px',
  'maxWidth': '25px',
  'maxHeight': '15px',
  'minWidth': '25px',
  'minHeight': '15px',
  'position': 'relative',
  'height': '15px',
  'paddingLeft': '2px',
  'borderRadius': '0',
  'fontSize': '10px',
  'backgroundColor': Colors.WHITE,
  'color': '#333',
  'marginRight': '3px',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: Colors.GREY_200,
    color: '#333',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '2px',
    right: '2px',
    width: '5px',
    height: '5px',
    backgroundColor: trend && trend >= 3 ? '#6CAF51' : trend && trend >= 2 ? '#E47D31' : '#E54D42',
  },
}));

export interface TurnoverButtonProps {
  car: CarResult;
  column: 'etr' | 'itr' | 'apr';
  searchParamKey: BooleanAppSearchParam;
}
export const TurnoverButton = ({ car, searchParamKey, column }: TurnoverButtonProps) => {
  const value = column == 'apr' ? car.apr : car.stat ? car.stat[searchParamKey] : undefined;

  return (
    <StyledToggleFilter trend={value} searchParamKey={searchParamKey} labelTranslationKey={value} hideResetButton />
  );
};
