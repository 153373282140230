import React, { useContext, useState } from 'react';
import { MenuButton } from '../../menu-button';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { UserContext } from '../../../../user-context';

export const ScrapeManagementButton = () => {
  const [isShown, setShown] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <MenuButton
      style={{ opacity: isShown ? 100 : 0, height: '42px' }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
      hoverTitle={'SCRAPE_MANAGEMENT'}
      display={user?.permissions.some((p) => p.startsWith('scrape'))}
      internal={false}
      link={`/admin-scrape/items/production/${user?.dealer}`}
    >
      <LibraryBooksIcon />
    </MenuButton>
  );
};
