import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { NumberAppSearchParam } from '../../types';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { useTranslation } from 'react-i18next';
import { RangeInput } from '../../../inputs/range-input/range-input';
import { useAppSearchParams } from '../../hooks';
import { getCurrencySymbolAndPosition } from '../../../../utils/currency-utils';
import { UserContext } from '../../../../user-context';

export interface RangeFilterProps
  extends Omit<BaseInputFilterProps<[number | undefined, number | undefined], NumberAppSearchParam>, 'searchParamKey'> {
  searchParamKey: [NumberAppSearchParam, NumberAppSearchParam];
  min?: number;
  max?: number;
  isMoneyInput?: boolean;
}
export const RangeFilter = ({
  searchParamKey,
  labelTranslationKey,
  min,
  max,
  defaultValue,
  hideResetButton,
  hasBackendDefault = true,
  isMoneyInput,
}: RangeFilterProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const value = useMemo(
    () => [searchParams[searchParamKey[0]], searchParams[searchParamKey[1]]],
    [searchParamKey, searchParams],
  );

  const onHandleReset = () => {
    searchParams[searchParamKey[0]] = undefined;
    searchParams[searchParamKey[1]] = undefined;
    setSearchParams(searchParams);
  };

  const onHandleChange = useCallback(
    (newValues: [number | undefined, number | undefined]) => {
      newValues.forEach((newValue, index) => (searchParams[searchParamKey[index]] = newValue ?? defaultValue?.[index]));
      setSearchParams(searchParams);
    },
    [defaultValue, setSearchParams, searchParams, searchParamKey],
  );

  useEffect(() => {
    if ((value[0] == null || value[1] == null) && !hasBackendDefault) {
      const value0 = defaultValue?.[0] ?? min;
      const value1 = defaultValue?.[1] ?? max;
      onHandleChange([value0, value1]);
    }
  }, [value, onHandleChange, defaultValue, min, max, hasBackendDefault]);

  return (
    <RangeInput
      hideResetButton={hideResetButton}
      defaultValue={defaultValue}
      setValue={onHandleChange}
      value={[value[0] != null ? Number(value[0]) : undefined, value[1] != null ? Number(value[1]) : undefined]}
      onReset={onHandleReset}
      min={min}
      max={max}
      title={t(
        labelTranslationKey,
        isMoneyInput ? { currencySymbol: getCurrencySymbolAndPosition(user).symbol } : undefined,
      )}
    />
  );
};
