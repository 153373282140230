import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CanvasAxisProps } from '@nivo/axes';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { ProgressIndicator } from '@japieglobal/shared/src/components';
import { formatDateAsMonthAndDay } from '@japieglobal/shared/src/utils';
import { BarDatum } from '@nivo/bar/dist/types/types';
import { useNavigate } from 'react-router-dom';
import { UserStatsResponse } from '@japieglobal/shared/src/api/services';

const COLOR_HIGH_ACTIVITY = `rgb(48, 90, 25)`;

const margin = {
  top: 50,
  right: 50,
  bottom: 50,
  left: 50,
};

const labelTextColor = { from: 'color', modifiers: [['darker', 1.6]] };

const axisBottom: CanvasAxisProps = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 90,
  format: (date) => formatDateAsMonthAndDay(new Date(String(date))),
};

const axisLeft: CanvasAxisProps = {
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  legend: 'Aantal acties',
  legendPosition: 'middle',
  legendOffset: -40,
};

const usedColors: string[] = [];

const getRandomArbitrary = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

const getRandomGreenShadeHSLColor = () => {
  const S = getRandomArbitrary(50, 100);
  const V = getRandomArbitrary(30, 100);
  const H = getRandomArbitrary(135, 150);
  return `hsl(${H}deg ${S}% ${V}%)`;
};

const getRandomHSLColorNoDuplicate = () => {
  const color = getRandomGreenShadeHSLColor();
  if (usedColors.includes(color)) {
    return getRandomGreenShadeHSLColor();
  }
  usedColors.push(color);
  return color;
};
interface AdminUserChartNivoProps {
  apiData: UserStatsResponse | undefined;
  isLoading: boolean;
  getUserActivityUrl: (userId: string) => string;
  highActivityMark?: number | undefined;
}
export const AdminUserChartNivo = React.memo(
  ({ apiData, isLoading, getUserActivityUrl, highActivityMark = 100 }: AdminUserChartNivoProps) => {
    const [chartData, setChartData] = useState<BarDatum[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [dealerColors, setDealerColors] = useState<Record<string, string>>({});
    const navigate = useNavigate();

    useEffect(() => {
      if (apiData) {
        const ids = new Set<string>();
        const colors: Record<string, string> = {};
        const mappedData = Object.entries(apiData ?? {}).map(([key, values]) => {
          const result: BarDatum = { date: key };
          values.forEach((v) => {
            if (!v.id) v.id = v.dealer;
            ids.add(v.id);
            result[v.id] = Math.min(v.totalActivities, highActivityMark);
            if (!colors[v.dealer]) colors[v.dealer] = getRandomHSLColorNoDuplicate();
          });
          return result;
        });
        setChartData(mappedData);
        setUserIds(Array.from(ids));
        setDealerColors(colors);
      }
    }, [apiData, highActivityMark]);

    const tooltip = useCallback(
      (params) => {
        if (!apiData) return null;
        const userEntry = apiData[params.indexValue]?.find((v) => v.id === params.id);
        if (userEntry) {
          return (
            <div style={{ backgroundColor: 'whitesmoke' }} key={`${params.indexValue}-${params.index}`}>
              <div>{params.indexValue}</div>
              <div>
                <div
                  style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    backgroundColor: params.color,
                  }}
                />{' '}
                {userEntry.name} {userEntry.totalActivities}
              </div>
              <div>{userEntry.dealer}</div>
            </div>
          );
        }
        return null;
      },
      [apiData],
    );

    const colorFunction = useCallback(
      (params) => {
        if (apiData) {
          const userEntry = apiData[params.indexValue]?.find((v) => v.id === params.id);
          if ((userEntry?.totalActivities ?? 0) > highActivityMark) return COLOR_HIGH_ACTIVITY;
          if (userEntry?.dealer) return dealerColors[userEntry.dealer];
        }
        return '';
      },
      [apiData, dealerColors, highActivityMark],
    );

    const onClick = useCallback(
      (params) => {
        navigate(getUserActivityUrl(params.id));
      },
      [navigate, getUserActivityUrl],
    );

    const MyResponsiveBar = useMemo(() => {
      if (chartData.length) {
        return (
          <ResponsiveBarCanvas
            data={chartData}
            keys={userIds}
            indexBy="date"
            margin={margin}
            padding={0.3}
            colors={colorFunction}
            tooltip={tooltip}
            onClick={onClick}
            axisTop={null}
            axisRight={null}
            axisBottom={axisBottom}
            borderWidth={1}
            borderColor="gray"
            axisLeft={axisLeft}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={labelTextColor as any}
          />
        );
      }
      return null;
    }, [colorFunction, chartData, onClick, tooltip, userIds]);

    return (
      <div style={{ height: '675px', position: 'relative' }}>
        {MyResponsiveBar}
        {isLoading ? <ProgressIndicator /> : null}
      </div>
    );
  },
);
