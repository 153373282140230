import { Body, Fuel, Gear } from '../../types';
import { instance } from './instance';

export interface ModelYearBreakDownItem {
  count: number;
  firstBuild: number;
  licensePlate: string;
  tuv: string;
  photo: string;
}

export interface BpmResult {
  license_plate: string;
  bpm_date: Date;
  bruto_bpm: number;
  price_catalog: number;
}

export const rdwManualAdjustment = async (params: {
  licensePlate: string;
  body?: Body;
  gear?: Gear;
  fuel?: Fuel;
  photo?: string;
  blocked?: boolean;
}): Promise<any> => instance.put('/rdw/manual-overwrite', null, { params });

interface AddRowToCatalogBody {
  cc?: number;
  minBuild: number;
  exampleUrl: string;
  fuel: Fuel;
  gear: Gear;
  body: Body;
  kw: number;
  maxBuild: number;
  wheelbase: number;
  model: string;
  make: string;
  kwNonElectric?: number;
  equipmentFourDoors?: boolean;
  isVerified?: boolean;
  catalogModel?: string;
  co2Nedc?: number;
  co2Wltp?: number;
  modelYear?: number;
}

interface AddModelYearBody {
  model: string;
  make: string;
  fuel: Fuel;
  gear: Gear;
  body: Body;
  hp: number;
  build: number;
  modelYear: number;
  minModelYear: number;
  maxModelYear: number;
  co2Wltp?: number | null;
  co2Nedc?: number | null;
}

interface ModelYearParams {
  model: string;
  make: string;
  fuel: Fuel;
  gear: Gear;
  body: Body;
  hp: number;
  build: number;
}

export const addRowToCatalog = async (requestBody: AddRowToCatalogBody): Promise<void> =>
  instance.post(`/rdw/catalog/manual-addition`, requestBody);

export const addModelYear = async (requestBody: AddModelYearBody): Promise<void> =>
  instance.post(`/rdw/co2-model-year`, requestBody);

interface Co2ModelYearResponse {
  model_year: number;
  min_model_year: number;
  max_model_year: number;
  co2_wltp: number | undefined;
  co2_nedc: number | undefined;
}

export const getCo2ModelYear = async (params: ModelYearParams): Promise<Co2ModelYearResponse> =>
  instance.get(`/rdw/co2-model-year`, { params: params }).then((res) => res.data);

export const getCarRdw = async (licensePlate: string) =>
  instance.get(`/rdw/plate?licensePlate=${licensePlate}&includeEquipment=true`).then((res) => res.data);

export const getPlatesByCarType = async (carTypeId: string): Promise<string[]> =>
  instance.get(`/rdw/plates-by-car-type-id?carTypeId=${carTypeId}`).then((res) => res.data);

export const refreshCarRdw = async (licensePlate: string) =>
  instance.get(`/rdw/refresh?licensePlate=${licensePlate}`).then((res) => res.data);

export const modelyearBreakdown = async (licensePlate: string): Promise<ModelYearBreakDownItem[]> =>
  instance.get(`/rdw/model-year-breakdown?licensePlate=${licensePlate}`).then((res) => res.data);

export const getBpmComparison = async (licensePlate: string): Promise<BpmResult[]> =>
  instance.get(`/rdw/bpm-comparison?licensePlate=${licensePlate}`).then((res) => res.data);
