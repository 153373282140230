import { styled } from '@mui/system';
import React from 'react';
const P1 = styled(`p`)({
  margin: '0px 0px 0px 0px',
  font: '10px Times',
  color: '#333333',
  minHeight: '11px',
});
const P2 = styled(`p`)({
  margin: '0px 0px 0px 0px',
  font: '10px Times',
  color: '#333333',
});
const P3 = styled(`p`)({
  margin: '0px 0px 5px 0px',
  font: '10px Times',
  color: '#000000',
});
const P4 = styled(`p`)({
  margin: '5px 0px 0px 0px',
  font: '10px Times',
  color: '#000000',
});

const S1 = styled(`span`)({
  textDecoration: 'underline',
});
const S2 = styled(`span`)({
  textDecoration: 'underline',
  color: '#0000ff',
});

export const PrivacyTerms = () => (
  <>
    <P1>
      <span>  </span>
    </P1>
    <P1>
      <span> </span>
    </P1>
    <P2>
      General Licence Conditions<span> </span>
    </P2>
    <P1>
      <span>  </span>
    </P1>
    <P3>
      <b>Privacy Statement</b>
    </P3>
    <P3>
      Your privacy is important to us at JPcars. We use your personal data exclusively to manage your account, to
      deliver our products and/or services to you and to inform you via telephone, e-mail and/or app push notifications
      about, among other things, the following:
      <br />
      - Management Reports.
      <br />
      - Push notifications (e.g. JPcars messages) via the mobile app.
      <br />- E-mails about new functionality.
    </P3>
    <P3>
      We regard the protection, confidentiality and integrity of your personal data as extremely important. In this
      privacy statement we explain which personal data we collect when you use our products and/or services, why we need
      this data and how we use it.
    </P3>
    <P4>
      <b>Our Services</b>
    </P4>
    <P3>
      JPcars provides various products and services to parties that are directly or indirectly involved in used cars.
      This privacy statement is applicable to these products, services and the related platforms.
    </P3>
    <P4>
      <b>Personal data</b>
    </P4>
    <P3>
      We need certain personal data so that we can offer you the best possible experience and inform you about, among
      other things, management reports, push notifications (e.g. JPcars messages) via the mobile app and e-mails about
      new functionality. You provide some of this data directly, for example when you create an account and/or use our
      products and/or services. In addition we receive certain personal data indirectly via your devices due to
      interaction with our products and/or services. This enables us to continue improving our products. When you create
      an account and/or use our products and/or services, we process the following data:
      <br />
      - Your first name and surname.
      <br />
      - Your e-mail address.
      <br />
      - Your telephone number.
      <br />
      - Your business location.
      <br />
      - Your IP address.
      <br />
      - Your telephone ID.
      <br />
      - Chat messages.
      <br />
      - Your company name.
      <br />
      - Your Chamber of Commerce registration number.
      <br />- Payment and invoicing information, such as a bank account number.
    </P3>
    <P4>
      <b>Data collection</b>
    </P4>
    <P3>
      When registering and using our online products and services we collect and analyse data on chat messages, log
      information and information about visitor behaviour. This information is used for evaluation purposes and to draw
      up statistical reports about online activities. We register your interaction with our products and services so
      that we can improve them.
    </P3>
    <P4>
      <b>Use of personal data</b>
    </P4>
    <P3>
      We use your personal data exclusively for the purposes for which they are collected and, where applicable, to
      fulfil legal obligations. We use your personal data for the following purposes:
      <br />
      - <S1>To execute agreements</S1>. We process your personal data so that we can deliver our products and services
      to you.
      <br />
      - <S1>Support</S1>. We use your information to support you when using our services. This covers the use of
      personal data to identify problems with products and to provide other support-related services.
      <br />
      - <S1>Client contact and communication</S1>. We use your data to communicate with you in connection with our
      services via telephone, e-mail and/or app push notifications. For example, we send you invoices and are able to
      communicate with you in order to confirm your account, send messages, solve problems and/or invite you to
      participate in customer satisfaction surveys.
      <br />
      - <S1>Marketing</S1>. We would like to update you about our products and services via e-mail and/or app push
      notifications. You are always entitled to unsubscribe from this information service by contacting us via{' '}
      <S2>jp@jp.cars</S2>.<br />
      <span> </span>-<S1>Safety, security and the settlement of disputes.</S1> We use your data to guarantee the safety,
      integrity and protection of our products, services and clients. For example we process your data to detect and
      prevent fraud, theft and other misuse of our services. As necessary we can use your data within the framework of
      settling disputes, to enforce fulfilment of the agreement with you, to enforce our rights vis-à-vis third parties
      and to defend ourselves against claims or allegations by third parties.
      <br />
      - <S1>Statutory obligations</S1>. We may process your information in order to fulfil legal obligations, for
      example the maintenance of records or to comply with legitimate requests by competent authorities (for example the
      police/judiciary).
      <br />
      - <S1>To improve and personalise our products and services</S1>. We use your personal data to improve our products
      and services. For example, insight into click behaviour and chat messages on our platforms can help us prioritise
      or identify new product functions.
      <br />
      - <S1>Maintenance, development and incident management</S1>. If you experience problems with one of our products
      or services, it may be that we have to process personal data, such as your name, in order to resolve the problem
      in question. We also process your personal data for some internal operational processes, for example when we
      transfer client data to a new database.
      <br />
      - 
      <S1>To perform general business processes and internal management tasks and to generate management reports</S1>.
      We use your personal data for general business processes so that we can carry out our business activities. For
      example, we process your data for archiving purposes, insurance policies, management reports, audits and other
      administrative purposes.
    </P3>
    <P4>
      <b>Third Parties</b>
    </P4>
    <P3>
      We share your personal data with third parties. We share your data in the following instances:
      <br />
      - Insofar as necessary in order to carry out our business activities and provide our services. This concerns, for
      example, collecting payments, providing customer support and being able to communicate with you in connection with
      our services.
      <br />
      - In instances in which you have given us explicit permission to do so.
      <br />
      - To generate leads, including but not limited to, in order to purchase potentially interesting second-hand
      vehicles.
      <br />
      - If we are lawfully asked to do so in the context of law enforcement, for example for investigations into illegal
      activities.
      <br />
      - As necessary in order to maintain our agreement with you. If, for example, you do not pay for our services, we
      can share your information with a collection agency.
      <br />
      - In order to enforce our rights vis-à-vis third parties or defend ourselves against claims or allegations by
      third parties.
      <br />- To guarantee the safety, integrity and protection of our services.
    </P3>
    <P4>
      <b>Processing of personal data</b>
    </P4>
    <P3>
      Your personal data is saved for hosting and maintenance purposes. Your personal data is primarily processed by our
      employees at our head office in the Netherlands. We also share data with group companies affiliated to us. Insofar
      as we forward personal data to a country outside the European Union, or an international organisation, we make
      sure that this only happens with respect to those countries and organisations which guarantee a suitable level of
      protection for your data and rights in accordance with the General Data Protection Regulation (GDPR), or if this
      is otherwise permitted under the GDPR. If you would like to receive more information about the above-mentioned
      safeguards, please contact us via <S2>jp@jp.cars</S2>.
    </P3>
    <P4>
      <b>Retaining personal data</b>
    </P4>
    <P3>
      We keep your personal data only for as long as necessary for the purposes for which the data has been collected or
      is used and/or as long as this is required by law. After cancellation we delete your personal data at the earliest
      opportunity, except for the information we are required to keep by law. For example, we are legally obliged to
      retain data such as copies of invoices for a seven-year period.
    </P3>
    <P4>
      <b>Access to and management of your personal data</b>
    </P4>
    <P3>
      Your account administrator can view and process personal data directly via the administrator account. How you can
      view or manage this personal data depends on the products and services you use. If you cannot view, rectify or
      delete certain personal data yourself, if you want a copy of your personal data, or if you want to submit a
      request to restrict processing, you can contact us via <S2>jp@jp.cars</S2>. We will respond within 30 days after
      receiving such requests.
    </P3>
    <P4>
      <b>
        Statutory basis for data processing<span> </span>
      </b>
    </P4>
    <P3>
      We collect and process your personal data exclusively when we have a legal basis for doing so. The following
      grounds apply:
      <br />
      - The processing is necessary in order to execute your agreement.
      <br />
      - The processing is necessary in order to protect our legitimate business interests, for example i) to be able to
      carry out our business activities; ii) for certain forms of direct marketing and profiling; iii) to detect and
      prevent fraud and misuse of our services; or iv) in order to protect our network and systems.
      <br />
      - The processing is necessary in order to fulfil a legal obligation, such as the duty to retain which applies to
      administrative data, or to share certain information after a police order for a criminal investigation.
      <br />
      - When you have given us explicit permission to process your data, for example to share data with partners for
      commercial purposes.
      <br />- When the processing is based on permission, you will always be entitled to withdraw your permission. In
      that case we will terminate the respective processing of your data. Withdrawing permission does not change the
      legitimacy of the permission-based processing before the withdrawal.
    </P3>
    <P4>
      <b>Questions and complaints</b>
    </P4>
    <P3>
      If you have any questions about the way we use your personal data, please contact us via <S2>jp@jp.cars</S2>. If
      you feel that a problem has not been sufficiently addressed, you are entitled to submit a complaint to the Dutch
      Data Protection Authority [Autoriteit Persoonsgegevens].
    </P3>
    <P4>
      <b>Responsible entity</b>
    </P4>
    <P3>
      JPcars is a brand name of japieCARS Netherlands B.V., established at John M. Keynesplein 1, 1066 EP Amsterdam, and
      registered with the Chamber of Commerce under number 71131264. japieCARS Netherlands B.V. is part of japieGLOBAL
      B.V., established at John M. Keynesplein 1, 1066 EP Amsterdam and registered with the Chamber of Commerce under
      number 64235688. The decisions relating to the goals and means of processing personal data are taken at japieCARS
      Netherlands B.V., which acts as the controller for the processing of said personal data.
    </P3>
  </>
);
