import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { ColoredOutlinedButton } from '@japieglobal/shared';

const StyledCounterButton = styled(ColoredOutlinedButton)<{ isactive: number }>(({ isactive, theme }) => ({
  'backgroundColor': isactive ? theme.palette.primary.main : undefined,
  'color': isactive ? theme.palette.primary.contrastText : undefined,
  '&:hover': {
    backgroundColor: isactive ? theme.palette.primary.main : undefined,
  },
  'padding': 0,
  'margin': '4px',
  'minWidth': 20,
}));

interface CarWindowPageCounterProps {
  currentPage: number;
  pageCount: number;
  changePage: (page: number) => void;
}
export const CarWindowPageCounter = React.memo(({ changePage, currentPage, pageCount }: CarWindowPageCounterProps) => (
  <Box style={{ textAlign: 'center' }}>
    {new Array(pageCount + 1).fill(0).map((value, index) => (
      <StyledCounterButton
        isactive={currentPage === index ? 1 : 0}
        key={`car-window-page-counter-${index}`}
        onClick={() => changePage(index)}
      >
        {index}
      </StyledCounterButton>
    ))}
  </Box>
));
