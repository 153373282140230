import React, { useMemo } from 'react';
import { AppSearchParamKeys, SelectFilter, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const SaveTaxationsFilterModel = () => {
  const [{ make }] = useAppSearchParams([AppSearchParamKeys.MAKE]);
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.MODEL, make }),
    [make],
  );
  const filterDependencies = useMemo(() => ({ [AppSearchParamKeys.MAKE]: make }), [make]);

  return (
    <SelectFilter
      tabOnEnter
      searchParamKey={AppSearchParamKeys.MODEL}
      filterDependencies={filterDependencies}
      carHeaderOptions={carHeaderOptions}
      labelTranslationKey="MODEL"
    />
  );
};
