import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import { useQueryString } from '../../../../hooks';
import { MenuButton } from '../../menu-button';
import { setDealer, User, UserRoles } from '../../../../api/services';
import { DealersSelect } from '../../../dealers-select/dealers-select';

interface DealerButtonProps {
  user: User;
}
export const DealerButton = ({ user }: DealerButtonProps) => {
  const theme = useTheme();
  const [showPopup, setShowPopup] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setShowPopup(true);
  const handleClose = () => setShowPopup(false);

  const [switchToDealer, setSwitchToDealer] = useQueryString<string | undefined>('switch_to_dealer', undefined);

  const handleSetDealer = useCallback(
    (dealer: string) => {
      if (user.dealer !== dealer) {
        setDealer(dealer)
          .then(() => {
            setSwitchToDealer(undefined);
            window.location.reload();
          })
          .catch((error) => console.error(error));
      }
    },
    [setSwitchToDealer, user.dealer],
  );

  useEffect(() => {
    if (switchToDealer) {
      setSwitchToDealer(undefined);
      handleSetDealer(switchToDealer);
    }
  }, [handleSetDealer, setSwitchToDealer, switchToDealer]);

  return user.role === UserRoles.SUPER_ADMIN || user.allowed_dealers?.length > 1 ? (
    <>
      <MenuButton hoverTitle={'DEALER'} onClick={handleOpen}>
        <StorageIcon />
      </MenuButton>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="select dealer"
        open={showPopup}
        disableEnforceFocus
      >
        <DialogTitle id="customized-dialog-title">Select dealer</DialogTitle>
        <DialogContent sx={{ width: 300 }} dividers>
          <DealersSelect selectedDealer={user.dealer} user={user} onSelect={handleSetDealer} />
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <></>
  );
};
