import React from 'react';
import { Box } from '@mui/material';
import { AppSearchParamKeys, ToggleFilter, useFilterGroup } from '@japieglobal/shared';

const StandingTimeBoxes = () => {
  useFilterGroup([
    AppSearchParamKeys.THREE_THUMBS_DOWN_30,
    AppSearchParamKeys.THREE_THUMBS_DOWN_60,
    AppSearchParamKeys.THREE_THUMBS_DOWN_90,
  ]);
  return (
    <Box display="flex" justifyContent="space-between" columnGap={'10px'} className="thumbsDownDaysFilter">
      <ToggleFilter
        dependencies={[AppSearchParamKeys.THREE_THUMBS_DOWN]}
        disableTripleState
        searchParamKey={AppSearchParamKeys.THREE_THUMBS_DOWN_30}
        labelTranslationKey="30"
      />
      <ToggleFilter
        dependencies={[AppSearchParamKeys.THREE_THUMBS_DOWN]}
        disableTripleState
        searchParamKey={AppSearchParamKeys.THREE_THUMBS_DOWN_60}
        labelTranslationKey="60"
      />
      <ToggleFilter
        dependencies={[AppSearchParamKeys.THREE_THUMBS_DOWN]}
        disableTripleState
        searchParamKey={AppSearchParamKeys.THREE_THUMBS_DOWN_90}
        labelTranslationKey="90"
      />
    </Box>
  );
};

export const FilterThumbsUpDown = () => {
  return (
    <>
      <ToggleFilter
        disableTripleState
        searchParamKey={AppSearchParamKeys.THREE_THUMBS_UP}
        labelTranslationKey="STOCK"
        icon={<span>👍👍👍</span>}
        className="thumbsUpFilter"
      />
      <ToggleFilter
        disableTripleState
        searchParamKey={AppSearchParamKeys.THREE_THUMBS_DOWN}
        labelTranslationKey="STOCK"
        icon={<span>👎👎👎</span>}
        className="thumbsDownFilter"
      />
      <StandingTimeBoxes />
    </>
  );
};
