import React from 'react';
import { MessagesButton } from './messages-button';
import { PriceProposalButton } from './price-proposal-button';
import { HiddenCarsButton } from './hidden-cars-button';
import { BannedCarsButton } from './banned-cars-button/banned-cars-button';

export const FilterMessagePriceProposals = () => {
  return (
    <>
      <MessagesButton />
      <PriceProposalButton />
      <HiddenCarsButton />
      <BannedCarsButton />
    </>
  );
};
