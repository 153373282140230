import { i18n } from 'i18next';
import { setLanguage as setLanguageService } from '../api/services';

export const setLanguage = (lang: string, i18n: i18n) => {
  const ns = i18n.reportNamespaces?.getUsedNamespaces()[0];
  localStorage.removeItem(`i18next_res_${lang}-${ns}`);
  i18n
    .changeLanguage(lang)
    .then(() => setLanguageService(lang))
    .then(() => i18n.reloadResources(lang))
    .catch((error) => console.error(error));
};
