import React from 'react';
import { styled } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types';
import { ColoredButtonSmall } from '../../cars/results/result-table-styles';
import { useAppSearchParams } from '@japieglobal/shared';

const OwnStockButton = styled(ColoredButtonSmall)<{ isActive: boolean }>(({ isActive, theme }) => ({
  'height': '30px',
  'width': '40px',
  'backgroundColor': isActive ? theme.palette.primary.main : undefined,
  'color': isActive ? theme.palette.primary.contrastText : undefined,
  '&: hover': {
    backgroundColor: isActive ? theme.palette.primary.dark : undefined,
  },
}));

export const OwnStockCount = ({ car }: { car: CarResult }) => {
  const [searchParams, setSearchParams] = useAppSearchParams();

  const showOwnSupplyWindow = () => {
    setSearchParams({ click_own_supply: !searchParams.click_own_supply });
  };

  return (
    <OwnStockButton
      aria-haspopup="true"
      onClick={showOwnSupplyWindow}
      isActive={searchParams.click_own_supply || false}
    >
      ({car.stat?.own_supply_window_count})
    </OwnStockButton>
  );
};
