import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterPriceDiscount = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.DISCOUNT_FROM, AppSearchParamKeys.DISCOUNT_TO]}
    defaultValue={[-100000, 100000]}
    min={-100000}
    max={100000}
    labelTranslationKey="PRICE_DISCOUNT"
  />
);
