import React from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { BannedCarsDialog } from './banned-cars-dialog';
import { AppSearchParamKeys, ToggleFilter } from '@japieglobal/shared';

export const BannedCarsButton = () => (
  <>
    <ToggleFilter
      disableTripleState
      searchParamKey={AppSearchParamKeys.SHOW_TRASH_BIN_CARS}
      labelTranslationKey="BANNED_CARS"
      icon={<DeleteForeverIcon />}
      className="bannedCarsButton"
    />
    <BannedCarsDialog />
  </>
);
