import React from 'react';
import ReactPasswordChecklist, { RuleNames } from 'react-password-checklist';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { PasswordInput } from './password-input';
import { Check, Clear } from '@mui/icons-material';

interface PasswordConfirmationProps {
  password: string;
  passwordAgain: string;
  setPassword: (password: string) => void;
  setPasswordAgain: (passwordAgain: string) => void;
  setIsPasswordValid: (isValid: boolean, failedRules?: RuleNames[]) => void;
}

export const PasswordConfirmation = ({
  password,
  passwordAgain,
  setPassword,
  setPasswordAgain,
  setIsPasswordValid,
}: PasswordConfirmationProps) => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
      <ReactPasswordChecklist
        rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
        minLength={8}
        value={password}
        valueAgain={passwordAgain}
        messages={{
          minLength: t('PASSWORD_MIN_LENGTH'),
          specialChar: t('PASSWORD_SPECIAL_CHAR'),
          number: t('PASSWORD_NUMBER'),
          capital: t('PASSWORD_CAPITAL'),
          lowercase: t('PASSWORD_LOWERCASE'),
          match: t('PASSWORD_MATCH'),
        }}
        iconComponents={{ ValidIcon: <Check color="success" />, InvalidIcon: <Clear color="error" /> }}
        onChange={setIsPasswordValid}
      />
      <PasswordInput labelTranslationKey={t('NEW_PASSWORD')} password={password} setPassword={setPassword} />
      <PasswordInput
        labelTranslationKey={t('REPEAT_PASSWORD')}
        password={passwordAgain}
        setPassword={setPasswordAgain}
      />
    </Box>
  );
};
