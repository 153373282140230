import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { LoadingWrapper } from '../../loading-wrapper/loading-wrapper';
import { styled } from '@mui/material';

const Container = styled(`div`)({ padding: 20, margin: 20 });
const MessageContainer = styled(`div`)({ textTransform: 'uppercase', textAlign: 'center' });
export interface FileInputProps {
  onDropFile: (formData: FormData) => Promise<any>;
}
export const FileInput = ({ onDropFile }: FileInputProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsLoading(true);
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      onDropFile(formData)
        .then(async (res) => {
          if (res.status !== 200) throw Error();
          snackbarSuccessMessage(t('UPLOAD_SUCCESS'));
        })
        .catch(() => {
          snackbarErrorMessage(t('UPLOAD_ERROR'));
        })
        .finally(() => setIsLoading(false));
    },
    [onDropFile, snackbarSuccessMessage, t, snackbarErrorMessage],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      transition: 'border .24s ease-in-out',
      ...(isDragActive ? { borderColor: '#2196f3' } : {}),
      ...(isDragAccept ? { borderColor: '#96BF31' } : {}),
      ...(isDragReject ? { borderColor: '#ff1744' } : {}),
    }),
    [isDragActive, isDragAccept, isDragReject],
  );

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <MessageContainer>
          <img src="/dragdrop.png" width={160} alt="drag-n-drop" />
          <div>{t('UPLOAD_DROP_MESSAGE')}</div>
        </MessageContainer>
      </Container>
      {isLoading && <CircularProgress />}
    </LoadingWrapper>
  );
};
