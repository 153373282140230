import { ConfirmationDialog, MultiSelectInput, SelectOption } from '@japieglobal/shared';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BusinessIcon from '@mui/icons-material/Business';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Box,
  Button,
  Grid2,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  styled,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingWrapper } from '@japieglobal/shared/src/components/loading-wrapper/loading-wrapper';
import { getDealerLocations, UserRoles } from '@japieglobal/shared/src/api/services';
import { getInvalidLocations } from '../utils/dealer-location-utils';
import { limitStringWithDots } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { DealersSelect } from '@japieglobal/shared/src/components/dealers-select/dealers-select';

const ListTextItemStyled = styled(ListItemText)({
  '& span': {
    fontSize: '16px',
  },
});

const LoadingWrapperStyled = styled(LoadingWrapper)({ marginLeft: '10px' });

interface AdminUserDealerEditProps {
  dealers: { id: string; locations: string[] }[];
  setDealers: (val: { id: string; locations: string[] }[]) => void;
}
export const AdminUserDealerEdit = ({ dealers, setDealers }: AdminUserDealerEditProps) => {
  const [deleteDealerIndex, setDeleteDealerIndex] = useState<number | undefined>(undefined);
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [selectedDealerIndex, setSelectedDealerIndex] = useState<number>(0);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const deleteDealerQuestion = useMemo(() => {
    if (deleteDealerIndex !== undefined) {
      const name = dealers[deleteDealerIndex].id;

      return `Weet je zeker dat je dealer "${name}" verwijderen wilt?`;
    }
    return '';
  }, [dealers, deleteDealerIndex]);

  const isSuperAdmin = useMemo(() => user.role === UserRoles.SUPER_ADMIN, [user.role]);
  const isScraperManager = user.role === UserRoles.SCRAPE_USER && user.permissions.includes('scrapeManagement');

  useEffect(() => {
    if (!isSuperAdmin) {
      setLocationOptions(user.locations);
    }
  }, [snackbarErrorHandler, user, isSuperAdmin]);

  const onAdd = useCallback(() => {
    const newDealerName = `Dealer ${dealers.length + 1}`;

    setDealers([...dealers, { id: newDealerName, locations: [] }]);
    setSelectedDealerIndex((prevState) => prevState + 1);
  }, [dealers, setDealers]);

  const onRemove = useCallback(
    (dealerIndex: number) => {
      if (dealers.length > 1) {
        const newAllowedDealers = [...dealers];
        newAllowedDealers.splice(dealerIndex, 1);
        setDealers(newAllowedDealers);
        if (selectedDealerIndex > dealerIndex || selectedDealerIndex === dealerIndex) {
          setSelectedDealerIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
        }
      }
    },
    [dealers, selectedDealerIndex, setDealers],
  );

  const onRemoveClick = useCallback(
    (dealerIndex: number) => {
      if (dealers.length > 1) {
        setDeleteDealerIndex(dealerIndex);
      }
    },
    [dealers.length],
  );

  const onDealerListClick = useCallback(
    (dealerIndex: number) => {
      console.log(dealerIndex);
      setSelectedDealerIndex(dealerIndex);
      if (isSuperAdmin) {
        setLocationOptions([]);
      }
    },
    [isSuperAdmin],
  );

  const handleChangeDealerName = (valueToSet: string) => {
    if (!dealers[selectedDealerIndex]) {
      dealers[selectedDealerIndex] = { id: '', locations: [] };
    }
    const newAllowedDealers = [...dealers];
    newAllowedDealers[selectedDealerIndex].id = valueToSet;
    newAllowedDealers[selectedDealerIndex].locations = [];
    setDealers(newAllowedDealers);
  };

  const handleChangeDealerLocations = useCallback(
    (selectedDealerLocations: SelectOption[]) => {
      const newAllowedDealers = [...dealers];
      newAllowedDealers[selectedDealerIndex].locations = [...selectedDealerLocations]
        .map((selectedDealerLocation) => selectedDealerLocation.value)
        .sort();
      setDealers(newAllowedDealers);
    },
    [dealers, selectedDealerIndex, setDealers],
  );

  const dealerName = useMemo(() => {
    return dealers && dealers[selectedDealerIndex] ? dealers[selectedDealerIndex].id : '';
  }, [dealers, selectedDealerIndex]);

  const dealerLocations = useMemo(() => {
    return dealers && dealers[selectedDealerIndex] ? [...dealers[selectedDealerIndex].locations].sort() : [];
  }, [dealers, selectedDealerIndex]);

  const invalidLocations = useMemo(() => {
    if (locationOptions && dealerLocations) {
      return Array.from(getInvalidLocations(locationOptions, dealerLocations));
    }
    return [];
  }, [dealerLocations, locationOptions]);

  const dealerLocationsInvalidTop: SelectOption[] = useMemo(() => {
    if (dealerLocations && invalidLocations) {
      const sorted = [...dealerLocations];
      sorted.sort((a, b) => {
        if (invalidLocations.includes(a) && !invalidLocations.includes(b)) {
          return -1;
        }
        if (!invalidLocations.includes(a) && invalidLocations.includes(b)) {
          return 1;
        }
        return a.localeCompare(b);
      });
      return sorted.map((dealerLocation) => ({ label: dealerLocation, value: dealerLocation }));
    }
    return dealerLocations.map((dealerLocation) => ({ label: dealerLocation, value: dealerLocation }));
  }, [dealerLocations, invalidLocations]);

  useDebouncedEffect(
    () => {
      if (
        dealerName &&
        dealerName.trim().length > 0 &&
        (isSuperAdmin || (user.dealer === dealerName && user.locations.length === 0))
      ) {
        setIsLoading(true);
        getDealerLocations(dealerName)
          .then(setLocationOptions)
          .catch(snackbarErrorHandler)
          .finally(() => setIsLoading(false));
      }
    },
    500,
    [user, dealerName, snackbarErrorHandler],
  );

  const closeDialog = useCallback(() => setDeleteDealerIndex(undefined), []);
  const dialogYesAction = useCallback(
    () => (deleteDealerIndex !== undefined ? onRemove(deleteDealerIndex) : undefined),
    [deleteDealerIndex, onRemove],
  );

  return (
    <>
      {deleteDealerIndex !== undefined && (
        <ConfirmationDialog
          closeDialog={closeDialog}
          title="Verwijder dealer"
          question={deleteDealerQuestion}
          yesAction={dialogYesAction}
        />
      )}
      <div style={{ width: '100%' }}>
        <div style={{ marginBottom: '18px' }}>
          <strong>Dealers</strong>
        </div>
        <div style={{ border: '1px solid rgb(225, 225, 225)' }}>
          <div style={{ display: 'flex', minHeight: '300px' }}>
            <div
              style={{
                minWidth: '250px',
                overflow: 'auto',
                borderRight: '1px solid rgb(225, 225, 225)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box style={{ maxHeight: 400, overflow: 'auto' }}>
                {(isSuperAdmin || isScraperManager) && !!dealers?.length && (
                  <Grid2 container justifyContent="flex-end">
                    <Button sx={{ marginLeft: 'auto' }} onClick={() => setDealers([])}>
                      Clear
                    </Button>
                  </Grid2>
                )}
                <List dense component="nav" style={{ maxHeight: '100%', overflow: 'auto' }}>
                  {dealers?.map((value, index) => {
                    return (
                      <ListItemButton
                        key={value.id}
                        color="primary"
                        onClick={() => onDealerListClick(index)}
                        selected={selectedDealerIndex === index}
                      >
                        <ListTextItemStyled primary={limitStringWithDots(value.id, 20)} title={value.id} />
                        {(isSuperAdmin || isScraperManager) && dealers.length > 1 && (
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              title="Verwijder"
                              onClick={() => onRemoveClick(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItemButton>
                    );
                  })}
                </List>
              </Box>
              {isSuperAdmin && (
                <div style={{ borderTop: '1px solid rgb(225, 225, 225)', textAlign: 'center' }}>
                  <IconButton aria-label="add" onClick={onAdd}>
                    <AddCircleIcon />
                  </IconButton>
                </div>
              )}
            </div>
            <div style={{ padding: '20px', width: '100%' }}>
              {dealers && (
                <>
                  {(isSuperAdmin || isScraperManager) && (
                    <div style={{ display: 'flex', marginBottom: '18px', alignItems: 'center' }}>
                      <BusinessIcon />
                      <DealersSelect selectedDealer={dealerName} user={user} onSelect={handleChangeDealerName} />
                    </div>
                  )}

                  {invalidLocations && invalidLocations.length > 0 && locationOptions && locationOptions.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '18px' }}>
                      <LocationOnIcon />

                      <div style={{ marginLeft: '10px' }}>
                        <div>
                          <span role="img" aria-label="exclamation-mark">
                            ❗
                          </span>{' '}
                          {t('INVALID_LOCATIONS')}{' '}
                          <span role="img" aria-label="exclamation-mark">
                            ❗
                          </span>
                          ️:{' '}
                        </div>
                        {invalidLocations.map((invalidLocation) => {
                          return <div key={`invalid-location-${invalidLocation}`}>{invalidLocation}</div>;
                        })}
                      </div>
                    </div>
                  )}

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon />
                    <LoadingWrapperStyled isLoading={isLoading}>
                      <MultiSelectInput
                        options={locationOptions.map((location) => ({ label: location, value: location }))}
                        value={dealerLocationsInvalidTop}
                        setValue={handleChangeDealerLocations}
                        label={t('LOCATIONS')}
                        limitTags={10}
                      />
                    </LoadingWrapperStyled>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
