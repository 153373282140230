import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCarMessageStyles = makeStyles((theme: Theme) => ({
  'commentbox': { padding: '0 40px' },
  'commentbox__form': {
    position: 'absolute',
    bottom: '40px',
    left: '40px',
    right: '40px',
    display: 'block',
  },
  'commentbox__text': {
    width: '100%',
    border: '1px solid #dee1e2',
    padding: '20px 150px 20px 20px',
    maxWidth: '100%',
    maxHeight: '60px',
    height: 'auto !important',
    resize: 'none',
  },
  'commentbox__submit': {
    // WebkitAppearance: 'none !important',
    background: '#485a69',
    borderRadius: '3px',
    border: '0',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: "'montserrat', sans-serif",
    padding: '0 20px',
    height: '45px',
    position: 'absolute',
    top: '7px',
    right: '10px',
  },
  'clear': {
    clear: 'both',
    display: 'block',
    overflow: 'hidden',
    visibility: 'hidden',
    width: '0',
    height: '0',
  },
  'commentbox__comments': {
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    top: '60px',
    bottom: '125px',
    left: '40px',
    paddingRight: '40px',
    right: '0px',
  },
  'comment__balloon': {
    padding: '30px',
    background: theme.palette.background.default,
    borderRadius: '3px',
    fontSize: '14px',
    lineHeight: '30px',
    position: 'relative',
    color: '#aaafb0',
    wordBreak: 'break-all',
    width: '100%',
  },
  'comment__triangle': {
    width: '0',
    height: '0',
    borderStyle: 'solid',
    borderWidth: '7px 7px 7px 0',
    borderColor: `transparent ${theme.palette.background.default} transparent transparent`,
    position: 'absolute',
    left: '-7px',
  },
  'comment__timestamp': {
    color: '#b1b6b6',
    fontSize: '14px',
    fontFamily: "'trocchi', sans-serif",
    lineHeight: '30px 1',
    width: '100%',
    display: 'inline-block',
    textAlign: 'right',
    padding: '0 30px',
  },
  'comment__user': {
    justifyContent: 'flex-start',
    marginTop: '26px',
    display: 'flex',
    color: '#aaafb0',
    wordBreak: 'break-all',
    width: '25%',
  },
  'comment--own.comment__triangle': {
    left: 'inherit',
    right: '-7px',
    borderWidth: '7px 0 7px 7px',
    borderColor: `transparent transparent transparent ${theme.palette.primary.main}`,
  },
  'comment--own.comment__balloon': { color: '#fff', background: theme.palette.primary.main },
  'comment--own.comment__timestamp': { textAlign: 'left' },
  'comment--own.comment__user': { color: theme.palette.primary.main, justifyContent: 'flex-end' },
  'commentText': {
    lineHeight: '20px',
    margin: 0,
    padding: 0,
  },
}));
