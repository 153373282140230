import React, { useContext } from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../states/click-states';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { BannedCarHeaderCell } from './header-cells/car-window-banned-cars-header-cell';
import { CarWindowMapHeaderCell } from './header-cells/car-window-map-header-cell';
import { HeaderType } from './car-window-columns';
import { Tooltip } from '@japieglobal/shared';
import { getCurrencySymbolAndPosition } from '@japieglobal/shared/src/utils/currency-utils';
import { useTranslation } from 'react-i18next';

interface CarWindowTableCellsProps {
  ownCar: CarResult;
  clicks: ClicksPassedDown;
  field: keyof CarResult;
  headerType?: HeaderType;
  headerName?: string;
  headerDescription?: string;
}

export const CarWindowHeaderCells = ({
  ownCar,
  clicks,
  field,
  headerType,
  headerDescription,
  headerName,
}: CarWindowTableCellsProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <>
      {headerType === 'basic' && headerName && (
        <Tooltip
          onHoverContent={
            headerDescription
              ? t(headerDescription)
              : t(headerName, { currencySymbol: getCurrencySymbolAndPosition(user).symbol })
          }
        >
          {t(headerName, { currencySymbol: getCurrencySymbolAndPosition(user).symbol })}
        </Tooltip>
      )}
      {field === 'is_banned' && ownCar.includes_banned && <BannedCarHeaderCell />}
      {field === 'country' && user?.permissions.includes('carWindowMap') && (
        <CarWindowMapHeaderCell clicks={clicks} ownCar={ownCar} />
      )}
    </>
  );
};
