import React from 'react';
import { styled } from '@mui/system';
import { SelectOption } from '../inputs';
import { Draggable } from '@hello-pangea/dnd';

const ListItem = styled('div')({
  border: '1px solid black',
  padding: '2px 8px',
  marginBottom: '8px',
});

interface SelectedListItemProps {
  item: SelectOption;
  index: number;
}
export const SelectedListItem = ({ item, index }: SelectedListItemProps) => (
  <Draggable key={item.value} draggableId={item.value} index={index}>
    {(provided) => (
      <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        {item.label}
      </ListItem>
    )}
  </Draggable>
);
