import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ColoredButton, TextInput } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { RdwPageContainer } from './rdw-page-container';
import { modelyearBreakdown } from '@japieglobal/shared/src/api/services';
import { ModelYearBreakDownItem } from '@japieglobal/shared/src/api/services';

export const RdwModelYearInfo = () => {
  const [licensePlate, setLicensePlate] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<ModelYearBreakDownItem[] | undefined>(undefined);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    modelyearBreakdown(licensePlate!)
      .then((res) => setResult(res))
      .catch(snackbarErrorHandler);
  };
  return (
    <RdwPageContainer onSubmit={onSubmit}>
      <b>{t('MODEL_YEAR_INFO')}</b>
      <TextInput label={t('LICENSE_PLATE')} value={licensePlate} setValue={setLicensePlate} required />
      <ColoredButton type="submit">{t('MODEL_YEAR_INFO')}</ColoredButton>

      {result && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('COUNT')}</TableCell>
                <TableCell>first build</TableCell>
                <TableCell>{t('LICENSE_PLATE')}</TableCell>
                <TableCell>tuv</TableCell>
                <TableCell>{t('PHOTO')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((item) => (
                <TableRow key={item.licensePlate}>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{item.firstBuild}</TableCell>
                  <TableCell>{item.licensePlate}</TableCell>
                  <TableCell>{item.tuv}</TableCell>
                  <TableCell>
                    <a href={item.photo} target="_blank" rel="noopener noreferrer">
                      {item.photo}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </RdwPageContainer>
  );
};
