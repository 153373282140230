import { AppSearchParams } from '../../components';
import { instance } from './instance';

export interface OptionListItem {
  name: string;
  makes: string;
  models: string;
  is_pseudo: boolean;
  is_hidden: boolean;
  commercial_vehicles_only: boolean;
  id: number;
}

export interface Option {
  name: string;
  makes: string[] | null;
  models: string[] | null;
  is_pseudo: boolean;
  commercial_vehicles_only: boolean;
  id: number;
  pattern: string[];
  ban: string[];
  default_options: string[];
  is_body: boolean;
  is_hidden: boolean;
  super_pattern: string[];
}

export interface StandardOption {
  id: number;
  option_id: number;
  make: string;
  model: string | null;
  fuel: string | null;
  body: string | null;
  hp: number | null;
  build: number | null;
  wheelbase: number | null;
}

export interface CarOptionsAndStandardOptionsParams {
  make: string;
  model: string;
  body: string;
  fuel: string;
  build: number;
  hp: number;
}

export interface CarOptionsAndStandardOptionsResult {
  options: { id: number; name: string }[];
  standard_options: { id: number; name: string; standard_option_id: number }[];
}

export const getStandardOptions = async (queryProps: {
  option_id: number;
  make?: string;
  model?: string;
}): Promise<StandardOption[]> =>
  instance.get(`extract/standard-options`, { params: queryProps }).then((res) => res.data);

export const getStandardOption = async (id: number): Promise<StandardOption> =>
  instance.get(`extract/standard-option/${id}`).then((res) => res.data);

export const createStandardOption = async (body?: StandardOption): Promise<void> =>
  body && instance.post(`extract/standard-option`, body);

export const editStandardOption = async (body?: StandardOption): Promise<void> =>
  body && instance.put(`extract/standard-option/${body.id}`, body);

export const deleteStandardOption = async (id: number): Promise<void> =>
  instance.delete(`extract/standard-option/${id}`);

export const getOptions = async (appSearchParams: AppSearchParams): Promise<OptionListItem[]> =>
  instance.get(`extract/options`, { params: { ...appSearchParams, from_cache: false } }).then((res) => res.data);

export const getOption = async (id: number): Promise<Option> =>
  instance.get(`extract/option/${id}`).then((res) => res.data);

export const createOption = async (body: Option): Promise<void> => instance.post(`extract/option`, body);

export const editOption = async (id: number, body: Option): Promise<void> => instance.put(`extract/option/${id}`, body);

export const deleteOption = async (id: number): Promise<void> => instance.delete(`extract/option/${id}`);

export const getCarOptionsAndStandardOptions = (
  props: CarOptionsAndStandardOptionsParams,
): Promise<CarOptionsAndStandardOptionsResult> =>
  instance.get(`extract/car/options`, { params: props }).then((res) => res.data);
