import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ColoredButton, ProgressIndicator } from '../../../../shared/src/components';
import { carMessageMarkAsRead } from '@japieglobal/shared/src/api/services';

export function CarMessageMarkAsAllAsReadButton() {
  const [isLoading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    carMessageMarkAsRead({})
      .then(() => window.location.reload())
      .finally(() => setLoading(false));
  };

  const { t } = useTranslation();
  return (
    <Box marginTop="20px" marginBottom="20px" display="flex" justifyContent="flex-end">
      <div style={{ flexGrow: 1 }} />
      {isLoading && <ProgressIndicator />}
      <ColoredButton onClick={onClick}>{t('MARK_EVERYTHING_AS_READ')}</ColoredButton>
    </Box>
  );
}
