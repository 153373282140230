import { UserRoles } from '@japieglobal/shared/src/api/services';
import { CarResult } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { styled } from '@mui/styles';
import React, { useContext, useMemo } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { createTaxationUrl } from '@japieglobal/shared';
import { Link } from '@mui/material';

const TaxationButtonStyled = styled(Link)({
  height: '25px',
  width: '15px',
  position: 'absolute',
  top: 0,
  left: 0,
  border: 0,
});

export const TaxationButton = ({ car }: { car: CarResult }) => {
  const { user } = useContext(UserContext);
  const url = useMemo(() => createTaxationUrl(car), [car]);

  return (
    user?.role === UserRoles.SUPER_ADMIN && (
      <TaxationButtonStyled href={url} target="_blank">
        <OpenInNewIcon style={{ width: 16 }} />
      </TaxationButtonStyled>
    )
  );
};
