import React from 'react';
import {
  CsmReport,
  CsmReportKeys,
  DealerAccountHealth,
  SalesReportRow,
  updateDealerAccount,
  UpdateDealerAccountResponse,
} from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { HealthCell } from './health-cell';

interface HealthSelectCellProps {
  row: CsmReport | SalesReportRow;
  onDealerUpdated: (newDealerAccount: UpdateDealerAccountResponse) => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const HealthSelectCell = ({ setRowsIsLoading, onDealerUpdated, row }: HealthSelectCellProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedHealth: DealerAccountHealth) => {
    setRowsIsLoading(true);
    updateDealerAccount({
      name: row[CsmReportKeys.DEALER],
      csm: row[CsmReportKeys.CSM],
      status: row[CsmReportKeys.STATUS],
      [CsmReportKeys.HEALTH]: selectedHealth,
    })
      .then((res) => onDealerUpdated(res))
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <HealthCell value={row[CsmReportKeys.HEALTH] as DealerAccountHealth} />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Object.values(DealerAccountHealth).map((health) => (
          <MenuItem key={health} onClick={() => handleSelect(health)}>
            <HealthCell value={health} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
