import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Link } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
  getClicksConfigs,
  ClicksConfig,
  ClicksConfigKeys,
  deleteClicksConfig,
} from '@japieglobal/shared/src/api/services';
import { BaseTable, ConfirmationDialog, TableColDef } from '@japieglobal/shared';
import { SuperAdminPageContainer, PageType } from '../../super-admin-page-container';
import { clicksConfigColumns } from './columns';

export const ClicksConfigs = () => {
  const [clicksConfigs, setClicksConfigs] = useState<ClicksConfig[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dealerToDelete, setDealerToDelete] = useState<string | undefined>(undefined);
  const [propertyIdToDelete, setPropertyIdToDelete] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const handleGetClicksConfigs = useCallback(() => {
    setLoading(true);
    getClicksConfigs()
      .then(setClicksConfigs)
      .catch(snackbarErrorHandler)
      .finally(() => setLoading(false));
  }, [snackbarErrorHandler]);

  useEffect(() => {
    handleGetClicksConfigs();
  }, [handleGetClicksConfigs]);

  const handleDelete = () => {
    if (dealerToDelete !== undefined && propertyIdToDelete !== undefined) {
      setLoading(true);
      deleteClicksConfig(dealerToDelete, propertyIdToDelete.toString())
        .then(() => {
          handleGetClicksConfigs();
          snackbarSuccessMessage(`Clicks config has been permanently delete`);
        })
        .catch(snackbarErrorHandler)
        .finally(() => {
          setLoading(false);
          setDealerToDelete(undefined);
        });
    }
  };

  const columns: TableColDef<ClicksConfig>[] = clicksConfigColumns.map((column) => ({
    ...column,
    headerAlign: 'left',
    align: 'left',
    type: 'string',
    renderCell: (params) =>
      column.cellRenderLogic === 'actions' ? (
        <>
          <IconButton
            href={`/super-admin/${PageType.CLICKS_CONFIG}/edit/${params.row.dealer_name}/${params.row[ClicksConfigKeys.PROPERTY_ID]}`}
            LinkComponent={Link}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="warning"
            onClick={() => {
              setOpenDialog(true);
              setDealerToDelete(params.row[ClicksConfigKeys.DEALER_NAME]);
              setPropertyIdToDelete(params.row[ClicksConfigKeys.PROPERTY_ID]);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </>
      ) : undefined,
  }));

  return (
    <SuperAdminPageContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <IconButton component={Link} href={`/super-admin/${PageType.CLICKS_CONFIG}/create`} aria-label="add">
          <AddCircleIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
      <BaseTable
        isLoading={loading}
        rows={clicksConfigs}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={55}
        rowHeight={35}
        sortingModel={{
          sortModel: [{ sort: 'asc', field: ClicksConfigKeys.DEALER_NAME }],
          sortingMode: 'client',
        }}
      />
      {openDialog && (
        <ConfirmationDialog
          yesAction={() => {
            setOpenDialog(false);
            setDealerToDelete(undefined);
          }}
          title="Delete clicks config"
          question={`Are you sure you want to delete?`}
          closeDialog={() => setOpenDialog(false)}
          noAction={handleDelete}
          textYes="No"
          textNo="Yes"
        />
      )}
    </SuperAdminPageContainer>
  );
};
