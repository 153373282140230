import { UserRoles } from '@japieglobal/shared/src/api/services';
import { CarResult } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { styled } from '@mui/styles';
import React, { useContext } from 'react';
import { Link } from '@mui/material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { createInternalUrlParams } from '@japieglobal/shared';

const SwitchToDealerButtonStyled = styled(Link)({
  height: '25px',
  width: '15px',
  position: 'absolute',
  bottom: 0,
  left: 0,
  border: 0,
});

export const SwitchToDealerButton = ({ car }: { car: CarResult }) => {
  const { user } = useContext(UserContext);

  return (
    user?.role === UserRoles.SUPER_ADMIN && (
      <SwitchToDealerButtonStyled
        href={`/cars?${createInternalUrlParams({ id: car.id, switch_to_dealer: car.dealer?.name })}`}
      >
        <ChangeCircleIcon style={{ width: 16 }} />
      </SwitchToDealerButtonStyled>
    )
  );
};
