import React, { useCallback, useContext, useMemo } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { InformationPopover } from '@japieglobal/shared/src/components/information-popover';
import { resultInfoPopupContent } from './result-info-popup-content';
import { InfoOutlined } from '@mui/icons-material';
import { CarTableCell } from '../../result-table-styles';
import { compareMemoProps } from '../../../../utils/compare-react-props';
import { UserContext } from '@japieglobal/shared/src/user-context';

const StyledSup = styled(`sup`)({
  textTransform: 'uppercase',
  position: 'absolute',
  top: '-10px',
  right: '-7px',
});

const StyledSupDiv = styled(`div`)({
  position: 'relative',
});

export const ResultTableInformationCell = React.memo(
  ({ car }: { car: CarResult }) => {
    const { user } = useContext(UserContext);

    const { t } = useTranslation();

    const apkDays = useMemo(() => {
      return car.stat?.apk_days;
    }, [car.stat?.apk_days]);

    const getInformation = useCallback(
      () => resultInfoPopupContent({ user, car, t, apkDays }),
      [user, apkDays, car, t],
    );

    return (
      <CarTableCell>
        {car.supply_type && (
          <StyledSupDiv>
            <StyledSup className="supplyType">{car.supply_type[0]}</StyledSup>
          </StyledSupDiv>
        )}
        <InformationPopover
          parentKey={`${car.id}-info`}
          information={getInformation()}
          Element={() => <InfoOutlined color={apkDays && apkDays <= 30 ? 'warning' : 'primary'} />}
        />
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
