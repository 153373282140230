import { useState } from 'react';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { urlParseNumber } from '../utils/url-value-parsers';

export const usePriceRankingStates = () => {
  const [pricingTarget, setPricingTarget] = useState<number | undefined>(undefined);
  const [priceDiscount, setPriceDiscount] = useQueryString<number | undefined>(
    'priceDiscount',
    undefined,
    false,
    urlParseNumber,
  );

  const resetPriceRanking = () => {
    setPriceDiscount(undefined);
    setPricingTarget(undefined);
  };

  return { pricingTarget, setPricingTarget, priceDiscount, setPriceDiscount, resetPriceRanking };
};
