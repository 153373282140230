import { Grid2 } from '@mui/material';
import React, { useMemo } from 'react';
import { NumberFilter, AppSearchParamKeys } from '@japieglobal/shared';
import { useAppSearchParams } from '../../../../../shared/src/components';

export const TaxationFilterMileage = () => {
  const max = useMemo(() => 1000000, []);
  const [{ license_plate }] = useAppSearchParams();

  return (
    <Grid2 size={2}>
      <NumberFilter
        tabOnEnter={!license_plate}
        labelTranslationKey="MILEAGE"
        max={max}
        searchParamKey={AppSearchParamKeys.MILEAGE}
        hideResetButton
      />
    </Grid2>
  );
};
