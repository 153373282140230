import React from 'react';
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { LoadingWrapper } from '@japieglobal/shared';

interface RdwPageContainerProps {
  onSubmit: (event: React.FormEvent) => void;
  isLoading?: boolean;
}
export const RdwPageContainer = ({
  children,
  isLoading = false,
  onSubmit,
}: PropsWithChildren<RdwPageContainerProps>) => (
  <LoadingWrapper isLoading={isLoading}>
    <SuperAdminPageContainer>
      <form onSubmit={onSubmit} style={{ flexFlow: 'row' }}>
        <Box
          display="flex"
          flexDirection="column"
          margin="10px"
          marginRight="25px"
          marginLeft="25px"
          paddingY="10px"
          width="100%%"
          minWidth="350px"
          rowGap="10px"
        >
          {children}
        </Box>
      </form>
    </SuperAdminPageContainer>
  </LoadingWrapper>
);
