import { AppSearchParamKeys } from '@japieglobal/shared';
import { CsmReportKeys } from '@japieglobal/shared/src/api/services';
import { CsmColumn } from '../../shared/types/csm';

export const csmColumns: CsmColumn[] = [
  {
    field: CsmReportKeys.STATUS,
    headerName: 'Status',
    headerDescription: 'Status',
    type: 'string',
    cellRender: 'statusSelect',
    searchParamKey: AppSearchParamKeys.STATUSES,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.DEALER,
    headerName: 'Dealer',
    headerDescription: 'Dealer',
    flex: 3,
    type: 'string',
    cellRender: 'link',
    searchParamKey: AppSearchParamKeys.DEALER,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.TOTAL_USAGE_CHANGE,
    headerName: 'Total usage change (%)',
    headerDescription: 'Total usage change (%)',
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_CHANGE,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.TOTAL_CALLS_CHANGE,
    headerName: 'Total calls change (%)',
    headerDescription: 'Total calls change (%)',
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_CALLS_CHANGE,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.TOTAL_USAGE_0_30,
    headerName: 'Total usage 0 - 30 days (%)',
    headerDescription: 'Total usage 0 - 30 days (%)',
    type: 'string',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_0_30,
    flex: 1.2,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.ARR_CATEGORY,
    headerName: 'Arr Category',
    headerDescription: 'Arr Category',
    type: 'number',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.ARR_CATEGORY,
    flex: 1.2,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.AUTO_ACCOUNT_HEALTH,
    headerName: 'Auto Account Health',
    headerDescription: 'Auto Account Health',
    type: 'string',
    cellRender: 'health',
    hoverRender: 'score',
    searchParamKey: AppSearchParamKeys.AUTO_ACCOUNT_HEALTH,
    selectType: 'multi-object',
  },
  {
    field: CsmReportKeys.HEALTH,
    headerName: 'Health',
    headerDescription: 'Health',
    type: 'string',
    cellRender: 'healthSelect',
    searchParamKey: AppSearchParamKeys.HEALTHS,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.END_DATE_CONTRACT,
    headerName: 'Einde contract',
    headerDescription: 'Einde contract',
    flex: 2,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.END_DATE_CONTRACT,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.CSM,
    headerName: 'CSM',
    headerDescription: 'CSM',
    flex: 2,
    type: 'string',
    cellRender: 'csmSelect',
    searchParamKey: AppSearchParamKeys.CSM,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.LAST_COMMENT_DATE,
    headerName: 'Last comment',
    headerDescription: 'Last comment',
    flex: 2,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.LAST_COMMENT_DATE,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.COMMENTS,
    headerName: 'MESSAGES',
    headerDescription: 'MESSAGES',
    type: 'string',
    cellRender: 'comments',
    searchParamKey: AppSearchParamKeys.COMMENTS,
    flex: 1.2,
    selectType: 'single',
  },
];
