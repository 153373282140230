import React from 'react';
import { MenuButton } from '../../menu-button';
import { Home } from '@mui/icons-material';
import { TopRowMenuButtonProps } from '../../top-row';

export const HomeButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton
    hoverTitle={'MONITORING'}
    internal={internal}
    link="/cars"
    display={user.permissions.includes('monitoring')}
  >
    <Home />
  </MenuButton>
);
