import React, { useContext, useEffect, useState } from 'react';
import { CarMessage, CarMessageContextType } from '../../../../shared/src/car-message/car-message-types';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { CarMessageOther, CarMessageOwn } from './car-message';
import { getCarId } from '../../utils/car-id';
import { UserContext } from '@japieglobal/shared/src/user-context';

const CarEmptyMessages = React.memo(() => (
  <div
    style={{
      color: 'gray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      minHeight: '30vh',
    }}
  >
    There are no messages
  </div>
));

interface CarMessagesProps {
  carMessageContext: CarMessageContextType;
  car: CarResult;
  scrollToBottom: () => void;
}
export const CarMessages = React.memo(({ carMessageContext, car, scrollToBottom }: CarMessagesProps) => {
  const [messages, setMessages] = useState<CarMessage[] | undefined>(undefined);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (JSON.stringify(carMessageContext.carMessagesByCarId?.[getCarId(car)]) !== JSON.stringify(messages)) {
      if (carMessageContext.carMessagesByCarId?.[getCarId(car)]) {
        setMessages([...carMessageContext.carMessagesByCarId?.[getCarId(car)]]);
      } else {
        setMessages(undefined);
      }
      setTimeout(() => scrollToBottom(), 50);
    }
  }, [car, car.id, carMessageContext, messages, scrollToBottom]);

  return (
    <>
      {messages &&
        messages.map((message) => {
          // eslint-disable-next-line no-underscore-dangle
          if (message.user_id !== user.id) {
            return <CarMessageOther key={message.created} carId={car.id!} msg={message} />;
          }
          return <CarMessageOwn key={message.created} carId={car.id!} msg={message} />;
        })}
      {(!messages || messages.length === 0) && <CarEmptyMessages />}
    </>
  );
});
