import CarMessageBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import React, { useCallback, useContext, useState } from 'react';
import { Menu, Theme } from '@mui/material';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { CarMessageContext } from '@japieglobal/shared/src/car-message/car-message-context';
import { useSearchParams } from 'react-router-dom';
import { Bubble } from '../../../components/bubble';
import { BoxFlexColumn, CarTableCell, ColoredButtonSmall } from '../result-table-styles';
import { CarMessageDialog } from '../../car-message/car-message-dialog';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { MailButton } from '../../../taxation-and-results-shared/buttons/mail-button/mail-button';
import { UserContext } from '@japieglobal/shared/src/user-context';

const Wrapper = styled(BoxFlexColumn)({
  paddingRight: '10px',
  paddingTop: '5px',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    noPadding: {
      padding: 0,
    },
  }),
);

const StyledButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
});
const StyledButtonTop = styled(StyledButton)({
  marginBottom: '5px',
});

const CarMessageIconStyled = styled(CarMessageBubbleOutlineIcon)({
  height: '16px',
});

const initialState: { mouseX: number | null; mouseY: number | null } = {
  mouseX: null,
  mouseY: null,
};

interface CarMessageButtonWithContextMenuProps {
  onClick: () => void;
  unreadMessagesCount: number;
}

const CarMessageButtonWithContextMenu = ({ onClick, unreadMessagesCount }: CarMessageButtonWithContextMenuProps) => {
  const [state, setState] = React.useState(initialState);

  const onContextMenuOpen = useCallback((event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }, []);

  const onContextMenuClose = useCallback(() => {
    setState(initialState);
  }, []);

  return (
    <div onContextMenu={onContextMenuOpen} style={{ cursor: 'context-menu' }}>
      <StyledButtonTop onClick={onClick}>
        <CarMessageIconStyled className="notesButton" />
        {unreadMessagesCount > 0 && <Bubble>{unreadMessagesCount}</Bubble>}
      </StyledButtonTop>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={onContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null ? { top: state.mouseY, left: state.mouseX } : undefined
        }
      />
    </div>
  );
};

interface ResultCarMessageShareCellProps {
  car: CarResult;
  windowView: boolean | undefined;
}

export const ResultCarMessageShareCell = React.memo(
  ({ car, windowView }: ResultCarMessageShareCellProps) => {
    const [searchParams] = useSearchParams();
    const openDialog = searchParams.get('show_car_messages') && searchParams.get('id') === String(car.id);
    const [showCarMessageDialog, setShowCarMessageDialog] = useState(!!openDialog);
    const onClickCarMessage = useCallback(() => setShowCarMessageDialog(true), []);
    const classes = useStyles();
    const { user } = useContext(UserContext);

    return (
      <CarTableCell className={windowView ? classes.noPadding : undefined}>
        <Wrapper className={windowView ? classes.noPadding : undefined}>
          <CarMessageContext.Consumer>
            {(carMessages) => {
              const unreadMessagesCount =
                ((carMessages?.value?.carMessagesByCarId || {})[car.id!] || []).filter((c) => !c.read).length || 0;

              return (
                <CarMessageButtonWithContextMenu
                  onClick={onClickCarMessage}
                  unreadMessagesCount={unreadMessagesCount}
                />
              );
            }}
          </CarMessageContext.Consumer>

          {user.permissions.includes('email') && <MailButton isTaxation={false} car={car} />}
          <CarMessageContext.Consumer>
            {(carMessageContext) => (
              <CarMessageDialog
                car={car}
                showDialog={showCarMessageDialog}
                setShowDialog={setShowCarMessageDialog}
                carMessageContext={carMessageContext}
              />
            )}
          </CarMessageContext.Consumer>
        </Wrapper>
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
