import { isDeepEqual } from '@japieglobal/shared/src/utils';

const compareDeepEqualProvider = <T>(keys: Array<keyof T>, key: keyof T) => {
  if (keys.includes(key)) {
    return isDeepEqual;
  }
  return undefined;
};

export const compareMemoProps =
  <T extends Record<string, unknown>>(keys?: Array<keyof T>) =>
  (prevProps: T, nextProps: T) => {
    if (Object.values(prevProps).length !== Object.values(nextProps).length) {
      return false;
    }

    return Object.keys(prevProps).reduce((previousValue, propKey) => {
      if (previousValue) {
        const resultCompareFunction =
          keys && keys.length > 0 ? compareDeepEqualProvider(keys, propKey as keyof T) : undefined;
        if (resultCompareFunction) {
          if (resultCompareFunction(prevProps[propKey as keyof T], nextProps[propKey as keyof T]) !== true) {
            return false;
          }
        } else if (prevProps[propKey as keyof T] !== nextProps[propKey as keyof T]) {
          return false;
        }
      }
      return previousValue;
    }, true);
  };
