export enum Fuel {
  PETROL = 'PETROL',
  HYBRID = 'HYBRID',
  HYBRID_DIESEL = 'HYBRID_DIESEL',
  DIESEL = 'DIESEL',
  ELECTRICITY = 'ELECTRICITY',
  LPG = 'LPG',
  HYDROGEN = 'HYDROGEN',
  OTHER = 'OTHER',
}
