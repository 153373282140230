import React from 'react';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import { ClicksPassedDown } from '../../../states/click-states';
import { CarWindowMap } from '../map/car-window-map';
import { CarResult } from '@japieglobal/shared/src/types';

interface CarWindowTableCellsProps {
  clicks: ClicksPassedDown;
  ownCar: CarResult;
}

export const CarWindowMapHeaderCell = ({ clicks, ownCar }: CarWindowTableCellsProps) => {
  const unlockMapIconActive = clicks.location.value !== undefined || clicks.polygon.value !== undefined;

  const onClick = () => {
    const value = clicks.polygon.value || clicks.location.value;
    clicks.polygon.setter(!value);
    clicks.location.setter(!value);
  };

  const icon =
    clicks.location.value || clicks.polygon.value ? (
      <VpnLockIcon onClick={onClick} />
    ) : (
      <PublicIcon onClick={onClick} />
    );
  return ownCar?.window_car_ids?.length ? (
    <>
      <CarWindowMap ownCar={ownCar} windowCarIds={ownCar.window_car_ids} />
      {unlockMapIconActive && icon}
    </>
  ) : (
    <></>
  );
};
