import { User, UserRoles } from '@japieglobal/shared/src/api/services';
import { AdminPageType } from '../hooks/admin-query-state';

const NON_ADMIN_PAGES_WITHOUT_ACCESS_REQUIREMENT = [
  AdminPageType.USERS,
  AdminPageType.EDIT_USER,
  AdminPageType.ADD_USER,
];

export const isUserAllowedToSeePage = ({ user, pageType }: { user: User; pageType: AdminPageType }) => {
  const isSuperAdmin = user.role === UserRoles.SUPER_ADMIN;
  const hasFeatureActivityChart = user.permissions.includes('userActivity');

  const displayPage =
    NON_ADMIN_PAGES_WITHOUT_ACCESS_REQUIREMENT.includes(pageType) ||
    (hasFeatureActivityChart && pageType === AdminPageType.ACTIVITY_LIST) ||
    (user.role === UserRoles.ADMIN && AdminPageType.SERVICE_ACCOUNTS) ||
    isSuperAdmin ||
    (user.role === UserRoles.SCRAPE_USER && user.permissions.includes('scrapeManagement'));

  return displayPage;
};
