import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types';
import { styled } from '@mui/material';
import { ColoredButtonSmall } from '../result-table-styles';

const ImageStyled = styled(`img`)(({ theme }) => ({
  filter: theme.imageFilter?.darkFilter,
}));

const buildTaxationUrl = (car: CarResult) => {
  const url = Object.entries(car)
    .filter(([k]) =>
      [
        'make',
        'model',
        'body',
        'fuel',
        'gear',
        'hp',
        'build',
        'model_year',
        'mileage',
        'equipment',
        'options',
        'license_plate',
        'location_name',
        'price',
      ].includes(k),
    )
    .flatMap(([k, v]) => {
      if (k.startsWith('equipment')) {
        return Object.entries(v).map(([k, v]) => ['equipment_' + k, v]);
      } else if (k == 'price') return [['start_price', v['local']]];
      return [[k, v]];
    })
    .map(([k, v]) => {
      if (k == 'location_name') return ['location_names', v];
      return [k, v];
    })
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');
  return url;
};

export const ResultTaxationButton = ({ car }: { car: CarResult }) => {
  return (
    <ColoredButtonSmall
      style={{ minWidth: '40px', maxWidth: '40px', height: '30px', marginTop: '5px', backgroundColor: 'white' }}
      onClick={() => window.open('/taxatie?' + buildTaxationUrl(car))}
    >
      <ImageStyled src="/images/taxation-button.png" width={30} alt="" />
    </ColoredButtonSmall>
  );
};
