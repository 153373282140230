import React from 'react';
import { MultiSelectFilter } from '@japieglobal/shared/src/components';
import { AppSearchParamKeys } from '@japieglobal/shared/src/components/filter/types';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

const carHeaderOptions: CarHeaderProps = { type: CarHeader.MAKE };
export const FilterMake = () => (
  <MultiSelectFilter
    searchParamKey={AppSearchParamKeys.MAKES}
    carHeaderOptions={carHeaderOptions}
    labelTranslationKey="MAKE"
  />
);
