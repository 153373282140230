import React, { useContext, useState } from 'react';
import { MenuButton } from '../../menu-button';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { UserRoles } from '../../../../api/services';
import { UserContext } from '../../../../user-context';

export const SuperAdminButton = ({ internal }: { internal: boolean }) => {
  const [isShown, setShown] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <MenuButton
      style={{ opacity: isShown ? 100 : 0, height: '42px' }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
      hoverTitle={'SUPER_ADMIN'}
      display={user?.role === UserRoles.SUPER_ADMIN}
      internal={internal}
      link="/super-admin"
    >
      <LocalPoliceIcon />
    </MenuButton>
  );
};
