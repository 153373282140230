export interface TaxationColumn {
  field: string;
  flex: number;
  headerName: string;
  type: string;
  columnType: string;
}

export const TaxationsColumnsValues: TaxationColumn[] = [
  { field: 'created', headerName: 'Date', flex: 2, type: 'string', columnType: 'date' },
  { field: 'user_email', headerName: 'User', flex: 5, type: 'number', columnType: 'hover' },
  { field: 'license_plate', headerName: 'License plate', flex: 2, type: 'number', columnType: 'value' },
  { field: 'make', headerName: 'Make', flex: 2.5, type: 'number', columnType: 'value' },
  { field: 'model', headerName: 'Model', flex: 2.5, type: 'number', columnType: 'value' },
  { field: 'stat_turnover_int', headerName: 'ITR', flex: 0.5, type: 'number', columnType: 'value' },
  { field: 'stat_turnover_ext', headerName: 'ETR', flex: 0.5, type: 'number', columnType: 'value' },
  { field: 'apr', headerName: 'APR', flex: 0.5, type: 'number', columnType: 'value' },
  {
    field: 'rank_target_value',
    headerName: 'VVP Protocol',
    flex: 2,
    type: 'number',
    columnType: 'value',
  },
  { field: 'topdown_value', headerName: 'TDC Gross', flex: 2, type: 'number', columnType: 'value' },
  {
    field: 'extra_costs',
    headerName: 'Extra costs',
    flex: 2,
    type: 'number',
    columnType: 'value',
  },
  {
    field: 'description',
    headerName: 'Notes',
    flex: 4,
    type: 'number',
    columnType: 'hover',
  },
];

export const TaxationsColumnsChange: TaxationColumn[] = [
  {
    field: 'fuel',
    headerName: '',
    flex: 0.5,
    type: 'number',
    columnType: 'runTaxation',
  },
];

export const TaxationsColumns = [...TaxationsColumnsValues, ...TaxationsColumnsChange];
