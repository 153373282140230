import { AxiosResponse } from 'axios';

export const responseFileDownload = (filename: string) => async (res: AxiosResponse) => {
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(res.data);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
