import React, { useCallback, useState } from 'react';
import { NetworkError } from '../../../shared/src/api/network-error';
import { AuthLink } from './auth-styled-components';
import { AuthStyleContainer } from './auth-style-container';
import { AuthTwoFactorComponent } from './auth-two-factor-component';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseTextInput } from '@japieglobal/shared/src/components/inputs/base-text-input/base-text-input';
import { ColoredButton } from '@japieglobal/shared';
import { Box, FormControl } from '@mui/material';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { login } from '@japieglobal/shared/src/api/services';

interface AuthLoginPageProps {
  setLoggedIn: () => void;
}
export const AuthLoginPage = ({ setLoggedIn }: AuthLoginPageProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);

  const [showTwoFactorDialog, setShowTwoFactorDialog] = useState<boolean>(false);
  const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(false);

  const submit = useCallback(() => {
    login({ email, password })
      .then((res) => {
        if (!res.verified) {
          setHasPhoneNumber(res.has_phone_number);
          setShowTwoFactorDialog(true);
        } else {
          setLoggedIn();
          if (searchParams.get('callback-url')) {
            // bit hacky to get the params of redirect url to work
            const redirectParams = Array.from(searchParams.entries())
              .filter(([k]) => k !== 'callback-url')
              .map(([k, v]) => `&${k}=${v}`)
              .join('');
            window.location.href = searchParams.get('callback-url') + redirectParams;
          }
        }
      })
      .catch(async (error: NetworkError) => {
        setAlertMessage(t(await error.getErrorMessage()));
        snackbarErrorHandler(error);
      });
  }, [t, email, password, setLoggedIn, searchParams, snackbarErrorHandler]);

  return (
    <AuthStyleContainer
      alertMessage={alertMessage}
      infoMessage={!hasPhoneNumber && showTwoFactorDialog ? t('TWO_FACTOR_EXPLANATION') : undefined}
    >
      <FormControl onSubmit={submit} component={Box} display={'flex'} flexDirection={'column'} rowGap={'10px'}>
        <BaseTextInput
          type="email"
          label={t('EMAIL')}
          required
          name="email"
          value={email}
          onChange={setEmail}
          setValue={setEmail}
        />
        <BaseTextInput
          type="password"
          label={t('PASSWORD')}
          required
          name="password"
          value={password}
          onChange={setPassword}
          setValue={setPassword}
        />

        {showTwoFactorDialog ? (
          <AuthTwoFactorComponent
            successAction={setLoggedIn}
            hasPhoneNumber={hasPhoneNumber}
            setHasPhoneNumber={setHasPhoneNumber}
          />
        ) : (
          <>
            <ColoredButton type="submit" onClick={submit} disabled={!password || !email}>
              {t('LOG_IN')}
            </ColoredButton>
            <AuthLink to="/auth/forgot">{t('FORGOTTEN_PASSWORD')}</AuthLink>
          </>
        )}
      </FormControl>
    </AuthStyleContainer>
  );
};
