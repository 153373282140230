import { Paper, styled } from '@mui/material';
import React from 'react';

const StyledWrapper = styled(`div`)({
  marginTop: '40px',
});

const StyledTitleWrapper = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 64,
  display: 'flex',
  flexDirection: 'row',
  paddingRight: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledTitle = styled(`span`)({
  color: 'white',
  fontSize: '20px',
});

const StyledInner = styled(`div`)({
  position: 'relative',
});

interface AdminMainWrapperProps {
  title: string;
  titleRightComponent?: JSX.Element;
  children: any;
}
export const AdminMainWrapper = ({ title, children, titleRightComponent }: AdminMainWrapperProps) => (
  <StyledWrapper>
    <Paper>
      <StyledTitleWrapper>
        <StyledTitle>{title}</StyledTitle>
        {titleRightComponent || null}
      </StyledTitleWrapper>
      <StyledInner>{children}</StyledInner>
    </Paper>
  </StyledWrapper>
);
