import React from 'react';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { ToggleFilter, AppSearchParamKeys } from '@japieglobal/shared';

export const MissingOptionsButton = () => (
  <ToggleFilter
    disableTripleState
    searchParamKey={AppSearchParamKeys.MISSING_OPTIONS}
    labelTranslationKey="MISSING_OPTIONS"
    icon={<SearchOffIcon />}
    className="missingOptionsButton"
  />
);
