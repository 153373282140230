import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { DialogActions, FormControlLabel, Switch, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
  ColoredButton,
  MultiSelectInput,
  SelectOption,
  valuesToSelectOptions,
} from '@japieglobal/shared/src/components';
import { shareCar, shareTaxation } from '@japieglobal/shared/src/api/services';
import { CarResult } from '@japieglobal/shared/src/types';
import { DealerUsersContext } from '../../../states/dealer-users-context';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { getMoney } from '@japieglobal/shared/src/utils';

const useStyles = makeStyles({
  general: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '6px',
    marginBottom: '16px',
    resize: 'vertical',
  },
  rounded: {
    padding: '12px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});
interface CarShareDialogProps {
  car: CarResult;
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  isTaxation: boolean;
}
export const CarShareDialog = ({ isTaxation, car, showDialog, setShowDialog }: CarShareDialogProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [emails, setEmails] = useState<SelectOption[]>([]);
  const [receiveCCEmail, setReceiveCCEmail] = useState(true);
  const [note, setNote] = useState<string>('');
  const { snackbarErrorHandler, snackbarErrorMessage } = useSnackbarErrorHandler();
  const { dealerUsers, dealerUsersIsLoading } = useContext(DealerUsersContext);
  const {
    user: { email, first_name, last_name },
  } = useContext(UserContext);

  const handleClose = () => setShowDialog(false);

  const onSubmit = () => {
    const emailValues = emails.map((email) => email.value);
    if (emails.length > 0) {
      const appendUserEmail = receiveCCEmail && !emailValues.includes(email);
      (isTaxation
        ? shareTaxation(
            {
              license_plate: car.license_plate || 'NB',
              text: note,
              price: car.rank!.target_value!,
              url: window.location.href,
              tdc: car.topdown_value!,
              etr: car.stat!.turnover_ext!,
            },
            emailValues.concat(appendUserEmail ? [email] : []),
          )
        : shareCar(
            {
              text: note,
              car_id: car.id,
              model: car.model!,
              make: car.make!,
              license_plate: car.license_plate!,
            },
            emailValues.concat(appendUserEmail ? [email] : []),
          )
      )
        .then(handleClose)
        .catch(snackbarErrorHandler);
    } else {
      snackbarErrorMessage(`Emails are empty`);
    }
  };

  const userHasName = !!(first_name || last_name);
  const userName = (userHasName && `${first_name} ${last_name}`) || '';
  const userEmail = (userHasName && `(${email})`) || email;
  const fromContent = `${userName.trim()} ${userEmail}`.trim();

  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="export"
      open={showDialog}
      disableEnforceFocus
    >
      <>
        <DialogTitle>{t('SHARE_CAR_VIA_EMAIL')}</DialogTitle>
        <DialogContent dividers>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img width="90px" style={{ marginRight: '10px' }} alt="car" src={car.image} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4>
                {car.make}&nbsp;{car.model}&nbsp;{getMoney(user, car.rank?.target_value)}
              </h4>
              <span>{car.license_plate}</span>
            </div>
            <div style={{ flexGrow: 1 }} />
          </div>
          <form onSubmit={onSubmit} style={{ padding: '5px' }}>
            <span>{t('FROM')}</span>
            <div id="some" className={`${classes.rounded} ${classes.general}`}>
              {fromContent}
            </div>
            <span>{t('TO')}</span>
            <MultiSelectInput
              options={valuesToSelectOptions(dealerUsers.map((dealerUser) => dealerUser.email))}
              label={t('TYPE_TO_SEARCH_USER')}
              freeSolo
              setValue={setEmails}
              value={emails}
              limitTags={5}
              isLoading={dealerUsersIsLoading}
            />
            <div>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={receiveCCEmail}
                    onChange={() => setReceiveCCEmail(!receiveCCEmail)}
                    name="receiveCCEmail"
                  />
                }
                label={t('RECEIVE_CC_EMAIL')}
              />
            </div>
            <span>{t('NOTE')}</span>
            <textarea
              className={`${classes.rounded} ${classes.general}`}
              rows={5}
              placeholder={t('OPTIONAL')}
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <ColoredButton disabled={emails.length === 0} onClick={onSubmit}>
            {t('SEND')}
          </ColoredButton>
        </DialogActions>
      </>
    </Dialog>
  );
};
