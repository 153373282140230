import React, { useCallback, useState } from 'react';
import { AuthStyleContainer } from './auth-style-container';
import { AuthInfoTextWithMargin, AuthLink, AuthTextField } from './auth-styled-components';
import { ColoredButton } from '@japieglobal/shared';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { forgotPassword, forgotPhoneNumber } from '@japieglobal/shared/src/api/services';

export const AuthForgotPage = ({ type }: { type: 'password' | 'phoneNumber' }) => {
  const [infoMessage, setInfoMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const handleInputChange = (e: { target: { name: string; value: string } }) => {
    const { value } = e.target;
    setEmail(value);
  };

  const submit = useCallback(() => {
    if (type === 'password' && (!email || email.trim().length === 0)) {
      setAlertMessage(`Please fill in an email`);
      return;
    }
    if (type == 'password') {
      forgotPassword({ email })
        .then(() => {
          setAlertMessage('');
          setEmail('');
          setInfoMessage(
            'Er is een e-mail naar je verstuurd met een link waarmee je je wachtwoord kunt resetten. Check je spam-map als je binnen een paar minuten geen e-mail ontvangt.',
          );
        })
        .catch(snackbarErrorHandler);
    } else {
      forgotPhoneNumber({ email })
        .then(() => {
          setAlertMessage('');
          setEmail('');
          setInfoMessage(
            'Er is een e-mail naar je verstuurd met een link waarmee je je telefoon nummer kunt resetten. Check je spam-map als je binnen een paar minuten geen e-mail ontvangt.',
          );
        })
        .catch(snackbarErrorHandler);
    }
  }, [email, type, snackbarErrorHandler]);

  const explanationMessage =
    type === 'password' ? t('FILL_EMAIL_FOR_FORGOT_PASSWORD_LINK') : t('FILL_EMAIL_FOR_PHONE_REGISTER_LINK');
  return (
    <AuthStyleContainer alertMessage={alertMessage} infoMessage={infoMessage}>
      <AuthInfoTextWithMargin>{explanationMessage}</AuthInfoTextWithMargin>
      {type === 'password' && (
        <AuthTextField
          variant="outlined"
          type="email"
          placeholder="E-mail"
          required
          name="email"
          value={email}
          onChange={handleInputChange}
        />
      )}
      <ColoredButton type="submit" onClick={submit}>
        {t('SUBMIT')}
      </ColoredButton>
      <AuthLink to="/login">{t('BACK_TO_LOGIN')}</AuthLink>
    </AuthStyleContainer>
  );
};
