import React from 'react';
import { CarMessageContextType } from './car-message-types';

export type CarMessageContextSetterGetter = {
  value: CarMessageContextType | undefined;
  setter: (val: CarMessageContextType | undefined) => void;
};

export const CarMessageContext = React.createContext<CarMessageContextSetterGetter>({
  value: undefined,
  setter: () => undefined,
});
