import React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

interface ExportToolbarProps {
  exportToPdf: boolean;
  exportToCsv: boolean;
}

export const ExportToolbar = ({ exportToPdf, exportToCsv }: ExportToolbarProps) => {
  if (exportToPdf || exportToCsv) {
    return (
      <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
        <GridToolbarExport
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
            disableToolbarButton: !exportToPdf,
          }}
          csvOptions={{
            disableToolbarButton: !exportToCsv,
          }}
          startIcon={undefined}
          style={{ color: '#fff', height: '40px', marginBottom: '10px' }}
        />
      </GridToolbarContainer>
    );
  }
  return null;
};
