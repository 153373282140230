import React, { useContext, useState } from 'react';
import { MenuButton } from '../../menu-button';
import GroupIcon from '@mui/icons-material/Group';
import { UserRoles } from '../../../../api/services';
import { UserContext } from '../../../../user-context';

export const UserManagementButton = ({ internal }: { internal: boolean }) => {
  const [isShown, setShown] = useState(false);
  const { user } = useContext(UserContext);

  return (
    <MenuButton
      style={{ opacity: isShown ? 100 : 0, height: '42px' }}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
      display={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(user.role)}
      hoverTitle={'USER_MANAGEMENT'}
      link={`/admin/users?userFilter%5Bdealer%5D=${user.dealer}`}
      internal={internal}
    >
      <GroupIcon />
    </MenuButton>
  );
};
