import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';
import React from 'react';

const Container = styled('div')({
  zIndex: 1,
  top: '0px',
  right: '0px',
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
});

export const ProgressIndicator = () => (
  <Container>
    <CircularProgress size={40} />
  </Container>
);
