import React, { FormEvent, useState } from 'react';
import { useAppSearchParams, ColoredBorderButton } from '@japieglobal/shared/src/components';
import { AppSearchParamKeys } from '@japieglobal/shared/src/components/filter/types';
import { Dialog, DialogContent, DialogTitle, Badge, Grid2, Chip, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OptionIcon, optionIcons } from '../../cars/car-window/car-window-icons';
import { TaxationFilterTaxationButton } from './taxation-filter-taxation-button';
import { CarResult } from '@japieglobal/shared/src/types';
import { TaxationStep } from '../taxation-steps/taxation-step';
import { CarHeader } from '@japieglobal/shared/src/api/services';
import { noop } from '@japieglobal/shared/src/utils';

interface TaxationFilterOptionsDialogProps {
  handleTaxation: (event: FormEvent) => void;
  car: CarResult | undefined;
}
export const TaxationFilterOptionsDialog = ({ car, handleTaxation }: TaxationFilterOptionsDialogProps) => {
  const [{ make, options }] = useAppSearchParams([AppSearchParamKeys.MAKE, AppSearchParamKeys.OPTIONS]);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const OptionChip = ({ option, disabled }: { option: string; disabled: boolean }) => (
    <Grid2>
      <Chip
        disabled={disabled}
        label={t(option.toUpperCase())}
        icon={optionIcons[option] && <OptionIcon color="inherit" enabled option={option} missing={false} />}
      />
    </Grid2>
  );

  return (
    <>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{t('OPTIONS')}</DialogTitle>
        <DialogContent>
          <TaxationStep goToNextStep={noop} inputKey={CarHeader.OPTIONS} />
        </DialogContent>
        <DialogActions>
          <TaxationFilterTaxationButton
            onSubmit={(event) => {
              setOpen(!open);
              handleTaxation(event);
            }}
          />
        </DialogActions>
      </Dialog>
      <Badge badgeContent={options?.length} color="primary">
        <ColoredBorderButton disabled={!make} color="secondary" fullWidth onClick={() => setOpen(!open)}>
          {options && options?.length > 0 ? (
            <Grid2 container spacing={'4px'}>
              {options?.map(
                (option) =>
                  !car?.standard_options?.includes(option) && (
                    <OptionChip key={option} option={option} disabled={false} />
                  ),
              )}
              {car?.standard_options?.map((option) => <OptionChip key={option} option={option} disabled />)}
            </Grid2>
          ) : (
            t('OPTIONS')
          )}
        </ColoredBorderButton>
      </Badge>
    </>
  );
};
