import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { SelectInput, SelectOption } from '@japieglobal/shared';
import { useTranslation } from 'react-i18next';
import { carHeader } from '@japieglobal/shared/src/api/services/common';
import { CarHeader } from '@japieglobal/shared/src/api/services';

export const MakeSelect = ({ setValue, value }: { value?: SelectOption; setValue: (value: SelectOption) => void }) => {
  const [makes, setMakes] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getMakes = useCallback(() => {
    setIsLoading(true);
    carHeader({ type: CarHeader.MAKE, dealer_specific: false })
      .then((data) => setMakes((data.headers as string[]).map((make) => ({ label: make, value: make }))))
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [snackbarErrorHandler]);

  useEffect(() => {
    getMakes();
  }, [getMakes]);

  return <SelectInput isLoading={isLoading} label={t('MAKE')} options={makes} setValue={setValue} value={value} />;
};
