import { ClicksConfig, ClicksConfigKeys } from '@japieglobal/shared/src/api/services';

export interface ClicksConfigColumn {
  field: keyof ClicksConfig;
  headerName: string;
  headerDescription: string;
  flex: number;
  cellRenderLogic?: string;
}

export const clicksConfigColumns: ClicksConfigColumn[] = [
  {
    field: ClicksConfigKeys.DEALER_NAME,
    headerName: 'Dealer name',
    headerDescription: 'Dealer',
    flex: 1,
  },
  {
    field: ClicksConfigKeys.PROPERTY_ID,
    headerName: 'Property id',
    headerDescription: '',
    flex: 1,
  },
  {
    field: ClicksConfigKeys.REGEX,
    headerName: 'Regex',
    headerDescription: '',
    flex: 2,
  },
  {
    field: ClicksConfigKeys.CLICKS_THRESHOLD,
    headerName: 'Clicks threshold',
    headerDescription: '',
    flex: 1,
  },
  {
    field: ClicksConfigKeys.ID,
    headerName: '',
    headerDescription: '',
    cellRenderLogic: 'actions',
    flex: 1,
  },
];
