import { instance } from './instance';
import { sendMessage } from './common';
import { sendPriceProposalEmail } from './email';
import { getMoney } from '../../utils';
import { User } from './user';

export const removePriceProposal = async (props: { id: number }) =>
  instance
    .delete(`/api/proposals/${props.id}`)
    .then((res) => res.data)
    .then(() =>
      sendMessage({
        car_id: String(props.id),
        content: 'Prijsvoorstel verwijderd',
      }),
    );

interface CreatePriceProposalProps {
  body: CreatePriceProposalBody;
  id: number;
  user: User;
}
interface CreatePriceProposalBody {
  rank_current_value: number | undefined;
  rank_target_value: number | undefined;
}
export const createPriceProposal = async ({ body, id, user }: CreatePriceProposalProps) =>
  instance
    .post(`/api/cars/${id}/proposals`, body)
    .then((res) => res.data)
    .then((res) => {
      const proposal = res.result;
      if (!proposal.hidden) {
        const message = `Prijsaanpassing: van ${getMoney(user, body.rank_current_value)} naar ${getMoney(user, body.rank_target_value)}`;
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          license_plate,
          make,
          model,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          location_name,
          id,
        } = proposal.car;
        sendMessage({
          car_id: id,
          content: message,
        });
        sendPriceProposalEmail({
          message: proposal.car.location_name ? message + ` (${proposal.car.location_name})` : message,
          license_plate,
          make,
          model,
          location_name,
          car_id: id,
        });
      }
    });
