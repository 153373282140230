import React, { useState } from 'react';
import { ColoredButton, TextInput } from '@japieglobal/shared';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton } from '@mui/material';
import { Option } from '@japieglobal/shared/src/api/services';

interface ListEditProps {
  propName: 'ban' | 'pattern' | 'default_options' | 'super_pattern';
  setOption: (option: Option) => void;
  option: Option;
}

const ListEdit = ({ option, propName, setOption }: ListEditProps) => {
  const [newProp, setNewProp] = useState('');

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'row'} columnGap={'10px'} justifyContent={'flex-start'}>
        <TextInput label={propName.toUpperCase()} setValue={setNewProp} value={newProp} />
        <ColoredButton
          onClick={() => {
            if (newProp) {
              const optionPatternsCopy = [...option[propName]];
              optionPatternsCopy.push(newProp);
              setOption({ ...option, [propName]: optionPatternsCopy });
              setNewProp('');
            }
          }}
        >
          Add
        </ColoredButton>
      </Box>
      <ul>
        {option[propName].map((prop, index) => (
          <li key={prop}>
            {prop}
            <IconButton
              color="warning"
              onClick={() => {
                const optionPatternsCopy = [...option[propName]];
                optionPatternsCopy.splice(index, 1);
                setOption({ ...option, [propName]: optionPatternsCopy });
              }}
            >
              <ClearIcon />
            </IconButton>
          </li>
        ))}
      </ul>
    </Box>
  );
};

interface BanDefaultPatternProps {
  setOption: (option: Option) => void;
  option: Option;
}

export const OptionInputs = ({ option, setOption }: BanDefaultPatternProps) => (
  <Box display={'flex'} flexDirection={'row'} columnGap={'10px'}>
    <ListEdit propName="pattern" option={option} setOption={setOption} />
    <ListEdit propName="default_options" option={option} setOption={setOption} />
    <ListEdit propName="ban" option={option} setOption={setOption} />
    <ListEdit propName="super_pattern" option={option} setOption={setOption} />
  </Box>
);
