import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useClickQueryStates } from '../../../states/click-states';

export const BannedCarHeaderCell = () => {
  const clickQueryStates = useClickQueryStates();
  return (
    <>
      {clickQueryStates.clickQueryState.clickIncludeBanned ? (
        <VisibilityIcon onClick={() => clickQueryStates.clickQuerySetters.setClickIncludeBanned(false)} />
      ) : (
        <VisibilityOffIcon onClick={() => clickQueryStates.clickQuerySetters.setClickIncludeBanned(true)} />
      )}
    </>
  );
};
