import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { grey } from '@mui/material/colors';

export const ColoredButton = (buttonProps: ButtonProps) => (
  <Button {...buttonProps} variant={'contained'} disableElevation />
);

export const ColoredOutlinedButton = (buttonProps: ButtonProps) => (
  <Button {...buttonProps} variant={'outlined'} disableElevation />
);

export const ColoredBorderButton = (buttonProps: ButtonProps) => (
  <Button
    {...buttonProps}
    variant={'contained'}
    disableElevation
    style={{ border: '1px solid', borderColor: grey[400] }}
  />
);
