import React, { useMemo, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import { ColoredButton, ColoredOutlinedButton } from '../colored-button';

export interface ConfirmationDialogProps {
  closeDialog: () => void;
  title: string;
  question: string;
  textNo?: string;
  textYes?: string;
  hideNoButton?: boolean;
  escapeAction?: () => void;
  yesAction: () => void;
  noAction?: () => void;
  children?: JSX.Element;
  disabled?: boolean;
}

export const ConfirmationDialog = ({
  closeDialog,
  noAction,
  question,
  title,
  yesAction,
  escapeAction,
  hideNoButton,
  textNo,
  textYes,
  children,
  disabled = false,
}: ConfirmationDialogProps) => {
  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      yesAction();
      closeDialog();
    },
    [closeDialog, yesAction],
  );

  const handleNo = React.useCallback(() => {
    closeDialog();
    if (noAction) {
      noAction();
    }
  }, [closeDialog, noAction]);

  const displayNo = useMemo(() => !hideNoButton, [hideNoButton]);
  const { t } = useTranslation();

  const handleClose = React.useCallback(() => {
    closeDialog();
    if (escapeAction) {
      escapeAction();
    }
  }, [closeDialog, escapeAction]);

  return (
    <Dialog open onClose={handleClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
          <DialogContentText>
            <b>{question}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {displayNo ? (
            <ColoredOutlinedButton onClick={handleNo}>{textNo || t('NO')}</ColoredOutlinedButton>
          ) : undefined}
          <ColoredButton autoFocus type="submit" disabled={disabled}>
            {textYes || t('YES')}
          </ColoredButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
