import React from 'react';
import { NumberFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const TaxationFilterFutureMileage = ({ mileage }: { mileage: number | undefined }) => (
  <NumberFilter
    min={mileage}
    max={1000000}
    labelTranslationKey="MILEAGE_AT_MATURITY"
    searchParamKey={AppSearchParamKeys.MILEAGE_AT_MATURITY}
    className="mileageAtMaturityFilter"
  />
);
