import { instance } from './instance';

export interface Car {
  [key: string]: string;
}

export const getInvalidCars = async (dealer: string): Promise<Car[]> =>
  instance
    .get(`/admin-scrape/api/items/production/${dealer}/extracted_data`, {
      params: { json_response: true, url_correction_enabled: false, export_invalid_cars: true },
    })
    .then((res) => res.data);
