import React from 'react';
import { AppSearchParamKeys, StringAppSearchParam, TextFilter, useAppSearchParams } from '@japieglobal/shared';
import { CsmReportKeys, SuperAdminResponseType } from '@japieglobal/shared/src/api/services';
import { ColumnHeaderFilterProps } from '@japieglobal/shared/src/components/table/types';
import { HeaderMultiFilter } from './header-multi-filter';
import { statusOptions, accountHealthOptions, arrCategoryOptions } from './filter-options';
import { CsmColumn } from '../../types/csm';
import { SalesColumn } from '../../../sales-report/table/sales-report-columns';

interface HeaderFilterWrapper {
  headerFilterProps: ColumnHeaderFilterProps;
  column: CsmColumn | SalesColumn;
  superAdmins: SuperAdminResponseType[];
}
export const HeaderFilterWrapper = ({
  headerFilterProps: { colDef, inputRef },
  column: { searchParamKey },
  superAdmins,
}: HeaderFilterWrapper) => {
  const [searchParams] = useAppSearchParams();
  const { statuses, healths, arr_category, auto_account_health, csm } = searchParams;
  const csmOptions = Object.values(superAdmins).map((superAdmin: SuperAdminResponseType) => ({
    label: superAdmin.full_name ?? 'None',
    value: superAdmin.full_name ?? '',
  }));
  switch (colDef.field) {
    case CsmReportKeys.COMMENTS:
      return undefined;
    case 'id':
      return undefined;
    case CsmReportKeys.STATUS:
      return (
        <HeaderMultiFilter options={statusOptions} filters={statuses} searchParamKey={AppSearchParamKeys.STATUSES} />
      );
    case CsmReportKeys.ARR_CATEGORY:
      return (
        <HeaderMultiFilter
          options={arrCategoryOptions}
          filters={arr_category}
          searchParamKey={AppSearchParamKeys.ARR_CATEGORY}
        />
      );
    case CsmReportKeys.HEALTH:
      return (
        <HeaderMultiFilter
          options={accountHealthOptions}
          filters={healths}
          searchParamKey={AppSearchParamKeys.HEALTHS}
        />
      );
    case CsmReportKeys.AUTO_ACCOUNT_HEALTH:
      return (
        <HeaderMultiFilter
          options={accountHealthOptions}
          filters={auto_account_health}
          searchParamKey={AppSearchParamKeys.AUTO_ACCOUNT_HEALTH}
        />
      );
    case CsmReportKeys.CSM:
      return <HeaderMultiFilter options={csmOptions} filters={csm} searchParamKey={AppSearchParamKeys.CSM} />;
    default:
      return (
        <TextFilter
          hideResetButton
          labelTranslationKey={'search...'}
          searchParamKey={searchParamKey as StringAppSearchParam}
          {...{ InputProps: { ref: inputRef } }}
        />
      );
  }
};
