import React, { useMemo } from 'react';
import { MultiSelectFilter, useAppSearchParams } from '@japieglobal/shared/src/components';
import { AppSearchParamKeys } from '@japieglobal/shared/src/components/filter/types';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const FilterOptions = () => {
  const [{ make }] = useAppSearchParams([AppSearchParamKeys.MAKE]);
  const carHeaderOptions: CarHeaderProps = useMemo(() => ({ type: CarHeader.OPTIONS, make: make }), [make]);
  return (
    <MultiSelectFilter
      searchParamKey={AppSearchParamKeys.OPTIONS}
      carHeaderOptions={carHeaderOptions}
      labelTranslationKey="OPTIONS"
    />
  );
};
