import { MenuItem, Select, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { capitalizeFirstLetter } from '../../utils/string-first-letter-capital';
import { urlParseNumber } from '../../utils/url-value-parsers';
import { AdminActivityPeriod, AdminQueryStateKey } from '../hooks/admin-query-state';

export const SelectMonth = ({ value, setValue }: { value: number; setValue: (val: number) => void }) => {
  const months = useMemo(
    () =>
      new Array(12).fill(0).map((value1, index) => {
        const date = new Date();
        date.setMonth(index);
        return capitalizeFirstLetter(date.toLocaleString('nl-NL', { month: 'long' }));
      }),
    [],
  );

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <Select
      style={{ marginRight: '15px' }}
      SelectDisplayProps={{
        style: {
          margin: '5px',
        },
      }}
      value={value}
      onChange={onChange}
      variant="standard"
    >
      {months.map((month, index) => (
        <MenuItem key={`select-month-${index}`} value={index + 1}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
};

export const SelectYear = ({ value, setValue }: { value: number; setValue: (val: number) => void }) => {
  const years = useMemo(
    () =>
      Array(new Date().getFullYear() - 2016)
        .fill(2017)
        .map((x, y) => x + y),
    [],
  );

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      SelectDisplayProps={{
        style: {
          margin: '5px',
        },
      }}
      variant="standard"
    >
      {years.map((year) => (
        <MenuItem key={`select-year-${year}`} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  );
};

interface SelectPeriodProps {
  value: string;
  dateFrom: string | undefined;
  dateTo: string | undefined;
  setValue: (val: string) => void;
  setDateFrom: (val: string | undefined) => void;
  setDateTo: (val: string | undefined) => void;
}
export const SelectPeriod = ({ value, dateFrom, dateTo, setValue, setDateFrom, setDateTo }: SelectPeriodProps) => {
  const { t } = useTranslation();
  const options = [
    AdminActivityPeriod.LAST_30_DAYS,
    AdminActivityPeriod.LAST_60_DAYS,
    AdminActivityPeriod.CUSTOM_RANGE,
  ];
  const { snackbarErrorMessage } = useSnackbarErrorHandler();

  const DAYS_THRESHOLD = 60;

  const dateFormat = 'YYYY-MM-DD';

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const [customDateFrom, setCustomDateFrom] = useState<string | undefined>(dateFrom);
  const [customDateTo, setCustomDateTo] = useState<string | undefined>(dateTo);

  useEffect(() => {
    const diffDays = dayjs(customDateTo).diff(dayjs(customDateFrom), 'day');
    if (diffDays <= DAYS_THRESHOLD) {
      setDateFrom(customDateFrom);
      setDateTo(customDateTo);
    } else {
      snackbarErrorMessage(
        `Invalid range: period of ${diffDays} days is bigger than the threshold (${DAYS_THRESHOLD} days)`,
      );
    }
  }, [customDateFrom, customDateTo, setDateFrom, setDateTo, snackbarErrorMessage]);

  useEffect(() => {
    if (value === AdminActivityPeriod.LAST_30_DAYS) {
      const thirtyDayAgo = new Date();
      thirtyDayAgo.setDate(new Date().getDate() - 30);
      setDateFrom(
        new Date(thirtyDayAgo.getFullYear(), thirtyDayAgo.getMonth(), thirtyDayAgo.getDate())
          .toLocaleString('sv-SE')
          .substring(0, 10),
      );
      setDateTo(undefined);
    } else if (value === AdminActivityPeriod.LAST_60_DAYS) {
      const sixtyDayAgo = new Date();
      sixtyDayAgo.setDate(new Date().getDate() - 60);
      setDateFrom(
        new Date(sixtyDayAgo.getFullYear(), sixtyDayAgo.getMonth(), sixtyDayAgo.getDate())
          .toLocaleString('sv-SE')
          .substring(0, 10),
      );
      setDateTo(undefined);
    }
  }, [value, setDateFrom, setDateTo, dateFrom, dateTo, setCustomDateTo, setCustomDateFrom]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {value === AdminActivityPeriod.CUSTOM_RANGE && (
        <>
          <DatePicker
            label="Date from"
            format={dateFormat}
            value={dayjs(customDateFrom)}
            onChange={(newValue: Dayjs | null) =>
              setCustomDateFrom(newValue && newValue.isValid() ? newValue.format(dateFormat) : undefined)
            }
            slotProps={{ textField: (params) => <TextField size="small" {...params} variant="filled" /> }}
          />
          <DatePicker
            label="Date to"
            format={dateFormat}
            value={dayjs(customDateTo)}
            onChange={(newValue: Dayjs | null) =>
              setCustomDateTo(newValue && newValue.isValid() ? newValue.format(dateFormat) : undefined)
            }
            slotProps={{ textField: (params) => <TextField size="small" {...params} variant="filled" /> }}
          />
        </>
      )}
      <Select
        value={value}
        onChange={onChange}
        SelectDisplayProps={{
          style: {
            margin: '5px',
          },
        }}
        variant="standard"
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {t(option.toUpperCase())}
          </MenuItem>
        ))}
      </Select>
    </LocalizationProvider>
  );
};

export const useTimeSpan = () => {
  const [timespanMonth, setTimespanMonth] = useQueryString<number>(
    AdminQueryStateKey.MONTH,
    new Date().getMonth() + 1,
    false,
    urlParseNumber,
  );
  const [timespanYear, setTimespanYear] = useQueryString<number>(
    AdminQueryStateKey.YEAR,
    new Date().getFullYear(),
    false,
    urlParseNumber,
  );
  const [timespanPeriod, setTimespanPeriod] = useQueryString<string>(
    AdminQueryStateKey.PERIOD,
    AdminActivityPeriod.LAST_30_DAYS,
    false,
  );

  const [timespanDateFrom, setTimespanDateFrom] = useQueryString<string>(AdminQueryStateKey.DATE_FROM, '', false);
  const [timespanDateTo, setTimespanDateTo] = useQueryString<string>(AdminQueryStateKey.DATE_TO, '', false);

  const timespan = useMemo(() => {
    return `${timespanMonth}-${timespanYear}`;
  }, [timespanMonth, timespanYear]);

  const selectMonth = useMemo(
    () => <SelectMonth value={timespanMonth} setValue={setTimespanMonth} />,
    [setTimespanMonth, timespanMonth],
  );
  const selectYear = useMemo(
    () => <SelectYear value={timespanYear} setValue={setTimespanYear} />,
    [setTimespanYear, timespanYear],
  );

  const [dateFrom, setDateFrom] = useState<string | undefined>(timespanDateFrom || undefined);
  const [dateTo, setDateTo] = useState<string | undefined>(timespanDateTo || undefined);

  useEffect(() => {
    if (timespanPeriod === AdminActivityPeriod.CUSTOM_RANGE) {
      setTimespanDateFrom(dateFrom);
      setTimespanDateTo(dateTo);
    } else {
      setTimespanDateFrom(undefined);
      setTimespanDateTo(undefined);
    }
  }, [setTimespanDateFrom, setTimespanDateTo, timespanPeriod, dateFrom, dateTo]);

  const selectPeriod = useMemo(
    () => (
      <SelectPeriod
        value={timespanPeriod}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setValue={setTimespanPeriod}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
      />
    ),
    [setTimespanPeriod, timespanPeriod, dateFrom, dateTo],
  );

  return {
    timespan,
    timespanMonth,
    timespanYear,
    timespanPeriod,
    selectMonth,
    selectYear,
    selectPeriod,
    dateFrom,
    dateTo,
  };
};
