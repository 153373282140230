import React, { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';
// import logo from '../../../../../public/images/logo.png';
import { FilterResetButton } from './filter-reset-button';
import { ThemeMode } from '../../../../style/types';
import { LocalStorageKeys } from '../../../../types';
import santaLogoBlack from '../../../../../public/images/santa-logo-black.png';
import santaLogoGreen from '../../../../../public/images/santa-logo-green.png';

export interface FiltersWrapperProps {
  filterCount?: number;
  onClick?: () => void;
  hideResetAllButton?: boolean;
  extraButtons?: JSX.Element[];
}

const ButtonsWrapper = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  marginLeft: '20%',
  rowGap: '10px',
});

// const ImageStyled = styled(`img`)(({ theme }) => ({
//   width: '180px',
//   objectFit: 'cover',
//   height: '100px',
//   marginLeft: '20%',
//   filter: theme.imageFilter?.darkFilter,
// }));

const ChristmasImage = styled(`img`)({
  width: '120px',
  marginLeft: '20%',
});

const HeaderWrapper = styled(`div`)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 100,
  backgroundColor: theme.palette.background.default,
  width: '125%',
  marginBottom: '40px',
  marginLeft: '-25%',
}));

export const FiltersWrapper = ({
  children,
  filterCount,
  onClick,
  hideResetAllButton = false,
  extraButtons,
}: PropsWithChildren<FiltersWrapperProps>) => {
  const JPcarsLogo = () => (
    <Box
      style={{
        marginBottom: '20px',
        width: 'fit-content',
        height: 'fit-content',
      }}
    >
      <a href="/">
        <ChristmasImage
          src={localStorage.getItem(LocalStorageKeys.THEME) === ThemeMode.LIGHT ? santaLogoGreen : santaLogoBlack}
        />
        {/* <ImageStyled src={logo} alt="" /> */}
      </a>
    </Box>
  );

  return (
    <>
      <HeaderWrapper>
        <JPcarsLogo />
        <ButtonsWrapper>
          {!hideResetAllButton && <FilterResetButton filterCount={filterCount} onClick={onClick} />}
          {extraButtons}
        </ButtonsWrapper>
      </HeaderWrapper>
      <Box display={'flex'} flexDirection={'column'}>
        {children}
      </Box>
    </>
  );
};
