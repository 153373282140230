import React, { PropsWithChildren } from 'react';
import { ProgressIndicator } from './progress-indicator';
import { styled } from '@mui/material';

export interface LoadingWrapperProps {
  isLoading: boolean;
}

const Wrapper = styled(`div`)({ position: 'relative', width: '100%' });
export const LoadingWrapper = ({ isLoading, children }: PropsWithChildren<LoadingWrapperProps>) => (
  <Wrapper>
    {children}
    {isLoading && <ProgressIndicator />}
  </Wrapper>
);
