import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useTranslation } from 'react-i18next';
import { getPlatesByCarType } from '@japieglobal/shared/src/api/services';
import { RdwPageContainer } from './rdw-page-container';
import { ColoredButton, TextInput } from '@japieglobal/shared/src';
import { styled } from '@mui/material';

// TODO use theme colors
const StyledDiv = styled(`div`)({
  ':nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  ':nth-of-type(even)': {
    backgroundColor: '#D8F7DD',
  },
});

export const RdwGetPlatesByCarType = () => {
  const [carTypeId, setCarTypeId] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<string[] | undefined>(undefined);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    getPlatesByCarType(carTypeId!).then(setResponse).catch(snackbarErrorHandler);
  };

  return (
    <RdwPageContainer onSubmit={onSubmit}>
      <b>Get plates</b>
      <TextInput label={t('CAR_TYPE_ID')} value={carTypeId} setValue={setCarTypeId} required />
      {response?.length}
      {response?.map((k) => <StyledDiv>{k}</StyledDiv>)}

      <ColoredButton type="submit">get</ColoredButton>
    </RdwPageContainer>
  );
};
