import { MenuButton } from '../../menu-button';
import React from 'react';
import { Settings } from '@mui/icons-material';
import { TopRowMenuButtonProps } from '../../top-row';
import { User, UserRoles } from '../../../../api/services';

export const hasRequiredSettingsPermissions = (user?: User) => {
  return (
    user?.role === UserRoles.ADMIN ||
    user?.role === UserRoles.SUPER_ADMIN ||
    user?.permissions.includes('protocol') ||
    user?.permissions.includes('purchase') ||
    user?.permissions.includes('apr')
  );
};

export const SettingsButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton hoverTitle={'SETTINGS'} display={hasRequiredSettingsPermissions(user)} internal={internal} link="/admin">
    <Settings />
  </MenuButton>
);
