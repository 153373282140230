import { instance } from './instance';

export interface ClusterLocation {
  created: string;
  dealer_name: string;
  id: number;
  location_name: string;
  modified: string;
  name: string;
}

export const getClusterLocations = async (): Promise<ClusterLocation[]> =>
  instance.get(`/api/cluster-locations`).then((res) => res.data);

export const deleteClusterLocation = async (id: number): Promise<ClusterLocation[]> =>
  instance.delete(`/api/cluster-location?id=${id}`).then((res) => res.data);

export const editClusterLocation = async (id: number, location: string, cluster: string): Promise<ClusterLocation[]> =>
  instance.put(`/api/cluster-location?id=${id}`, { location_name: location, name: cluster }).then((res) => res.data);

export const createClusterLocation = async (location: string, cluster: string): Promise<ClusterLocation[]> =>
  instance.post(`/api/cluster-location`, { location_name: location, name: cluster }).then((res) => res.data);

export const getClusters = async (allowed_only = false): Promise<string[]> =>
  instance.get(`/report/clusters`, { params: { allowed_only } }).then((res) => res.data);

export const getDealerLocations = async (name?: string, used_only = true): Promise<string[]> =>
  instance.get(`/api/locations`, { params: { dealer_name: name, used_only } }).then((res) => res.data.results);
