import React from 'react';
import { styled } from '@mui/material';
import { CarTableCell, ColoredButtonLargeText } from '../result-table-styles';

const PlusButton = styled(ColoredButtonLargeText)({
  minWidth: '5px',
  height: '20px',
  width: '20px',
});

const ColorSpan = styled('span')({
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: '5px',
  height: '5px',
  backgroundColor: 'black',
});

export const ResultPlusButtonCell = React.memo(function ResultPlusButtonCell({
  setCompetitorCar,
  disabled,
  includesBanned,
}: {
  setCompetitorCar: () => void;
  disabled: boolean;
  includesBanned?: boolean;
}) {
  return (
    <CarTableCell>
      <PlusButton onClick={setCompetitorCar} disabled={disabled} className="plusButton">
        +{includesBanned ? <ColorSpan /> : undefined}
      </PlusButton>
    </CarTableCell>
  );
});
