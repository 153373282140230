import React, { useMemo, useState } from 'react';
import { FieldWrapper, FormRow } from '../shared/styled';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ProgressIndicator, ColoredButton } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { PasswordConfirmation } from '../../components/auth/password-confirmation';
import { deleteSuperAdminPassword, setSuperAdminPassword } from '@japieglobal/shared/src/api/services';
import { User } from '@japieglobal/shared/src/api/services';

interface AdminUserSuperPasswordProps {
  editUser: Partial<User>;
  setEditUser: <K extends keyof Partial<User>>(value: Partial<User>[K], key: K) => void;
}
export const AdminUserSuperPassword = ({ editUser, setEditUser }: AdminUserSuperPasswordProps) => {
  const [password, setPassword] = useState(editUser.is_super_admin_password_set ? '********' : '');
  const [confirmPassword, setConfirmPassword] = useState(editUser.is_super_admin_password_set ? '********' : '');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { t } = useTranslation();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userId = useMemo(() => editUser.id!, [editUser.id]);

  const savePassword = (event) => {
    event.preventDefault();
    if (isPasswordValid) {
      setIsLoading(true);
      setSuperAdminPassword({ userId, super_admin_password: password })
        .then(() => setEditUser(true, 'is_super_admin_password_set'))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  const removePassword = (event) => {
    event.preventDefault();
    setIsLoading(true);
    deleteSuperAdminPassword({ userId })
      .then(() => {
        setEditUser(false, 'is_super_admin_password_set');
        setPassword('');
        setConfirmPassword('');
      })
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  };

  return (
    <div style={{ width: '100%', marginTop: '20px', paddingTop: '20px', borderTop: '1px solid gray' }}>
      <div style={{ marginBottom: '18px' }}>
        <strong>{t('SUPER_ADMIN_PASSWORD')}</strong>
      </div>
      <div style={{ border: '1px solid rgb(225, 225, 225)', padding: '10px', position: 'relative' }}>
        <FormRow>
          <FieldWrapper>
            <PasswordConfirmation
              password={password}
              setPassword={setPassword}
              passwordAgain={confirmPassword}
              setPasswordAgain={setConfirmPassword}
              setIsPasswordValid={setIsPasswordValid}
            />
          </FieldWrapper>
        </FormRow>
        <FormRow>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', columnGap: 10 }}>
            {editUser.is_super_admin_password_set && (
              <ColoredButton style={{ backgroundColor: 'rgb(255, 87, 34)' }} onClick={removePassword}>
                {t('DELETE_PASSWORD')}
              </ColoredButton>
            )}
            <ColoredButton disabled={!isPasswordValid} onClick={savePassword}>
              {t('SAVE_PASSWORD')}
            </ColoredButton>
          </div>
        </FormRow>
        {isLoading && <ProgressIndicator />}
      </div>
    </div>
  );
};
