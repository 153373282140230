import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplyType } from '../../../shared/src/types';
import { AppSearchParamKeys, MultiSelectFilter, SelectOption } from '../../../shared/src/components';
import { UserContext } from '@japieglobal/shared/src/user-context';

export const FilterSupplyType = () => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const options: SelectOption[] = [
    { value: SupplyType.DEMO, label: t('DEMO') },
    { value: SupplyType.NOT_VALUED, label: t('NOT_VALUED') },
    { value: SupplyType.FINANCIAL, label: t('FINANCIAL') },
    { value: SupplyType.NEW, label: t('NEW') },
  ];
  if (!user.permissions.includes('supply_type')) return null;

  return (
    <span className="stockType">
      <MultiSelectFilter
        searchParamKey={AppSearchParamKeys.SUPPLY_TYPES}
        limitTags={5}
        staticOptions={options}
        labelTranslationKey="STOCK_TYPE"
      />
    </span>
  );
};
