import React from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { CsmReportKeys } from '@japieglobal/shared/src/api/services';

const TableCellKey = ({ value }: { value: string }) => (
  <TableCell size="small" sx={{ border: 1 }}>
    {value}
  </TableCell>
);
const TableCellValue = ({ value, backgroundColor }: { value: number; backgroundColor: string }) => (
  <TableCell size="small" align="center" sx={{ backgroundColor }}>
    {value}
  </TableCell>
);

interface HoverTableProps {
  hoverValues: { [key: string]: number | string };
}
export const ScoreTable = ({ hoverValues }: HoverTableProps) => {
  const GetBackgroundColor = (value: number) => {
    if (value < 0) return 'green';
    else if (value === 0) return 'orange';
    else return 'red';
  };

  return (
    <Table sx={{ '& .MuiTableRow-root:last-child td': { fontWeight: 900, borderTop: '2px solid #000' } }}>
      <TableBody>
        {Object.entries(hoverValues).map(
          ([key, value]: [string, number | string]) =>
            key !== CsmReportKeys.VALUE && (
              <TableRow key={key}>
                <TableCellKey value={key} />
                <TableCellValue
                  value={value as number}
                  backgroundColor={
                    key === CsmReportKeys.SCORE_TOTAL
                      ? (hoverValues.value as string)
                      : GetBackgroundColor(value as number)
                  }
                />
              </TableRow>
            ),
        )}
      </TableBody>
    </Table>
  );
};
