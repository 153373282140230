import { Link } from 'react-router-dom';
import React, { PropsWithChildren, useCallback } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { Box } from '@mui/material';
import { AuthLanguageSelect } from './auth-language-select';

const useStyles = makeStyles({
  alertError: {
    backgroundColor: '#f2dede',
    borderColor: '#ebccd1',
    color: '#a94442',
  },
  infoMessage: {
    backgroundColor: '#fcf8e3',
    borderColor: '#faebcc',
    color: '#8a6d3b',
  },
});

const LoginContainer = styled(Box)({
  'maxWidth': '480px',
  'width': '100%',
  'padding': 10,
  'margin': '100px auto 0 auto',
  'display': 'flex',
  'flex-direction': 'column',
  'align-items': 'center',
  'justify-content': 'center',
});

const LoginForm = styled('form')({
  padding: '40px 30px',
  marginTop: `20px`,
  backgroundColor: `white`,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Logo = styled('img')({
  maxWidth: 250,
});

const Copyright = styled(Box)({ marginTop: '10px', fontSize: '13px', color: '#A9A9A9', textAlign: 'center' });
const CopyrightLink = styled('a')({ fontSize: '13px', color: '#A9A9A9', textDecoration: 'none' });

const Alert = styled(`div`)({
  padding: '10px 15px',
  marginBottom: '20px',
  border: '1px solid transparent',
  borderRadius: '4px',
  fontFamily: 'sans-serif',
  fontSize: '14px',
  lineHeight: '17px',
  textAlign: 'left',
});
interface AuthStyleContainerProps {
  alertMessage?: string;
  infoMessage?: string;
}

export const AuthStyleContainer = ({
  children,
  alertMessage,
  infoMessage,
}: PropsWithChildren<AuthStyleContainerProps>) => {
  const classes = useStyles();

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
  }, []);

  return (
    <LoginContainer>
      <Link to="/">
        <Logo src="/logo_text.png" alt="JP.cars App" />
      </Link>
      <AuthLanguageSelect />

      <LoginForm onSubmit={handleSubmit}>
        {alertMessage ? <Alert className={classes.alertError}>{alertMessage}</Alert> : undefined}
        {infoMessage ? <Alert className={classes.infoMessage}>{infoMessage}</Alert> : undefined}
        {children}
      </LoginForm>
      <Copyright>
        ©{new Date().getFullYear()} JPcars - <CopyrightLink href="mailto:contact@jp.cars">Contact</CopyrightLink>
      </Copyright>
    </LoginContainer>
  );
};
