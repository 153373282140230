import React, { useCallback, useEffect, useMemo } from 'react';
import { BooleanAppSearchParam, AppSearchParamKey } from '../../types';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { ToggleInput } from '../../../inputs';
import { useAppSearchParams } from '../../hooks';
import { BaseInputFilterProps } from '../../types/base-filter-props';

// When disableTripleState is disabled, then the generic value can only be true | undefined
// Instead of boolean | undefined. And thus the default value will be the same.
export interface ToggleFilterProps extends BaseInputFilterProps<boolean | undefined, BooleanAppSearchParam> {
  icon?: string | JSX.Element;
  fullWidth?: boolean;
  disableTripleState?: boolean;
  dependencies?: BooleanAppSearchParam[];
  className?: string;
}

export const ToggleFilter = ({
  className,
  searchParamKey,
  labelTranslationKey,
  defaultValue,
  hideResetButton,
  disableTripleState,
  dependencies,
  ...rest
}: ToggleFilterProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();
  const toggleState = searchParams[searchParamKey];

  const [filterDependenciesObject] = useAppSearchParams(dependencies || []);
  const filterDependenciesMemo = useMemo(() => filterDependenciesObject, [filterDependenciesObject]);

  const hasFilledDependency = useCallback(() => {
    let isFilled = false;
    for (const property in filterDependenciesMemo) {
      if (filterDependenciesMemo[property as AppSearchParamKey]) {
        isFilled = true;
      }
    }
    return isFilled;
  }, [filterDependenciesMemo]);

  const onHandleReset = useCallback(() => {
    searchParams[searchParamKey] = defaultValue;
    setSearchParams(searchParams);
  }, [defaultValue, searchParamKey, searchParams, setSearchParams]);

  const onHandleChange = (value: boolean | undefined) => {
    if (dependencies && dependencies?.length > 0 && !hasFilledDependency()) {
      dependencies.forEach((key) => {
        searchParams[key] = value;
      });
    }
    searchParams[searchParamKey] = value;
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (toggleState == null && defaultValue !== undefined) {
      searchParams[searchParamKey] = defaultValue;
      setSearchParams(searchParams);
    }
    // TODO: Find a solution having to put setSearchParams inside here.
    // This now only works for the first render. But reset all filters does not work yet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, searchParamKey]);

  useEffect(() => {
    if (dependencies && dependencies?.length > 0 && !hasFilledDependency() && toggleState != null) {
      onHandleReset();
    }
  }, [dependencies, hasFilledDependency, onHandleReset, toggleState]);

  return (
    <InputWrapper hideResetButton={disableTripleState ? true : hideResetButton} onReset={onHandleReset}>
      <ToggleInput
        toggleState={toggleState}
        setToggleState={onHandleChange}
        label={t(labelTranslationKey)}
        disableTripleState={disableTripleState}
        className={className}
        {...rest}
      />
    </InputWrapper>
  );
};
