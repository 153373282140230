import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { formatDateAsDate } from '@japieglobal/shared/src/utils';
import { Tooltip } from '@japieglobal/shared/src';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { OptionIcon, optionIcons } from '../../car-window/car-window-icons';
import { formatAndShortenOption } from '../../car-window/cells/car-window-options-cell/car-window-option';
import { ClickState } from '@japieglobal/shared/src/types/click-state';

const CarTableCellStyled = styled(CarTableCell)({});
const PositionWrapper = styled(`div`)({ width: '125px', display: 'flex', flexDirection: 'column' });
const PositionTop = styled(`div`)({ flex: '2' });
const SoldDate = styled(`div`)({ fontSize: '11px', display: 'flex', justifyContent: 'center' });
const FlagStyled = styled(FlagIcon)({ height: '15px' });

function getSoldDate(car: CarResult) {
  if (car.sold?.since && car.sold?.since > 0) {
    const d = new Date();
    d.setDate(d.getDate() - car.sold?.since);
    return formatDateAsDate(d);
  }
  return undefined;
}

export const ResultBrandModel = React.memo(({ car, windowView }: { car: CarResult; windowView?: boolean }) => {
  const { t } = useTranslation();
  const soldDate = getSoldDate(car);

  return (
    <CarTableCellStyled>
      <BoxFlexColumn>
        <PositionWrapper>
          <PositionTop>
            <div>{car.make}</div>
            <div>{car.model}</div>
          </PositionTop>
          {soldDate && (
            <SoldDate>
              <FlagStyled /> {soldDate}
            </SoldDate>
          )}
        </PositionWrapper>
        {windowView && car?.standard_options?.length ? (
          <Box display="flex" gap={0.5}>
            {car.standard_options?.map((option) => {
              const missing = !car.extracted_options?.includes(option);
              return (
                <Tooltip onHoverContent={t(option.toUpperCase(), option)} key={option}>
                  {optionIcons[option] ? (
                    <OptionIcon
                      key={option}
                      active={JSON.parse(car.click?.options || '{}')[option] == ClickState.LOCK}
                      missing={missing}
                      enabled={true}
                      option={option}
                      windowView={true}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: 'inherit',
                        color: missing ? 'warning.main' : 'primary.main',
                      }}
                    >
                      {formatAndShortenOption(t(option.toUpperCase()))}
                    </Box>
                  )}
                </Tooltip>
              );
            })}
          </Box>
        ) : (
          <></>
        )}
      </BoxFlexColumn>
    </CarTableCellStyled>
  );
});
