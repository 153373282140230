import { BaseTextFieldProps, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export interface BaseTextInputProps<T> {
  value?: T;
  defaultValue?: T;
  setValue: (value: T) => void;
  width?: 'fill' | number;
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  tabOnEnter?: boolean;
  multiline?: boolean;
}

export const tabToNextElement = (element: HTMLInputElement) => {
  const universe = document.querySelectorAll('input');
  const list = Array.prototype.filter.call(universe, (item) => item.tabIndex >= '0');
  const index = list.indexOf(element);
  const nextEl = list[index + 1] || list[0];
  nextEl.focus();
};

export const BaseTextInput = <T,>({
  value,
  setValue,
  width,
  autoFocus = false,
  onChange,
  label,
  disabled = false,
  startAdornment,
  endAdornment,
  tabOnEnter = false,
  multiline = false,
  ...params
}: Omit<BaseTextInputProps<T>, 'defaultValue'> &
  BaseTextFieldProps &
  Omit<TextFieldProps, 'variant' | 'onChange'> & { onChange: (value: T) => void }) => {
  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => event.target.select();

  const handleOnKeyUp = ({ key, target }: React.KeyboardEvent) => {
    if (key === 'Enter') {
      (target as HTMLInputElement).blur();
      if (tabOnEnter) {
        tabToNextElement(target as HTMLInputElement);
      }
    }
  };

  return (
    <TextField
      value={value ?? ''}
      onChange={({ target }) => onChange(target.value as T)}
      onKeyUp={handleOnKeyUp}
      onFocus={handleOnFocus}
      onBlur={({ target }) => setValue(target.value as T)}
      fullWidth={typeof width !== 'number'}
      size="small"
      margin="none"
      style={{ width: typeof width === 'number' ? width : undefined }}
      autoFocus={autoFocus}
      label={label}
      disabled={disabled}
      InputProps={{
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
        endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
      }}
      multiline={multiline}
      {...params}
    />
  );
};
