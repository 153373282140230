import axios from 'axios';
import qs from 'qs';
import { NetworkError } from '../network-error';
import { getProtocolAndDomain } from '../utils/getProtocolAndDomain';

export const instance = axios.create({
  withCredentials: true,
  baseURL: getProtocolAndDomain(),
  paramsSerializer: {
    serialize: (p) => qs.stringify(p, { arrayFormat: 'comma' }),
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    throw new NetworkError(error);
  },
);

instance.defaults.headers.common = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};

export const setInstanceBaseUrl = (url: string) => {
  instance.defaults.baseURL = url;
};
