import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
  ClicksConfigKeys,
  ClicksConfig,
  createClicksConfig,
  getClicksConfig,
} from '@japieglobal/shared/src/api/services';
import { ColoredButton } from '@japieglobal/shared';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { ClicksItem } from './clicks-config-item';
import { DealersSelect } from '@japieglobal/shared/src/components/dealers-select/dealers-select';
import { UserContext } from '@japieglobal/shared/src/user-context';

const config = {
  [ClicksConfigKeys.DEALER_NAME]: '',
  [ClicksConfigKeys.REGEX]: '(.+)',
  [ClicksConfigKeys.CREDENTIALS]: {},
  [ClicksConfigKeys.PROPERTY_ID]: 0,
  [ClicksConfigKeys.CLICKS_THRESHOLD]: null,
  [ClicksConfigKeys.ID]: 0,
};

export const ClicksConfigCreate = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [clicksConfig, setClicksConfig] = useState<ClicksConfig>(config);
  const [propertyIds, setPropertyIds] = useState<number[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (clicksConfig[ClicksConfigKeys.DEALER_NAME]) {
      getClicksConfig(clicksConfig[ClicksConfigKeys.DEALER_NAME])
        .then((configs) => {
          setPropertyIds(configs.map((config) => config[ClicksConfigKeys.PROPERTY_ID]));
        })
        .catch(() => setPropertyIds([]));
    }
  }, [clicksConfig]);

  const createConfig = () => {
    if (
      !clicksConfig[ClicksConfigKeys.DEALER_NAME] ||
      !clicksConfig[ClicksConfigKeys.REGEX] ||
      !clicksConfig[ClicksConfigKeys.PROPERTY_ID] ||
      Object.keys(clicksConfig[ClicksConfigKeys.CREDENTIALS]).length === 0
    ) {
      snackbarErrorMessage(t('CHECK_ALL_FIELDS'));
    } else if (propertyIds.includes(Number(clicksConfig[ClicksConfigKeys.PROPERTY_ID]))) {
      snackbarErrorMessage(t('PROPERTY_ID ALREADY EXISTS'));
    } else {
      createClicksConfig(clicksConfig)
        .then(() => {
          snackbarSuccessMessage(t('CLICKS_CONFIG_SUCCESS'));
          navigate(
            `/super-admin/clicks_config/edit/${clicksConfig[ClicksConfigKeys.DEALER_NAME]}/${clicksConfig[ClicksConfigKeys.PROPERTY_ID]}`,
          );
        })
        .catch(() => snackbarErrorMessage(t('SOMETHING_WRENT_WRONG')));
    }
  };

  const onConfigChange = <K extends ClicksConfigKeys>(value: ClicksConfig[K], key: K) => {
    if (clicksConfig) {
      setClicksConfig({ ...clicksConfig, [key]: value });
    }
  };

  return (
    <SuperAdminPageContainer>
      <Box p={2}>
        <h4>Create clicks config</h4>
        <DealersSelect
          onSelect={(newDealer) => onConfigChange(newDealer, ClicksConfigKeys.DEALER_NAME)}
          user={user}
          selectedDealer={clicksConfig[ClicksConfigKeys.DEALER_NAME]}
        />
        <ClicksItem clicksKey={ClicksConfigKeys.REGEX} clicksConfig={clicksConfig} onConfigChange={onConfigChange} />
        <ClicksItem
          clicksKey={ClicksConfigKeys.CREDENTIALS}
          value={JSON.stringify(clicksConfig[ClicksConfigKeys.CREDENTIALS])}
          multiline
          clicksConfig={clicksConfig}
          onConfigChange={onConfigChange}
        />
        <ClicksItem
          clicksKey={ClicksConfigKeys.PROPERTY_ID}
          clicksConfig={clicksConfig}
          onConfigChange={onConfigChange}
        />
        <ClicksItem
          clicksKey={ClicksConfigKeys.CLICKS_THRESHOLD}
          required={false}
          clicksConfig={clicksConfig}
          onConfigChange={onConfigChange}
        />
        <ColoredButton onClick={createConfig}>Create</ColoredButton>
      </Box>
    </SuperAdminPageContainer>
  );
};
