import { styled } from '@mui/system';
import React from 'react';
const P1 = styled(`p`)({
  margin: '0px 0px 0px 0px',
  font: '10px Times',
  color: '#333333',
});
const P2 = styled(`p`)({
  margin: '0px 0px 0px 0px',
  font: '10px Times',
  color: '#333333',
  minHeight: '13px',
});
const P3 = styled(`p`)({
  margin: '0px 0px 0px 35.2px',
  font: '10px Times',
  color: '#333333',
});
const P4 = styled(`p`)({
  margin: '0px 0px 0px 40.8px',
  font: '10px Times',
  color: '#333333',
});
const Li1 = styled(`li`)({
  margin: '0px 0px 0px 0px',
  font: '10px Times',
  color: '#333333',
});
const S1 = styled(`span`)({
  font: '12px Times',
  color: '#000000',
});
const Ul1 = styled(`ul`)({
  listStyleType: 'disc',
});

export const Jpcglc = () => (
  <>
    <P1>
      <b>General Licence Conditions</b>
    </P1>
    <P1>
      <b> </b>
    </P1>
    <P1>
      <b>
        BY SIGNING A 'LICENSING AGREEMENT' ON BEHALF OF THE 'CLIENT', WHICH REFERS TO THESE 'GENERAL LICENCE
        CONDITIONS', INCLUDING ALL DOCUMENTS AND REFERENCES MADE IN THE LICENSING AGREEMENT AND/OR THIS DOCUMENT, THE
        SIGNATORY ON BEHALF OF THE CLIENT HAS TAKEN COGNISANCE OF THE CONTENTS OF THE LICENSING AGREEMENT AND THE
        GENERAL LICENCE CONDITIONS BETWEEN THE CLIENT AND JAPIECARS NETHERLANDS B.V. ('THE SUPPLIER'), THE CLIENT AND
        SUPPLIER, HEREINAFTER REFERRED TO JOINTLY AS THE 'PARTIES'. BY SIGNING THE SIGNATORY INDICATES THAT IT HAS THE
        AUTHORITY TO BIND THE CLIENT TO THE LICENSING AGREEMENT AND THE GENERAL LICENCE CONDITIONS AND, BY SIGNING, THE
        CLIENT IS BOUND TO THE LICENSING AGREEMENT AND GENERAL LICENCE CONDITIONS.
      </b>
    </P1>
    <P1>
      <b> </b>
    </P1>
    <P1>
      <b>Privacy statement</b>
    </P1>
    <P1>
      When you register or use our software and/or services, you provide us with personal data. Given that your privacy
      is extremely important to us, we do all we can to inform you of any processing of personal data. Please also read
      our Privacy Statement carefully: <S1>https://nl.jp.cars/jpcps</S1>. If you do not agree to the processing of your
      personal data as explained in our Privacy Statement, we kindly request that you do not use our software and/or
      services.
    </P1>
    <P2>
      <br />
    </P2>
    <P1>
      <b>Article 1 Definitions</b>
    </P1>
    <P1>The following definitions apply to the General Licence Conditions:</P1>
    <Ul1>
      <Li1>
        <b>Licensing Agreement(s):</b> The agreement signed and executed by both Parties, referred to as the 'Licensing
        Commission' and/or the 'Licensing Agreement'.
      </Li1>
      <Li1>
        <b>Application(s)</b>: The Licence Modules made available by means of the Cloud Service, as specified in the
        Licensing Agreement, including the underlying databases and development platforms with the corresponding data
        collection(s).
      </Li1>
      <Li1>
        <b>Availability</b>: The period during which the Client actually has the Application at its disposal by means of
        the Cloud Service.
      </Li1>
      <Li1>
        <b>Cloud Service</b>: The making and keeping available, by the Supplier in return for payment, of the
        Application(s) and/or Infrastructure and/or Platform Facilities on a Server on the Client's behalf.
      </Li1>
      <Li1>
        <b>User</b>: A natural person who is authorised by the Client to use (an) available Application(s).
      </Li1>
      <Li1>
        <b>Tools</b>: The resources which have to be installed by the User on its computer in order to be able to use
        the Application.
      </Li1>
      <Li1>
        <b>Server</b>: A computer, or a combined group of computers and related hardware ('cloud'), managed by or on
        behalf of the Supplier, containing web server equipment, the Application(s), supportive software and/or database
        software, which can be accessed by means of the Internet.
      </Li1>
      <Li1>
        <b>Means of Access</b>: The resources, such as a token or combination of access code and a username, which can
        be used to access the Server and the Application(s).
      </Li1>
      <Li1>
        <b>Agreement</b>: These 'General Licence Conditions' with all related Licensing Agreement(s). In the event of a
        contradiction between the provisions of the General Licence Conditions and the Licensing Agreement(s) the
        provisions of the Licensing Agreement(s) will take precedence.
      </Li1>
      <Li1>
        <b>Personal Data</b>: Any data concerning a directly or indirectly identified or identifiable natural person,
        such as the User or employees of the Client that is processed when using the Cloud Service.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 2 Commission</b>
    </P1>
    <Ul1>
      <Li1>The Client commissions the Supplier to supply the Cloud Services. These Cloud Services consist of:</Li1>
      <Ul1>
        <Li1>The making available to the Client of the Application(s) referred to in the Licensing Agreement.</Li1>
        <Li1>
          The provision of the right to the Client to use the Application in accordance with the provisions of these
          General Licence Conditions.
        </Li1>
        <Li1>The provision of technical support to the Users.</Li1>
      </Ul1>
      <Li1>The functionality of the current version of the Application as described in the Licensing Agreement.</Li1>
      <Li1>
        The Supplier is not obliged to have a backup centre or other backup facilities for the execution of the Cloud
        Services.
      </Li1>
      <Li1>
        The Supplier is not obliged to make back-ups of data stored by the Client when using the Cloud Services.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 3 Availability and modifications</b>
    </P1>
    <Ul1>
      <Li1>
        The Supplier will make every effort to ensure that the agreed Cloud Service always functions properly and will
        aim to achieve the highest possible level of availability, quality and security of the Cloud Service. However,
        the Supplier does not provide any guarantee that the Cloud Service will function without errors, malfunctions or
        interruptions.
      </Li1>
      <Li1>
        The Supplier reserves the right to change the technical and functional properties of the Cloud Service in
        between times in order to improve functionality and to rectify any errors, or to comply with applicable
        legislation and regulations.
      </Li1>
      <Li1>
        The Supplier will regularly endeavour to detect and rectify any errors in the Cloud Service. However, the
        Supplier cannot guarantee that all errors will be rectified. The Client must report errors in the Application(s)
        to the Supplier as soon as possible.
      </Li1>
      <Li1>
        If a modification referred to in paragraphs 2 and 3 leads to a material deviation in the functionality of the
        Cloud Service, the Supplier will inform the Client accordingly in writing or electronically before the
        modification becomes available.
      </Li1>
      <Li1>
        The Supplier reserves the right to shut down the Cloud Service temporarily for the purpose of, among other
        things, maintenance, modification or improvement of the Supplier’s computer systems. The Supplier will ensure
        that such a shutdown takes place outside office hours wherever possible and will forewarn the Client accordingly
        in good time. An announced shutdown of the Cloud Service like this should not, in any event, be regarded as an
        attributable failure on the part of the Supplier in the fulfilment of its commitments vis-à-vis the Client. The
        Supplier will always endeavour to ensure that the shutdown does not last any longer than necessary.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 4 Tools for Application(s)</b>
    </P1>
    <Ul1>
      <Li1>
        The Client is responsible for the availability and functioning of the Tools required for accessing and using the
        Cloud Services, including the (peripheral) equipment and software, ancillary software, configuration and
        Internet connection used by the Client which comply with the minimal technical and functional specifications for
        the Application(s) referred to by the Supplier.
      </Li1>
      <Li1>
        The Client is responsible for maintaining a connection to the power network and other connections necessary for
        accessing and using the Cloud Service.
      </Li1>
      <Li1>
        The use of the ancillary software may be subject to additional (licence) conditions (of external third parties).
        The Supplier does not guarantee the functionality of the third-party ancillary software used by the Client.
      </Li1>
    </Ul1>
    <P2>
      <br />
    </P2>
    <P1>
      <b>Article 5 Access to the Cloud Service</b>
    </P1>
    <Ul1>
      <Li1>
        The Client is responsible for any use, with or without its permission, of the Cloud Service and of the Means of
        Access made available to it. The Supplier is not liable for loss or damage suffered by the Client and/or third
        parties which has arisen due to unauthorised use of the Means of Access.
      </Li1>
      <Li1>
        The Means of Access provided are not transferable, are strictly personal and are exclusively for personal use
        within the Client's organisation. The Client will observe due care and attention when using the Means of Access
        and keep them secret from third parties.
      </Li1>
      <Li1>
        The Supplier can always change the Means of Access as it sees fit and inform the Client accordingly in good
        time.
      </Li1>
      <Li1>
        The Client will inform the Supplier immediately if the Means of Access are used in an unauthorised way, or if
        the Client reasonably has a suspicion to that effect.
      </Li1>
      <Li1>
        The Client can ask the Supplier to block the Means of Access. The Supplier is also entitled at all times to
        block Means of Access on its own initiative if the Supplier is aware of any unauthorised use of the Means of
        Access. In that case the Supplier is not liable for direct or indirect loss or damage suffered by the Client
        and/or third parties which has arisen due to the blocking of the Means of Access.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 6 Use of the Cloud Service</b>
    </P1>
    <Ul1>
      <Li1>
        With regard to using the Cloud Service the Client guarantees, in any event, that it and the User(s), insofar as
        relevant, will observe the following rules:
      </Li1>
    </Ul1>
    <Ul1>
      <Ul1>
        <Li1>
          The Client will ensure the protection of its (peripheral) equipment, software, infrastructure and Internet
          connection against viruses, computer crime and (other) unlawful use by User(s) of third parties.
        </Li1>
        <Li1>
          When using the Cloud Service the Client and/or User will not spread any (computer) viruses or other files
          which might damage the (proper functioning of the) Cloud Service.
        </Li1>
        <Li1>
          The Client and/or User will not perform any actions (nor allow any actions to be performed) which might cause
          malfunctions in the Cloud Service, (computer) networks or infrastructures (of other users), or with regard to
          that may cause inconvenience, restricted use or unforeseen use (for other users).
        </Li1>
        <Li1>
          The Client and/or User will not send out large quantities of unsolicited messages with the same or similar
          content ('spam').
        </Li1>
        <Li1>
          The Client and/or User will not misuse Means of Access, nor violate, or try to violate, the Cloud Service
          security.
        </Li1>
        <Li1>
          The Client and/or User will not engage in any actions or omissions with regard to which they know, or
          reasonably should have known, could lead to the Cloud Service being used in a way which is criminal or
          unlawful vis-à-vis the Supplier and/or third parties.
        </Li1>
        <Li1>
          The Client and/or User will not access a computer system, or a part thereof, deliberately and without
          permission and against the will of the owner or administrator ('hack').
        </Li1>
        <Li1>
          The Client and/or User will not, in any way, violate the intellectual property rights of the Supplier and/or
          third parties.
        </Li1>
        <Li1>
          Without the Supplier's prior explicit written permission the Client and/or User will not publish, copy or
          otherwise use information and data provided by the Supplier within the framework of the Cloud Service, other
          than for use in the Client's internal business operations.
        </Li1>
      </Ul1>
      <Li1>
        If the Client and/or User(s) act contrary to one or more of the aforementioned rules, the Client will be obliged
        to comply with reasonable instructions given by the Supplier in that context and to ensure that these
        instructions are complied with by the User(s).
      </Li1>
      <Li1>
        If data which is saved, edited, processed or otherwise inputted using the Cloud Service is unlawful or criminal
        vis-à-vis third parties, the Supplier will be entitled, without any prior notification, to remove this data
        directly from the Server and to destroy it. The Client hereby grants the Supplier permission to delete and
        destroy any data which violates the rules from the Server. The Supplier will not, in any event, be liable for
        any loss or damage which results from these actions.
      </Li1>
      <Li1>
        The Supplier can prevent Access to the Cloud Services by shutting down Means of Access, or by suspending the
        service, if it has a serious suspicion that the services are being used contrary to the provisions of the
        Licensing Agreement and/or these General Licence Conditions. The payment obligation will continue to exist
        during a period of shutdown as referred to above.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 7 Third-party applications</b>
    </P1>
    <Ul1>
      <Li1>
        If and insofar as the Supplier makes third-party applications or other software available to the Client when
        providing the Cloud Service, the conditions of those third parties will apply with regard to those applications
        and/or other software to the exclusion of the provisions which have been agreed by the Supplier and the Client.
        The Client hereby accepts the third-party conditions referred to, provided the Supplier has communicated the
        applicability of the conditions in question to the Client in writing or electronically.
      </Li1>
      <Li1>
        If and insofar as the third-party conditions referred to are, for whatever reason, considered not to be
        applicable, or are declared inapplicable, in the relationship between the Client and the Supplier, the
        provisions agreed by the Supplier and the Client relating to the use of software will apply.
      </Li1>
      <Li1>
        With regard to using and maintaining third-party applications the Supplier can never be called to account for
        more or anything else than applies in the relationship between the Supplier and its respective supplier of that
        application.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 8 Fee and payment</b>
    </P1>
    <Ul1>
      <Li1>
        The fees payable for the Cloud Service are included in the Licensing Agreement. The payable fees are based on
        the data and figures included in the Licensing Agreement(s). When determining the amount of the total fee per
        Client, the data and figures on or around the Commencement Date of the Licensing Agreement (up to a maximum of
        20 days thereafter), or on or around the Licensing Agreement granting date (up to a maximum of 20 days
        thereafter), for which the fee is determined, will always apply.
      </Li1>
      <Li1>
        The fees referred to in the Licensing Agreement and otherwise discussed by the parties during the term of the
        Licensing Agreement are in euros and exclude payable turnover tax and other levies imposed by the government.
      </Li1>
      <Li1>
        The Supplier is entitled to change the agreed fees periodically and this includes the right to increase the
        prices and rates once (1) per then applicable contract year. The Supplier will inform the Client at least two
        calendar months in advance in writing of any rate changes.
      </Li1>
      <Li1>
        The Supplier will invoice the agreed fee to the Client annually in advance. The underlying Licence Term for the
        Licensing Agreement(s) is included in the Licensing Agreement(s). The payment term is no later than 14 days
        after the date of the invoice. Statutory commercial interest will be payable at the end of the payment term, as
        well as compensation of the extrajudicial costs incurred by the Supplier in connection with collecting the
        amount of the invoice in question.
      </Li1>
      <Li1>
        If, on the grounds of general or exceptional facts and circumstances, there is reasonable doubt as to whether
        the Client will (be able to) fulfil its payment obligations vis-à-vis the Supplier, the Supplier will be
        entitled, before the Supplier fulfils any other of its obligations under the Licensing Agreement and/or General
        Licence Conditions, to demand that the Client provide surety, for example in the form of a guarantee, bank
        guarantee or deposit. The relevant amount will not exceed the amount that the Client will reasonably owe over a
        period of twelve calendar months, unless agreed otherwise.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 9 Suspension</b>
    </P1>
    <Ul1>
      <Li1>
        Seven days after a prior demand or notice of default the Supplier is entitled to block access to the Cloud
        Service wholly or partially for an indefinite period of time if the Client fails to fulfil any commitment on
        account of the Licensing Agreement and/or General Licence Conditions. If the Client then fulfils its commitments
        after all, the Supplier will once again provide access to the Cloud Service.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 10 Intellectual property rights</b>
    </P1>
    <Ul1>
      <Li1>
        All intellectual property rights to any Applications, other software, documentation and other materials,
        developed or made available within the framework of the Cloud Services, which are subject to any intellectual
        property right, are vested exclusively in the Supplier or its licensors.
        <span className="Apple-converted-space"> </span>
      </Li1>
      <Li1>
        The Client will only receive the non-exclusive user rights and authorities as explicitly assigned in writing in
        the Licensing Agreement, General Terms and Conditions of Delivery or otherwise during the Licence Term of the
        Licensing Agreement. The Client will not otherwise copy or publicise the Applications, other software,
        documentation and other materials developed or made available within the framework of the Cloud Services. The
        Client is not permitted to delete or change any reference concerning copyrights, brands, trade names, or other
        intellectual property rights resulting from Applications, other software, documentation and other materials
        developed or made available within the framework of the Client Services, including references concerning the
        confidential nature and confidentiality of the materials.
      </Li1>
      <Li1>
        The Supplier is permitted to take technical measures to protect the Applications, other software, documentation
        and other materials developed or made available within the framework of the Cloud Services, on the condition
        that these measures do not have a negative effect on the functionality. If the Applications, other software,
        documentation and other materials developed or made available within the framework of the Cloud Services are
        secured by means of technical protection, the Client will not be permitted to remove or circumvent this
        protection.
      </Li1>
      <Li1>
        The Client is not entitled to rectify independently any errors in the Cloud Service software (or to have them
        rectified), to make changes to them, transfer them to other equipment, link them with other equipment and
        software, expand the functionality, change parameters and/or remove protections.
      </Li1>
    </Ul1>
    <P3> </P3>
    <P1>
      <b>Article 11 Confidentiality</b>
    </P1>
    <Ul1>
      <Li1>
        The Client and the Supplier will ensure that all data received from the other Party which is known, or
        reasonably should be known, to be confidential in nature, remains secret. The Party that receives that
        confidential data will use it solely for the purpose for which it has been provided. Data will, in any event, be
        regarded as confidential if one of the Parties has indicated such.
      </Li1>
      <Li1>
        The above-mentioned obligation to observe confidentiality does not apply if the confidential information:
      </Li1>
      <Ul1>
        <Li1>
          is general knowledge, without this having been caused by a violation of the duty of confidentiality referred
          to here;
        </Li1>
        <Li1>has been developed by the other party independently, without using this information;</Li1>
        <Li1>
          has been obtained lawfully by the other party from a third party, which third party is not bound by a similar
          confidentiality obligation; or
        </Li1>
        <Li1>
          has to be made public on the grounds of legislation or regulations, a court verdict, or a judgement by a
          regulatory body.
        </Li1>
      </Ul1>
      <Li1>
        The parties undertake to use the information referred to in paragraph 1 exclusively for the performance of the
        Licensing Agreement.
      </Li1>
      <Li1>
        The parties undertake to impose the same obligations as stated above on people engaged by them in the
        performance of the Licensing Agreement and General Licence Conditions.
      </Li1>
      <Li1>
        The above-mentioned confidentiality obligations will continue to apply during the Licence Term of the Licensing
        Agreement and until two years after its termination.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 12 Data</b>
    </P1>
    <Ul1>
      <Li1>
        The Client remains the entitled party of the data it has itself saved, edited, processed or otherwise inputted
        using the Cloud Services.
      </Li1>
      <Li1>
        The Client and its Users will themselves determine which data is saved, edited, processed or otherwise inputted
        using the Cloud Services. The Supplier has no knowledge of this data. For that reason, the Client will continue
        to be individually responsible for the data it has inputted. The Supplier is consequently not liable for any
        loss or damage which results from the data inputted by the Client. The Client indemnifies the Supplier against
        third-party claims for compensation of loss or damage which these third parties might be able to recover in any
        way from the Supplier, insofar as this claim is based on the Client's use of the Cloud Service.
      </Li1>
      <Li1>
        The Supplier is not obliged to check the accuracy and completeness of the data provided and is therefore not
        liable for the consequences of the Client's use of incorrect and/or incomplete information supplied.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 13 Liability and indemnification</b>
    </P1>
    <Ul1>
      <Li1>
        The Supplier's total liability vis-à-vis the Client due to an attributable failure in the fulfilment of the
        Licensing Agreement and/or General Licence Conditions, including but not limited to:
      </Li1>
      <Ul1>
        <Li1>recommendations made to the Client generated on the basis of the Cloud Service by the Supplier;</Li1>
        <Li1>
          the performance of any actual act by the Supplier and/or Client on the basis of the recommendations generated
          by the Cloud Service;
        </Li1>
        <Li1>
          the (automatic) retrieval of specific information with login data of the Client by the Supplier on web
          portals, websites and/or software of third parties and/or the Client
        </Li1>
      </Ul1>
    </Ul1>
    <P4>
      is limited for the term of the Licensing Agreement to compensation of direct loss or damage up to a total of no
      more than the amount of licence costs per calendar year actually paid until the moment of the event which causes
      the loss or damage. This limitation of liability applies mutatis mutandis to the Supplier's indemnification
      obligations.
    </P4>
    <Ul1>
      <Li1>
        The Supplier is never liable in any way for writing and/or typing errors with regard to offers and/or price
        recommendations by the Cloud Service.
      </Li1>
      <Li1>
        The Supplier's liability for indirect loss or damage, including (but not exclusively) lost profit, missed
        savings, reduced goodwill, loss or damage due to business interruptions and loss or damage as a consequence of
        claims from the Client's customers, is excluded. Similarly excluded is the Supplier's liability due to
        corruption, destruction or loss of files, data, documents or other information carriers of the Client.
      </Li1>
      <Li1>
        The exclusions and limitations referred to with regard to the Supplier's liability do not apply in the event
        that the loss or damage was caused by intent or gross negligence on the part of the Supplier's employees or
        company management.
      </Li1>
      <Li1>
        Unless fulfilment by the Supplier is permanently impossible, the Supplier will only be liable due to an
        attributable failure to fulfil a Licensing Agreement if the Client immediately places the Supplier in default in
        writing, whereby a deadline of at least 14 days will be imposed for the rectification of the failure, and the
        attributable failure on the part of the Supplier to fulfil its obligations continues even after that 14-day
        deadline. The notice of default must include a complete and detailed description of the asserted failure, so
        that the Supplier has the opportunity to respond to the notice of default adequately and on time.
      </Li1>
      <Li1>
        A condition for the creation of any right to compensation is always that the Client reports the loss or damage
        to the Supplier as soon as possible after it has occurred and that the Client itself limits the loss or damage
        as much as possible from an objective point of view. Any claim for compensation against the Supplier will cease
        to exist simply by the passage of 12 months after the claim arose.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 14 Force majeure</b>
    </P1>
    <Ul1>
      <Li1>
        None of the Parties is obliged to fulfil any obligation, including any guarantee obligation agreed between the
        Parties, if a Party is prevented from doing so as a result of force majeure as described in Article 6:75 of the
        Dutch Civil Code. In the Licensing Agreement and General Licence Conditions force majeure is understood to mean
        force majeure on the part of the Supplier's subsuppliers, the improper fulfilment of obligations of subsuppliers
        prescribed by the Client vis-à-vis the Supplier, government measures, electricity outages, malfunctions
        affecting the Internet, the computer network or telecommunication facilities, (civil) war, occupations, strikes,
        general transport problems and terrorism.
      </Li1>
      <Li1>
        If a force majeure situation lasts longer than 90 consecutive days, each of the parties will be entitled to
        dissolve the Licensing Agreement in writing. Any deliverables already provided under the Licensing Agreement
        will be settled on a proportional basis, without the Parties having any other claims against each other.
      </Li1>
    </Ul1>
    <P2>
      <br />
    </P2>
    <P1>
      <b>Article 15 Term and termination</b>
    </P1>
    <Ul1>
      <Li1>
        The Licensing Agreement and General Licence Conditions take effect on the commencement date referred to in the
        Licensing Agreement and have a minimum term of one (1) year. On each occasion the Licensing Agreement will be
        tacitly renewed for the term of one year, unless the Client or the Supplier terminates the Licensing Agreement
        in a letter sent by registered post, with due regard for a cancellation period of at least three months before
        the end of the period in question.
      </Li1>
      <Li1>
        Each Party is authorised to dissolve the Licensing Agreement due to an attributable failure in the fulfilment of
        the Licensing Agreement and/or General Licence Conditions if the other Party attributably fails in the
        fulfilment of essential obligations under the Licensing Agreement and/or General Terms and Conditions of
        Delivery. Dissolution is only possible after a full and detailed written notice of default has been issued,
        whereby a deadline of 14 days will be set to rectify the failure. Any payment obligation on the part of the
        Client and all other obligations to cooperate by the Client or a third party to be engaged by the Client are
        always deemed to be essential obligations under the Licensing Agreement and/or General Licence Conditions.
      </Li1>
      <Li1>
        At the moment of the dissolution as referred to above, deliverables already provided with a view to executing
        the Licensing Agreement and General Licence Conditions will not be undone unless the Client proves that the
        Supplier is in default with respect to a substantial portion of those deliverables. In that case the value of
        the deliverables already provided by the Supplier for the Client (barring evidence to the contrary) will be
        determined as being the same as the value of fees already paid. Amounts which the Supplier invoiced before the
        dissolution in connection with that which the Supplier has already and properly carried out or delivered for the
        execution of the Licensing Agreement will continue to be payable, with due regard for the provisions of the
        previous sentence, and will become immediately due and payable at the moment of dissolution.
      </Li1>
      <Li1>
        In all other cases the Client will not be entitled to cancel the Licensing Agreement and/or General Licence
        Conditions prematurely.
      </Li1>
      <Li1>
        Each of the Parties can immediately cancel the Licensing Agreement and General Licence Conditions in writing, in
        full or partially, without any notice of default being required, if the other Party is granted a – provisional
        or otherwise – suspension of payments, if an application for bankruptcy is made with regard to the other Party,
        or if the other Party's business is liquidated or terminated other than for the purpose of a business
        restructuring. The Supplier is not obliged to refund any monies that have already been received, or to pay
        compensation, in connection with such a termination. In the event of bankruptcy on the part of the Client, the
        right to use the Cloud Services will immediately lapse.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 16 Consequences of termination</b>
    </P1>
    <Ul1>
      <Li1>
        In the event of the Licensing Agreement being terminated the Parties will still cooperate in good faith with any
        support desired by the Client in connection with the remigration of the data inputted when using the Cloud
        Services and in connection with the transfer to the Client or to a third party designated by the Client during
        this 'remigration period'. In this context continuity in terms of the availability of the data and services is
        paramount. The Parties will consult over the extent of the effort required by the Supplier. The Supplier will be
        able to charge the Client the costs which it incurs in connection with the remigration of the services on the
        basis of subsequent calculation. 
      </Li1>
    </Ul1>
    <P1>
      <b>Article 17 Disputes and applicable law</b>
    </P1>
    <Ul1>
      <Li1>The Licensing Agreement and these General Licence Conditions are governed by Dutch law.</Li1>
      <Li1>
        Any disputes between the Parties will be submitted to the competent court in Rotterdam. In addition the Parties
        can jointly decide to settle a dispute, or part of that dispute, by means of arbitration, mediation, or a
        binding opinion.
      </Li1>
    </Ul1>
    <P1> </P1>
    <P1>
      <b>Article 18 Other stipulations</b>
    </P1>
    <Ul1>
      <Li1>
        If any provision under the Licensing Agreement and/or General Licence Conditions turns out to be null and void,
        this will not affect the validity of the agreement as a whole. In that case the Parties will agree (a)
        replacement, new provision(s) which represent(s) the purport of the original Licensing Agreement and General
        Licence Conditions as much as legally possible.
      </Li1>
      <Li1>
        In the event of a contradiction between the provisions of the General Licence Conditions and the Licensing
        Agreement(s) the provisions of the Licensing Agreement(s) will take precedence.
      </Li1>
      <Li1>
        If the Supplier has to perform work relating to data of the Client, employees or Users on the grounds of a
        request or authorised instruction from a government body, or in connection with a legal obligation, all the
        related costs will be charged to the Client, unless the Supplier is responsible for the grounds for this
        investigation. Insofar as possible the Supplier will inform the Client to this effect in advance.
      </Li1>
      <Li1>
        The version of any communication received or saved by the Supplier applies as authentic (including log files),
        barring proof to the contrary provided by the Client.
      </Li1>
      <Li1>
        The Parties will always inform each other in writing within a reasonable period of time of any changes to their
        name, postal address, email address, telephone number and, if requested, their bank account number.
      </Li1>
      <Li1>
        The Client gives the Supplier permission to use the client name, logo, trademarks and/or trade names in press
        releases, product brochures, sales presentations, financial reports and/or to state on the website that the
        Client is one of the Supplier's clients. All other public messages and/or announcements must be mutually
        approved by the Parties in advance.
      </Li1>
      <Li1>
        The Client is not entitled to transfer the rights and obligations under the Licensing Agreement and/or General
        Licence Conditions to a third party without the Supplier's written permission. The Supplier is entitled to
        transfer the rights and obligations under the Licensing Agreement and/or General Licence Conditions to a third
        party.
      </Li1>
    </Ul1>
    <P1>
      <b> </b>
    </P1>
    <P1>
      <b>\\ General Licence Conditions</b>
    </P1>
    <P2>
      <b></b>
      <br />
    </P2>
  </>
);
