import { CarResult } from '@japieglobal/shared/src/types/car-types';

export function addCarBasedOnRank(car: CarResult, cars: CarResult[], maxPerPage: number) {
  if (!car) {
    return cars;
  }
  if (!cars || cars.length === 0) {
    return [car];
  }
  const getCarRank = (rankCar: CarResult) => rankCar?.rank?.counter ?? 0;

  const isSmallerThanFirst = getCarRank(cars[0]) > getCarRank(car);
  const isBiggerThanLast = getCarRank(cars[cars.length - 1]) < getCarRank(car);
  const isLessThanMaxPerPage = cars.length < maxPerPage;

  if (isSmallerThanFirst || (isBiggerThanLast && !isLessThanMaxPerPage)) {
    return getCarRank(car) !== getCarRank(cars[0]) ? [car, ...cars] : cars;
  }

  return cars.reduce((previousValue: CarResult[], currentValue) => {
    previousValue.push(currentValue);
    if (getCarRank(currentValue)! + 1 === getCarRank(car)) {
      previousValue.push(car);
    }
    return previousValue;
  }, []);
}
