import { instance } from './instance';
import { User } from './user';

export interface LoginResponse {
  verified: boolean;
  has_phone_number: boolean;
  session_token: string;
}

interface ResetPasswordBody {
  token: string;
  password: string;
}
export const resetPassword = async (body: ResetPasswordBody): Promise<User> =>
  instance.post(`/auth/reset`, body).then((res) => res.data);

interface ResetPhoneNumber {
  token: string;
  phone_number: string;
}
export const resetPhoneNumber = async (body: ResetPhoneNumber) =>
  instance.post(`/auth/reset/phone`, body).then((res) => res.data);

interface ForgotBody {
  email: string;
}
export const forgotPassword = async (body: ForgotBody) => instance.post(`/auth/forgot`, body);
export const forgotPhoneNumber = async (body: ForgotBody) => instance.post(`/auth/forgot/phone`, body);

export const logout = async () => instance.get(`/logout`).then((res) => res.status === 200 || res.status === 302);

interface LoginBody {
  email: string;
  password: string;
}
export const login = async (body: LoginBody): Promise<LoginResponse> =>
  instance.post(`/login`, body).then((res) => res.data);

export const resendSmsVerificationCode = async () => instance.post('/two-factor/send');

export const verifySmsVerificationCode = async (code: string) =>
  instance.post('/two-factor/verify', { tfa_code: code });

interface ConfirmUserBody {
  activation_token: string;
  password: string;
}
export const confirmUser = async (body: ConfirmUserBody): Promise<User> =>
  instance.post(`/auth/activate`, body).then((res) => res.data);
