import React, { useState, useEffect } from 'react';
import { BaseTextInput, BaseTextInputProps } from '../base-text-input/base-text-input';

export type TextInputProps = BaseTextInputProps<string>;

export const TextInput = ({ value, defaultValue = '', ...baseProps }: TextInputProps) => {
  const [tempValue, setTempValue] = useState<string | undefined>(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  return (
    <BaseTextInput
      value={tempValue || defaultValue}
      onChange={(inputValue) => setTempValue(inputValue)}
      type="text"
      {...baseProps}
    />
  );
};
