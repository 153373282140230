import React, { useContext, useEffect, useState } from 'react';
import {
  AppSearchParamKeys,
  MultiSelectFilter,
  SelectOption,
  valuesToSelectOptions,
} from '../../../shared/src/components';
import { getClusters, getDealerLocations } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

export const FilterLocation = () => {
  const [clusters, setClusters] = useState<string[]>([]);
  const { user } = useContext(UserContext);

  const [dealerLocations, setDealerLocations] = useState<string[]>(user.locations);
  const [locations, setLocations] = useState<SelectOption[]>(valuesToSelectOptions(user?.locations));

  useEffect(() => {
    getClusters(true)
      .then(setClusters)
      .catch(() => setClusters([]))
      .finally(() => {
        if (!dealerLocations.length) {
          getDealerLocations(user.dealer)
            .then(setDealerLocations)
            .catch(() => setClusters([]));
        }
      });
  }, [dealerLocations?.length, user.dealer]);

  useEffect(() => {
    let tempLocations: SelectOption[] = [];
    tempLocations = valuesToSelectOptions(dealerLocations?.concat(clusters));
    setLocations(tempLocations);
  }, [clusters, dealerLocations, user.dealer]);

  if (!user.permissions.includes('locations')) return null;

  return (
    <span className="establishment">
      <MultiSelectFilter
        searchParamKey={AppSearchParamKeys.LOCATION_NAMES}
        labelTranslationKey="ESTABLISHMENT"
        staticOptions={locations}
        disableAutoSelect
      />
    </span>
  );
};
