import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { SelectInput, SelectOption } from '@japieglobal/shared';
import { useTranslation } from 'react-i18next';
import { OptionSelectProps } from './type';
import { carHeader } from '@japieglobal/shared/src/api/services/common';
import { CarHeader } from '@japieglobal/shared/src/api/services';

export const FuelSelect = ({ setValue, value, standardOption }: OptionSelectProps) => {
  const [fuels, setFuels] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getFuels = useCallback(() => {
    if (standardOption?.make) {
      setIsLoading(true);
      carHeader({
        type: CarHeader.FUEL,
        make: standardOption.make,
        body: standardOption.body ?? undefined,
        hp: standardOption.hp ?? undefined,
        build: standardOption.build ?? undefined,

        dealer_specific: false,
      })
        .then((data) => setFuels((data.headers as string[]).map((fuels) => ({ label: t(fuels), value: fuels }))))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [standardOption, snackbarErrorHandler, t]);

  useEffect(() => {
    getFuels();
  }, [getFuels]);

  return (
    <SelectInput
      isLoading={isLoading}
      disabled={!standardOption?.make}
      label={t('FUEL')}
      options={fuels}
      setValue={setValue}
      value={value}
    />
  );
};
