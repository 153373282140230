import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterStockDaysClient = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.STOCK_DAYS_CLIENT_FROM, AppSearchParamKeys.STOCK_DAYS_CLIENT_TO]}
    defaultValue={[0, undefined]}
    min={0}
    labelTranslationKey="STOCK_DAYS_CLIENT"
  />
);
