import React from 'react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ThemeMode } from '../../../../style/types';
import { LocalStorageKeys } from '../../../../types/local-storage-keys';
import { MenuButton } from '../../menu-button';
import { TopRowMenuButtonProps } from '../../top-row';

export const ThemeButton = ({ internal }: TopRowMenuButtonProps) => {
  const localStoredTheme = localStorage.getItem(LocalStorageKeys.THEME);
  const handleChange = () => {
    localStoredTheme === ThemeMode.LIGHT
      ? localStorage.setItem(LocalStorageKeys.THEME, ThemeMode.DARK)
      : localStorage.setItem(LocalStorageKeys.THEME, ThemeMode.LIGHT);
    window.location.reload();
  };

  return (
    <MenuButton hoverTitle={'THEME'} internal={internal} onClick={handleChange}>
      {localStoredTheme === ThemeMode.DARK ? <LightModeIcon /> : <DarkModeIcon />}
    </MenuButton>
  );
};
