import { UseQueryStringValueSetter } from '../hooks/query-string-state';

export type QueryStateMultiSetValueSetter = {
  func: UseQueryStringValueSetter;
  value: any;
};

export const queryStateMultipleAtOnce = async ({
  valueSetters,
  handleError,
  currentUrl,
}: {
  valueSetters: Array<QueryStateMultiSetValueSetter>;
  handleError?: (err: Error) => void;
  currentUrl?: string;
}) => {
  let url: string | undefined = currentUrl ?? undefined;

  // eslint-disable-next-line no-restricted-syntax
  for (const valueSetter of valueSetters) {
    if (url) {
      // eslint-disable-next-line prefer-destructuring
      url = url.split('?')[1];
    }

    // eslint-disable-next-line no-await-in-loop,@typescript-eslint/no-loop-func
    url = (await new Promise((resolve) =>
      valueSetter.func(
        valueSetter.value,
        (newUrl) => {
          resolve(newUrl);
        },
        url ?? window.location.search,
      ),
    ).catch((err) => (handleError ? handleError(err) : undefined))) as string;
  }

  return url;
};
