import React from 'react';
import { DataTypeFilter } from './filter/data-filter';
import { CsmReportTable } from './table/csm-report-table';
import { FilterResetButton } from '@japieglobal/shared';
import { Grid2 } from '@mui/material';

export const AdminMainCsmReport = () => (
  <>
    <Grid2 container justifyContent={'flex-end'} spacing={1} mb={1}>
      <Grid2 size={2}>
        <FilterResetButton />
      </Grid2>
      <Grid2 size={2}>
        <DataTypeFilter />
      </Grid2>
    </Grid2>
    <CsmReportTable />
  </>
);
