import { styled } from '@mui/system';
import { TextField } from '@mui/material';

export const DataGridWrapper = styled(`div`)({
  minHeight: 500,
  width: '100%',
});

export const AdminTextField = styled(TextField)({
  '& label': {
    fontSize: '16px',
    zIndex: 1,
  },
  '& div': {
    minHeight: '32px',
  },
  '& input': {
    fontSize: '16px',
    zIndex: 2,
  },
});

export const AdminTableHeaderField = styled(TextField)({
  '& div:before': {
    borderBottom: '0px',
  },
  '& label': {
    fontSize: '12px',
    zIndex: 1,
  },
  '& div': {
    minHeight: '32px',
  },
  '& input': {
    fontSize: '12px',
    zIndex: 2,
  },
});

export const DatePickerTextField = styled(TextField)({
  width: 150,
});

export const FieldWrapper = styled(`div`)({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
});

export const FormRow = styled(`div`)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '18px',
  marginBottom: '18px',
});
