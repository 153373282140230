import React, { PropsWithChildren, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { ColoredButton } from '@japieglobal/shared/src/components';
import { largeNumberFormatter } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';

const Price = ({ children }: PropsWithChildren) => (
  <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{children}</Typography>
);

export const CarWindowPriceCell = React.memo(
  ({ car, removeRanking }: { car: CarResult; removeRanking: () => void }) => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    return (
      <>
        {car.rank?.target_value && car.price?.recalculate && car.price?.recalculate.length > 0 ? (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Price>{largeNumberFormatter(user, car.rank?.target_value)}</Price>
            <Price>{`(${largeNumberFormatter(user, car.rank.target_discount)})`}</Price>
            <ColoredButton style={{ height: '20px', fontSize: '10px' }} type="button" onClick={removeRanking}>
              {t('CANCEL')}
            </ColoredButton>
          </Box>
        ) : (
          <>{largeNumberFormatter(user, car.price?.local) || largeNumberFormatter(user, car.rank?.target_value)}</>
        )}
      </>
    );
  },
);
