import { instance } from './instance';

export enum ExportColumn {
  HOME = 'export',
  RECOMMENDATIONS = 'recommendations',
  CHARTS = 'jp_market',
}

export interface getExportColumnsResponse {
  all_columns: { [key: string]: string };
  selected_columns: string[];
}

export const getExportColumns = async (lang: string, type: ExportColumn): Promise<getExportColumnsResponse> =>
  instance.get(`/api/cars/export/columns`, { params: { lang, column_preference_type: type } }).then((res) => res.data);

export const setExportColumns = async (
  columns: string[],
  lang: string,
  type: ExportColumn,
): Promise<getExportColumnsResponse> =>
  instance
    .post(`/api/cars/export/columns`, { columns }, { params: { lang, column_preference_type: type } })
    .then((res) => res.data);

export const deleteExportColumns = async (lang: string, type: ExportColumn): Promise<getExportColumnsResponse> =>
  instance
    .delete(`/api/cars/export/columns`, { params: { lang, column_preference_type: type } })
    .then((res) => res.data);
