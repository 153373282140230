import { AppSearchParamKeys } from '@japieglobal/shared';
import { CsmReportKeys, SalesReportRow } from '@japieglobal/shared/src/api/services';
import { CsmColumn } from '../../shared/types/csm';

export type SalesColumn = Omit<CsmColumn, 'field'> & {
  field: keyof SalesReportRow;
};

export const salesColumns: SalesColumn[] = [
  {
    field: CsmReportKeys.STATUS,
    headerName: 'Status',
    headerDescription: 'Status',
    flex: 0.8,
    type: 'string',
    cellRender: 'statusSelect',
    searchParamKey: AppSearchParamKeys.STATUSES,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.DEALER,
    headerName: 'Dealer',
    headerDescription: 'Dealer',
    flex: 2.5,
    type: 'string',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.DEALER,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.HEALTH,
    headerName: 'Health',
    headerDescription: 'Health',
    flex: 0.5,
    type: 'string',
    cellRender: 'healthSelect',
    searchParamKey: AppSearchParamKeys.HEALTHS,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.CSM,
    headerName: 'CSM',
    headerDescription: 'CSM',
    flex: 1.5,
    type: 'string',
    cellRender: 'csmSelect',
    searchParamKey: AppSearchParamKeys.CSM,
    selectType: 'multi',
  },
  {
    field: CsmReportKeys.COMMENTS,
    headerName: 'MESSAGES',
    headerDescription: 'MESSAGES',
    flex: 0.5,
    type: 'string',
    cellRender: 'comments',
    searchParamKey: AppSearchParamKeys.COMMENTS,
    selectType: 'single',
  },
  {
    field: CsmReportKeys.LAST_COMMENT_DATE,
    headerName: 'Last comment',
    headerDescription: 'Last comment',
    flex: 1,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.LAST_COMMENT_DATE,
    selectType: 'single',
  },
  {
    field: 'id',
    headerName: '',
    headerDescription: '',
    flex: 0.5,
    type: 'string',
    cellRender: 'actions',
    selectType: 'single',
  },
];
