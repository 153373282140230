import { DarkThemeColors } from './theme-colors';

export const DarkPalette = {
  primary: {
    main: DarkThemeColors.PRIMARY,
    light: DarkThemeColors.PRIMARY_LIGHT,
    contrastText: DarkThemeColors.PRIMARY_TEXT,
  },
  secondary: {
    main: DarkThemeColors.SECONDARY,
  },
  warning: {
    main: DarkThemeColors.WARNING,
  },
  info: {
    main: DarkThemeColors.INFO,
    dark: DarkThemeColors.INFO_DARK,
    contrastText: DarkThemeColors.INFO_TEXT,
  },
  background: {
    default: DarkThemeColors.BACKGROUND,
  },
};
