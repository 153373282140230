import React from 'react';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SevereColdIcon from '@mui/icons-material/SevereCold';
import { LocalStorageKeys } from '../../../../types/local-storage-keys';
import { MenuButton } from '../../menu-button';
import { TopRowMenuButtonProps } from '../../top-row';
import { ChristmasMode } from '../../../../style/types';

export const LetItSnowButton = ({ internal }: TopRowMenuButtonProps) => {
  const localStoredShowSnow = localStorage.getItem(LocalStorageKeys.SNOW);
  const handleChange = () => {
    localStoredShowSnow === ChristmasMode.LET_IT_SNOW
      ? localStorage.setItem(LocalStorageKeys.SNOW, ChristmasMode.STOP_SNOWING)
      : localStorage.setItem(LocalStorageKeys.SNOW, ChristmasMode.LET_IT_SNOW);
    window.location.reload();
  };

  return (
    <MenuButton
      hoverTitle={localStoredShowSnow === ChristmasMode.LET_IT_SNOW ? 'STOP_SNOWING' : 'LET_IT_SNOW'}
      internal={internal}
      onClick={handleChange}
    >
      {localStoredShowSnow === ChristmasMode.LET_IT_SNOW ? <SevereColdIcon /> : <AcUnitIcon />}
    </MenuButton>
  );
};
