import React, { useEffect } from 'react';
import { CarHeader, CarInformationResult } from '@japieglobal/shared/src/api/services';
import { Grid2 } from '@mui/material';
import { noop } from '@japieglobal/shared/src/utils';
import { TaxationStep } from './taxation-steps/taxation-step';
import { TaxationFilterTaxationButton } from './left-side-filters/taxation-filter-taxation-button';
import { TaxationErrorView } from './taxation-list-view/taxation-error-view';
import { WindowResultErrorCode } from '@japieglobal/shared/src/types';

interface TaxationOptionsView {
  evaluateAllCars: () => void;
  carInformation?: CarInformationResult;
}

export const TaxationOptionsView = ({ evaluateAllCars, carInformation }: TaxationOptionsView) => {
  useEffect(() => {
    // This piece of code makes it possible for the user to start the taxation with enter
    // instead of doing so with the mouse.
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && carInformation) {
        evaluateAllCars();
      }
    };
    document.addEventListener('keyup', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, [carInformation, evaluateAllCars]);

  return carInformation?.error_code || carInformation?.error_message ? (
    <TaxationErrorView
      windowResult={{
        pagable: { page_count: 0, page_index: 0, page_size: 0, total: 0 },
        window: [],
        error: WindowResultErrorCode.ERROR_INVALID_CAR,
      }}
    />
  ) : (
    <Grid2 padding={1} container rowSpacing={1}>
      <Grid2>
        <TaxationStep goToNextStep={noop} inputKey={CarHeader.OPTIONS} />
      </Grid2>
      <Grid2 display={'flex'} flex={1} justifyContent={'flex-end'}>
        <TaxationFilterTaxationButton key="taxation" onSubmit={evaluateAllCars} />
      </Grid2>
    </Grid2>
  );
};
