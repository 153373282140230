import React from 'react';
import { createStyles, makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bubbleRed: {
      background: theme.palette.warning.main,
    },
    bubbleGreen: {
      background: theme.palette.primary.main,
    },
    noIndex: {
      zIndex: 100,
    },
  }),
);

const BubbleDiv = styled(`div`)({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  display: 'block',
  width: '20px',
  height: '20px',
  fontWeight: 500,
  color: 'white',
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'center',
  borderRadius: '100%',
});

export const Bubble = React.memo(
  ({ red, useZIndex = true, children }: { red?: boolean; useZIndex?: boolean; children: JSX.Element | any }) => {
    const classes = useStyles();
    let activeClasses = red ? classes.bubbleRed : classes.bubbleGreen;
    if (useZIndex) {
      activeClasses = `${classes.noIndex} ${activeClasses}`;
    }
    return <BubbleDiv className={`${activeClasses}`}>{children}</BubbleDiv>;
  },
);
