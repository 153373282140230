import React, { useCallback, useMemo, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { deleteUsers } from '@japieglobal/shared/src/api/services';
import { ConfirmationDialog } from '@japieglobal/shared';

interface useAdminDeleteUsersProps {
  selected: string[];
  setSelected: (val: string[]) => void;
  refreshUserList: () => void;
}
export const useAdminDeleteUsers = ({ selected, refreshUserList, setSelected }: useAdminDeleteUsersProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const onClickActualDeleteUsers = useCallback(() => {
    if (selected && selected.length > 0) {
      deleteUsers({ user_ids: selected })
        .then(() => {
          setSelected([]);
          refreshUserList();
        })
        .catch(snackbarErrorHandler)
        .finally(() => {
          setShowDeleteUserDialog(false);
        });
    }
  }, [refreshUserList, selected, setSelected, snackbarErrorHandler]);

  const onClickDelete = useCallback(() => {
    if (selected && selected.length > 0) {
      setShowDeleteUserDialog(true);
    }
  }, [selected]);

  const deleteUserDialogComponent = useMemo(() => {
    return showDeleteUserDialog ? (
      <ConfirmationDialog
        closeDialog={() => setShowDeleteUserDialog(false)}
        title="Verwijder gebruikers"
        question="Weet je zeker dat je gebruikers verwijderen wilt?"
        yesAction={onClickActualDeleteUsers}
      />
    ) : undefined;
  }, [onClickActualDeleteUsers, showDeleteUserDialog]);

  return {
    onClickDelete,
    deleteUserDialogComponent,
  };
};
