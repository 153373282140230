import { Box, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CarResult, getCarPrice } from '@japieglobal/shared/src/types/car-types';
import { DisplayHints } from '@japieglobal/shared/src/components/hints/display-hints';
import { ResultBrandModel } from './result-cells/result-brand-model-cell';
import { ResultCarMessageShareCell } from './result-cells/result-car-message-share';
import { ResultCopyCarIdentifier } from './result-cells/result-copy-car-identifier';
import { ResultPlusButtonCell } from './result-cells/result-plus-button-cell';
import { ResultPriceCell } from './result-cells/result-price-cell';
import { ResultPriceProposalLockCell } from './result-cells/result-price-proposal-lock';
import { ResultTableInformationCell } from './result-cells/result-table-information-cell/result-table-information-cell';
import { CarTableCell } from './result-table-styles';
import { ResultTrendCell } from './result-cells/result-trend-cell';
import { ResultWindowButtonCell } from './result-cells/result-window-button-cell';
import { useSearchParams } from 'react-router-dom';
import { settings } from '@japieglobal/shared/src/settings';
import { TopDealer } from '@japieglobal/shared/src/types';
import { ResultTaxationButton } from './result-cells/result-taxation-button';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { noop } from '@japieglobal/shared/src/utils';
import { ResultTableImageCell } from './result-cells/result-table-image-cell';
import { ellipsify } from '../../utils/ellipsify';
import { ResultTopDealers } from '../../taxation-and-results-shared/result-top-dealers';
import { ResultPriceInformation } from './result-cells/result-price-information';
import { compareMemoProps } from '../../utils/compare-react-props';
import { useClickQueryStates } from '../../states/click-states';
import { ResultUrlToExternalCell } from './result-cells/result-url-to-external-cell';
import { UserContext } from '@japieglobal/shared/src/user-context';

const useStyles = makeStyles({
  rowNoBorder: {
    '& td': {
      border: '0',
    },
  },
});

interface ResultTableRowProps {
  lt?: CarResult | undefined;
  car: CarResult;
  reloadCarList: (id: number) => void;
  closeDialog?: () => void;
  taxationView?: boolean;
  windowView?: boolean;
  showReduced: boolean;
  isCompetitorIncluded?: boolean;
  topDealers?: TopDealer[];
}

export const ResultTableRow = React.memo(
  ({
    car,
    isCompetitorIncluded,
    reloadCarList,
    taxationView = false,
    windowView,
    showReduced,
    closeDialog = noop,
    topDealers,
  }: ResultTableRowProps) => {
    const classes = useStyles();
    const [price, setPrice] = useState(getCarPrice(car));
    const {
      user: { permissions },
    } = useContext(UserContext);

    const { clickQuerySetters, clicksAPIStyle } = useClickQueryStates();
    const [, setSearchParams] = useSearchParams();
    const setCompetitorCar = useCallback(
      () =>
        setSearchParams((prev) => {
          prev.set('window_car_id', String(car.id));
          return prev;
        }),
      [car, setSearchParams],
    );

    const showLicensePlate = useMemo(() => {
      return settings().country == 'nl' && (car.license_plate != 'NB' || (!car.vin && !car.reference_code));
    }, [car]);

    const showPrice = useMemo(() => {
      if (taxationView) {
        return false;
      }
      if (windowView && !taxationView) {
        return true;
      }
      if (!windowView && showReduced) {
        return false;
      }
      return true;
    }, [showReduced, taxationView, windowView]);

    const showPriceLock = useMemo(() => {
      if (taxationView) {
        return false;
      }
      if (windowView && !taxationView) {
        return true;
      }
      if (!windowView && showReduced) {
        return false;
      }
      return true;
    }, [showReduced, taxationView, windowView]);

    const showTaxationButton = useMemo(() => {
      return !taxationView && !windowView && car.status == 'verkocht';
    }, [car.status, taxationView, windowView]);

    const showWindowButton = useMemo(() => {
      return !taxationView && !windowView && car.status == 'voorraad';
    }, [car.status, taxationView, windowView]);

    const tableRowContent = useMemo(
      () => (
        <>
          <ResultTableImageCell car={car} windowView={windowView} />
          {!showReduced && <ResultBrandModel car={car} windowView={windowView} />}
          {!showReduced && <ResultTableInformationCell car={car} />}
          {!showReduced && (
            <CarTableCell>
              <p title={car.location_name}>{ellipsify(car.location_name, 30)}</p>
            </CarTableCell>
          )}
          {showLicensePlate ? (
            <ResultCopyCarIdentifier carIdentifier={car.license_plate!} replaceDashes />
          ) : (
            <ResultCopyCarIdentifier carIdentifier={(car.reference_code || car.vin)!} />
          )}
          {showWindowButton && (
            <ResultPlusButtonCell
              setCompetitorCar={setCompetitorCar}
              disabled={!!car.sold?.since && car.sold?.since > 0}
              includesBanned={car.includes_banned}
            />
          )}
          {showTaxationButton && (
            <CarTableCell>
              <ResultTaxationButton car={car} />
            </CarTableCell>
          )}
          <ResultUrlToExternalCell car={car} />
          <ResultPriceInformation car={car} />
          <ResultTrendCell car={car} />

          {!showReduced && !windowView && <ResultWindowButtonCell car={car} />}
          {showPrice && (
            <ResultPriceCell
              price={price}
              setPrice={setPrice}
              car={car}
              closeDialog={closeDialog}
              reloadCarList={reloadCarList}
              competitorView={windowView}
            />
          )}
          {showPriceLock && (
            <ResultPriceProposalLockCell
              closeDialog={closeDialog}
              targetPrice={price}
              car={car}
              reloadCarList={reloadCarList}
              isCompetitorView={isCompetitorIncluded}
            />
          )}
          {!taxationView && <ResultCarMessageShareCell windowView={windowView} car={car} />}

          {isCompetitorIncluded && (topDealers || clicksAPIStyle.click_dealer) && (
            <Box mt={1}>
              <ResultTopDealers
                dealers={topDealers}
                selected={clicksAPIStyle.click_dealer}
                click={(dealer) => {
                  clickQuerySetters.setClickDealer(dealer);
                  clickQuerySetters.setClickEquipmentInStock(dealer ? ClickState.ALL : undefined);
                }}
              />
            </Box>
          )}
          {isCompetitorIncluded && permissions.includes('monitoringTaxationButton') && (
            <ResultTaxationButton car={car} />
          )}
          <DisplayHints />
        </>
      ),
      [
        car,
        windowView,
        showReduced,
        showLicensePlate,
        showWindowButton,
        setCompetitorCar,
        showTaxationButton,
        showPrice,
        price,
        closeDialog,
        reloadCarList,
        showPriceLock,
        isCompetitorIncluded,
        taxationView,
        topDealers,
        clicksAPIStyle.click_dealer,
        permissions,
        clickQuerySetters,
      ],
    );

    return useMemo(
      () => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TableRow name="result-table-row" key={car.id} className={windowView ? classes.rowNoBorder : undefined}>
          {tableRowContent}
        </TableRow>
      ),
      [car?.id, classes.rowNoBorder, tableRowContent, windowView],
    );
  },
  compareMemoProps(['car']),
);
