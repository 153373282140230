import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterStockDays = () => (
  <span className="stockDays">
    <RangeFilter
      searchParamKey={[AppSearchParamKeys.STOCK_DAYS_FROM, AppSearchParamKeys.STOCK_DAYS_TO]}
      defaultValue={[0, undefined]}
      min={0}
      labelTranslationKey="DAYS"
    />
  </span>
);
