import React from 'react';
import { SelectFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

const carHeaderOptions: CarHeaderProps = { dealer_specific: false, type: CarHeader.MAKE };
export const TaxationFilterMake = () => (
  <Grid2 size={2}>
    <SelectFilter
      tabOnEnter
      hideResetButton
      searchParamKey={AppSearchParamKeys.MAKE}
      carHeaderOptions={carHeaderOptions}
      labelTranslationKey="MAKE"
    />
  </Grid2>
);
