export enum WindowResultErrorCode {
  ERROR_VALUATION = 'ERROR_VALUATION',
  ERROR_VALUATION_MIN_MILEAGE = 'ERROR_VALUATION_MIN_MILEAGE',
  ERROR_VALUATION_MIN_BUILD = 'ERROR_VALUATION_MIN_BUILD',
  ERROR_VALUATION_MODEL = 'ERROR_VALUATION_MODEL',
  ERROR_WINDOW_EMPTY = 'ERROR_WINDOW_EMPTY',
  ERROR_INVALID_DEALER = 'ERROR_INVALID_DEALER',
  ERROR_UNKNOWN = 'ERROR_UNKNOWN',
  ERROR_INVALID_CAR = 'ERROR_INVALID_CAR',
}
