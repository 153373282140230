import React from 'react';
import { Badge, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CarResult, Equipment } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../../../states/click-states';
import { Tooltip } from '@japieglobal/shared/src/components';
import { clickStateParser } from '../../../../utils/tri-state-parser';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { OptionLinkStyled, OptionIcon } from '../../car-window-icons';

const snakeToCamel = (str) => {
  return str.replace(/(?!^)_(.)/g, (_, char) => char.toUpperCase());
};

interface CarWindowOptionCellProps {
  car: CarResult;
  clicks: ClicksPassedDown | undefined;
  equipmentOption: keyof Equipment;
  onHoverContent?: string | JSX.Element;
  useClickState?: boolean;
  hideBadge?: boolean;
  badgeColor?: string;
}

export const CarWindowEquipment = ({
  car,
  clicks,
  equipmentOption,
  onHoverContent,
  useClickState = false,
  hideBadge = true,
  badgeColor,
}: CarWindowOptionCellProps) => {
  const clickKey = snakeToCamel('equipment_' + equipmentOption);
  const clickObject = (clicks || {})[clickKey] || {};
  const enabled = car.equipment![equipmentOption];
  const active = useClickState ? clickObject.value == ClickState.LOCK : clickObject.value;
  const antiLockIsActive = useClickState && clickObject.value == ClickState.ANTI_LOCK && !enabled;

  const { t } = useTranslation();

  const handleClick = () => {
    if (useClickState) {
      clickObject.setter(clickStateParser(clickObject.value));
    } else {
      clickObject.setter(!clickObject.value);
    }
  };

  const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      backgroundColor: badgeColor,
      height: 5,
      minWidth: 5,
    },
  });

  return (
    <Tooltip onHoverContent={onHoverContent || t(equipmentOption.toUpperCase())}>
      <OptionLinkStyled href="#" onClick={handleClick}>
        <StyledBadge variant="dot" invisible={hideBadge}>
          <OptionIcon
            missing={false}
            enabled={enabled}
            active={active}
            option={equipmentOption}
            antiLock={antiLockIsActive}
            windowView={true}
          />
        </StyledBadge>
      </OptionLinkStyled>
    </Tooltip>
  );
};
