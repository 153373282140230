import React from 'react';
import CarMessageBubbleIcon from '@mui/icons-material/ChatBubble';
import { ToggleFilter, AppSearchParamKeys } from '@japieglobal/shared';

export const MessagesButton = () => (
  <ToggleFilter
    disableTripleState
    searchParamKey={AppSearchParamKeys.SHOW_MESSAGES_CARS}
    labelTranslationKey="MESSAGES"
    icon={<CarMessageBubbleIcon />}
    className="messagesButton"
  />
);
