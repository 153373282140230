import { UserRoles } from '@japieglobal/shared/src/api/services';
import { settings } from '@japieglobal/shared/src/settings';
import { UserContext } from '@japieglobal/shared/src/user-context';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { styled } from '@mui/styles';
import React, { useContext } from 'react';
import { ColoredButtonSmall } from './results/result-table-styles';

const ScrapeManagementButton = styled(ColoredButtonSmall)({
  height: '25px',
  width: '15px',
  position: 'absolute',
  bottom: 0,
  right: 0,
  border: 0,
});

interface CarScrapeAdminButton {
  carUrl?: string;
  carDealer?: string;
  country?: string;
  marginBottom?: number;
}
export const CarScrapeAdminButton = ({ carUrl, carDealer, marginBottom, country }: CarScrapeAdminButton) => {
  const { user } = useContext(UserContext);
  const hasScrapePermission =
    user?.role === UserRoles.SUPER_ADMIN &&
    (user.permissions.includes('scrapeUser') || user.permissions.includes('scrapeManagement'));
  const showScrapeLink = hasScrapePermission && carUrl && (!country || settings().country == country?.toLowerCase());

  if (!showScrapeLink) return undefined;

  return (
    <ScrapeManagementButton
      style={{ marginBottom: marginBottom ?? 8 }}
      href={`/admin-scrape/items/production/${carDealer ?? user.dealer}?tab=files&url_api=${carUrl}`}
    >
      <LibraryBooksIcon style={{ width: 16 }} />
    </ScrapeManagementButton>
  );
};
