import React from 'react';
import { RenderCellDefParams } from '@japieglobal/shared/src/components';
import { MessagesCell } from '../../shared/admin-and-sales-report/cells/messages-cell/messages-cell';
import { CsmReportKeys, SalesReportRow, SuperAdminResponseType } from '@japieglobal/shared/src/api/services';
import { CsmSelectCell } from '../../shared/admin-and-sales-report/cells/csm-select-cell';
import { HealthSelectCell } from '../../shared/admin-and-sales-report/cells/health-select-cell';
import { StatusSelectCell } from '../../shared/admin-and-sales-report/cells/status-select-cell';
import { CellRender } from '../../shared/types/csm';
import { ActionsCell } from './actions-cell';
import moment from 'moment';

interface CellContentProps {
  cellRender: CellRender;
  renderCellParams: RenderCellDefParams<SalesReportRow>;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: () => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const CellContent = ({
  setRowsIsLoading,
  onDealerUpdated,
  cellRender,
  renderCellParams,
  superAdmins,
}: CellContentProps) => (
  <>
    {cellRender === 'basic' && <>{renderCellParams.value}</>}
    {cellRender === 'comments' && (
      <MessagesCell messages={renderCellParams.value} dealerAccountName={renderCellParams.row[CsmReportKeys.DEALER]} />
    )}
    {cellRender === 'csmSelect' && (
      <CsmSelectCell
        setRowsIsLoading={setRowsIsLoading}
        onDealerUpdated={onDealerUpdated}
        superAdmins={superAdmins}
        row={renderCellParams.row}
      />
    )}
    {cellRender === 'healthSelect' && (
      <HealthSelectCell
        setRowsIsLoading={setRowsIsLoading}
        onDealerUpdated={onDealerUpdated}
        row={renderCellParams.row}
      />
    )}
    {cellRender === 'statusSelect' && (
      <StatusSelectCell
        setRowsIsLoading={setRowsIsLoading}
        onDealerUpdated={onDealerUpdated}
        row={renderCellParams.row}
      />
    )}
    {cellRender === 'actions' && (
      <ActionsCell setRowsIsLoading={setRowsIsLoading} onDealerUpdated={onDealerUpdated} row={renderCellParams.row} />
    )}
    {cellRender === 'date' && <>{renderCellParams.value && moment(renderCellParams.value).format('DD-MM-YYYY')}</>}
  </>
);
