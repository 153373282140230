import { Tooltip } from '@japieglobal/shared';
import { styled } from '@mui/material';
import React from 'react';

const TableStyled = styled(`table`)({ fontSize: 12 });
const TdStyled1 = styled(`td`)({ fontWeight: 600, padding: 0, width: '160px' });
const TdStyled2 = styled(`td`)({ padding: 0 });

interface InformationPopoverProps {
  Element: React.ElementType;
  information: Array<Array<any>>;
  parentKey: string;
}

export const InformationPopover = React.memo(({ information, parentKey, Element }: InformationPopoverProps) => (
  <Tooltip
    onHoverContent={
      <TableStyled>
        <tbody>
          {information?.map(([val1, val2], index) => (
            <tr key={`${parentKey}-${index}-${val1}`}>
              <TdStyled1>{val1}</TdStyled1>
              <TdStyled2>{val2}</TdStyled2>
            </tr>
          ))}
        </tbody>
      </TableStyled>
    }
  >
    <Element />
  </Tooltip>
));
