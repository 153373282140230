import { OptionListItem } from '@japieglobal/shared/src/api/services';

export type CellRenderLogic = 'actions';
export interface clusterManagementColumn {
  field: keyof OptionListItem;
  headerName: string;
  headerDescription: string;
  flex: number;
  cellRenderLogic?: CellRenderLogic;
}

export const clusterManagementColumns: clusterManagementColumn[] = [
  {
    field: 'name',
    headerName: 'NAME',
    headerDescription: 'NAME',
    flex: 1,
  },
  {
    field: 'makes',
    headerName: 'MAKES',
    headerDescription: 'MAKES',
    flex: 1,
  },
  {
    field: 'models',
    headerName: 'MODELS',
    headerDescription: 'MODELS',
    flex: 1,
  },
  {
    field: 'commercial_vehicles_only',
    headerName: 'BW ONLY',
    headerDescription: 'BW ONLY',
    flex: 1,
  },
  {
    field: 'is_pseudo',
    headerName: 'IS_PSEUDO',
    headerDescription: 'IS_PSEUDO',
    flex: 1,
  },
  {
    field: 'is_hidden',
    headerName: 'IS_HIDDEN',
    headerDescription: 'IS_HIDDEN',
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'ACTIONS',
    headerDescription: 'ACTIONS',
    flex: 1,
    cellRenderLogic: 'actions',
  },
];
