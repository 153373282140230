import { DealerAccountHealth, DealerAccountStatus } from '@japieglobal/shared/src/api/services';

export const statusOptions = Object.values(DealerAccountStatus).map((status) => ({ label: status, value: status }));

export const accountHealthOptions = [{ label: 'None', value: '' }].concat(
  Object.values(DealerAccountHealth).map((health) => ({ label: health, value: health })),
);

export const arrCategoryOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];
