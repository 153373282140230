import { SuperAdminResponseType } from '@japieglobal/shared/src/api/services';
import { arraySymmetricalDifference } from '@japieglobal/shared/src/utils';

export const hasUserMissingLocations = (dealerLocations: string[], user: SuperAdminResponseType, dealer: string) => {
  const userDealerLocations = user.allowed_locations[dealer] ?? [];

  const difference = arraySymmetricalDifference(userDealerLocations, dealerLocations);

  return difference.length > 0;
};

export const getInvalidLocations = (dealerLocations: string[], userLocations: string[]) => {
  const userDealerLocations = userLocations ?? [];

  const difference = new Set(userDealerLocations);
  dealerLocations.forEach((elem) => {
    if (difference.has(elem)) {
      difference.delete(elem);
    }
  });

  return difference;
};

export const getUserInvalidLocations = (dealerLocations: string[], user: SuperAdminResponseType, dealer: string) => {
  const userDealerLocations = user.allowed_locations[dealer] ?? [];

  return getInvalidLocations(dealerLocations, userDealerLocations);
};
