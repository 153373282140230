import React from 'react';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserActivityWithId } from '../utils/admin-activity-list-mapper';
import { AdminPageType, AdminQueryStateKey } from '../hooks/admin-query-state';

const useStyles = makeStyles(() => ({
  tableBorder: {
    'borderCollapse': 'collapse',
    'fontSize': '9px',
    '& th': {
      border: '1px solid gray',
      width: '40px',
    },
    '& td': {
      paddingHorizontal: '5px',
      textAlign: 'center',
      border: '1px solid gray',
    },
  },
}));
interface AdminMainUserActivityListProps {
  users: { [id: string]: string }[];
  activityType: string;
  userActivity: UserActivityWithId[];
  columns: string[];
}
export const AdminMainUserActivityList = ({
  users,
  activityType,
  userActivity,
  columns,
}: AdminMainUserActivityListProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const buildUrl = (user, value) => {
    const userId = user !== 'TOTAL' ? users.find((u) => u.label === user)?.id : undefined;
    let dateFrom = value.id;
    let dateTo = value.id;
    if (!dateFrom) dateFrom = userActivity[0].id;
    if (!dateTo) dateTo = userActivity[userActivity.length - 2].id;
    let url = `/admin/${AdminPageType.ACTIVITY_DETAIL_LIST}?${AdminQueryStateKey.ACTIVITY_TYPE}=${activityType}&${AdminQueryStateKey.DATE_FROM}=${dateFrom}&${AdminQueryStateKey.DATE_TO}=${dateTo}`;
    if (userId) url += `&user_id=${userId}`;
    return url;
  };

  return (
    <table
      style={
        // 20 columns is the amount that fits on the screen without having a wierd view that overflows.
        userActivity.length > 20
          ? { display: 'block', overflowX: 'auto', whiteSpace: 'nowrap' }
          : { display: 'table', width: '100%', whiteSpace: 'nowrap' }
      }
      className={styles.tableBorder}
    >
      <thead>
        <tr>
          <th>-</th>
          {userActivity.map((item) => {
            return <th key={item.label}>{item?.date?.substring(0, 5) || t(item?.label?.toString() || '')}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {columns.map((column) => {
          return (
            <tr key={column}>
              <td>
                <span>{t(column)}</span>
              </td>
              {userActivity.map((value) => {
                return (
                  <td key={value.label}>
                    {value[column as keyof UserActivityWithId] && (
                      <div>
                        <Link to={buildUrl(column, value)}>{value[column as keyof UserActivityWithId]}</Link>
                      </div>
                    )}
                    {!value[column as keyof UserActivityWithId] && <div>-</div>}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
