import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalStorageKeys } from '../../types/local-storage-keys';
import { ChristmasMode } from '../types';
import { keyframes, styled } from '@mui/system';

const fall = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 95vh;
        opacity: 1;
    }
`;

const sway = keyframes`
    0% {
        margin-left: 0;
    }
    25% {
        margin-left: 50px;
    }
    50% {
        margin-left: -50px;
    }
    75% {
        margin-left: 50px;
    }
    100% {
        margin-left: 0;
    }
`;

const Snowflake = styled('div')({
  position: 'absolute',
  color: 'skyblue',
  fontFamily: "'Pacifico', cursive",
  animation: `${fall} ease-in infinite, ${sway} ease-in-out infinite`,
  zIndex: 1000000,
});

export const useSnowFall = () => {
  const showSnow = localStorage.getItem(LocalStorageKeys.SNOW);
  if (showSnow !== ChristmasMode.LET_IT_SNOW && showSnow !== ChristmasMode.STOP_SNOWING) {
    localStorage.setItem(LocalStorageKeys.SNOW, ChristmasMode.STOP_SNOWING);
  }
  const snowContainer = document.getElementById('snow-container');
    const snowContent = useMemo(() => ['❄️', '❅', '❆'], []);
  const [snowflakes, setSnowflakes] = useState<JSX.Element[]>([]);

  const random = (num: number) => Math.floor(Math.random() * num);

  const createSnow = useCallback(
    (num: number) => {
      const newSnowflakes: JSX.Element[] = [];
      for (let i = 0; i < num; i++) {
        newSnowflakes.push(
          <Snowflake
            key={i}
            style={{
              top: `-${random(100)}%`,
              left: `${random(95)}%`,
              fontSize: `${random(14) + 14}px`,
              animationDuration: `${random(20) + 20}s`,
            }}
          >
            {snowContent[random(3)]}
          </Snowflake>,
        );
      }
      setSnowflakes(newSnowflakes);
    },
    [snowContent],
  );

  useEffect(() => {
    if (showSnow === ChristmasMode.LET_IT_SNOW) {
      createSnow(30);
    }
  }, [createSnow, showSnow]);

  useEffect(() => {
    if (snowContainer && showSnow === ChristmasMode.STOP_SNOWING) {
      snowContainer.style.opacity = '0';
      setTimeout(() => {
        snowContainer.remove();
      }, 500);
    }
  }, [createSnow, showSnow, snowContainer]);

  return snowflakes;
};
