import React, { useMemo } from 'react';
import { SelectFilter, AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { Grid2 } from '@mui/material';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api/services';

export const TaxationFilterModel = () => {
  const [{ make }] = useAppSearchParams([AppSearchParamKeys.MAKE]);
  const carHeaderOptions: CarHeaderProps = useMemo(
    () => ({ dealer_specific: false, type: CarHeader.MODEL, make }),
    [make],
  );
  const filterDependencies = useMemo(() => ({ [AppSearchParamKeys.MAKE]: make }), [make]);

  return (
    <Grid2 size={2}>
      <SelectFilter
        tabOnEnter
        hideResetButton
        searchParamKey={AppSearchParamKeys.MODEL}
        carHeaderOptions={carHeaderOptions}
        filterDependencies={filterDependencies}
        labelTranslationKey="MODEL"
      />
    </Grid2>
  );
};
