import React from 'react';
import { Link, styled, SvgIconProps } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as PhoneConnect } from '../../../public/images/icons_svg/PhoneConnect.svg';
import { ReactComponent as Camera } from '../../../public/images/icons_svg/Achteruitrijcamera.svg';
import { ReactComponent as Trekhaak } from '../../../public/images/icons_svg/Trekhaak.svg';
import { ReactComponent as ClimateControl } from '../../../public/images/icons_svg/ClimateControl.svg';
import { ReactComponent as ZevenZitter } from '../../../public/images/icons_svg/7Zitter.svg';
import { ReactComponent as AdaptiveCruiseControl } from '../../../public/images/icons_svg/AdaptiveCruiseControl.svg';
import { ReactComponent as PDC } from '../../../public/images/icons_svg/PDC.svg';
import { ReactComponent as FourWheelDrive } from '../../../public/images/icons_svg/4x4.svg';
import { ReactComponent as Luchtvering } from '../../../public/images/icons_svg/Luchtvering.svg';
import { ReactComponent as Sportstoelen } from '../../../public/images/icons_svg/Sportstoelen.svg';
import { ReactComponent as VirtualCockpit } from '../../../public/images/icons_svg/VirtualCockpit.svg';
import { ReactComponent as Navigation } from '../../../public/images/icons_svg/navigation.svg';
import { ReactComponent as Airco } from '../../../public/images/icons_svg/airco.svg';
import { ReactComponent as AlloyWheels } from '../../../public/images/icons_svg/alloyWheels.svg';
import { ReactComponent as Leather } from '../../../public/images/icons_svg/leather.svg';
import { ReactComponent as Roof } from '../../../public/images/icons_svg/roof.svg';
import { ReactComponent as Xenon } from '../../../public/images/icons_svg/xenon.svg';
import { ReactComponent as InStock } from '../../../public/images/icons_svg/inStock.svg';
import { ReactComponent as IsDealer } from '../../../public/images/icons_svg/isDealer.svg';
import { ReactComponent as OfficialMake } from '../../../public/images/icons_svg/officialMake.svg';
import { ReactComponent as premiumAudio } from '../../../public/images/icons_svg/premiumAudio.svg';
import { ReactComponent as AMG } from '../../../public/images/icons_svg/amg.svg';
import { ReactComponent as MPakket } from '../../../public/images/icons_svg/m-pakket.svg';
import { ReactComponent as Sline } from '../../../public/images/icons_svg/s-line.svg';
import { ReactComponent as FR } from '../../../public/images/icons_svg/FR.svg';
import { ReactComponent as CarMap } from '../../../public/images/icons_svg/carMap.svg';

export const optionIcons = {
  'PhoneConnect': PhoneConnect,
  'Achteruitrijcamera': Camera,
  'Trekhaak': Trekhaak,
  'Climate Control': ClimateControl,
  '7 Zitter': ZevenZitter,
  'Adaptive Cruise Control': AdaptiveCruiseControl,
  'PDC': PDC,
  '4x4': FourWheelDrive,
  'Luchtvering': Luchtvering,
  'Sportstoelen': Sportstoelen,
  'Virtual Cockpit': VirtualCockpit,
  'navigation': Navigation,
  'alloy_wheels': AlloyWheels,
  'leather': Leather,
  'xenon': Xenon,
  'airco': Airco,
  'roof': Roof,
  'is_dealer': IsDealer,
  'official_make': OfficialMake,
  'in_stock': InStock,
  'Premium audio': premiumAudio,
  'AMG pakket': AMG,
  'M pakket': MPakket,
  'S-line': Sline,
  'FR': FR,
};

export const generalIcons = {
  carMap: CarMap,
};

export const OptionLinkStyled = styled(Link)({
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2.5px 3px',
  borderRadius: '3px',
  textDecoration: 'none',
  marginRight: '5px',
});

export const StyledOptionIcon = styled(SvgIcon)<{
  component?: React.ElementType;
  locked: number;
  antilock: number;
  windowview: number;
}>(({ theme, locked, antilock, windowview }) => ({
  border:
    locked && !antilock
      ? `1px solid ${theme.palette.primary.main}`
      : antilock
        ? `1px solid ${theme.palette.warning.main}`
        : undefined,
  borderRadius: '5px',
  padding: '1px',
  marginTop: windowview ? '5px' : undefined,
}));

interface getOptionIconColor {
  missing: boolean;
  active?: boolean;
  enabled?: boolean;
  antiLock?: boolean;
}
export const getOptionIconColor = ({ missing, enabled }: getOptionIconColor) => {
  if (missing) return 'warning.main';
  if (enabled) return 'primary.main';
  return '#d3d3cd';
};

const getStrokeWidth = (option: string) => {
  if (option === 'Premium audio') return 2;
  return 0;
};

const getFontSize = (option) => {
  if (option === 'Adaptive Cruise Control' || option === 'Premium audio' || option === 'M pakket') return 35;
  if (option === 'AMG pakket') return 50;
  if (option === 'S-line') return 40;
  return 22;
};

interface OptionIconProps {
  missing: boolean;
  enabled?: boolean;
  active?: boolean;
  fontSize?: number;
  option: string;
  antiLock?: boolean;
  color?: SvgIconProps['color'];
  windowView?: boolean;
}

export const OptionIcon = ({
  option,
  missing,
  enabled,
  fontSize,
  color,
  active = false,
  antiLock = false,
  windowView = false,
}: OptionIconProps) => {
  return (
    <StyledOptionIcon
      component={optionIcons[option]}
      locked={active ? 1 : 0}
      antilock={antiLock ? 1 : 0}
      inheritViewBox={true}
      sx={{
        fontSize: fontSize ?? getFontSize(option),
        color: color ?? getOptionIconColor({ missing: missing, enabled: enabled, antiLock: antiLock }),
        height: '25px',
      }}
      strokeWidth={getStrokeWidth(option)}
      stroke={'currentColor'}
      windowview={windowView ? 1 : 0}
    />
  );
};

export const GeneralIcon = ({ icon, color, fontSize = 20 }: { icon: string; color: string; fontSize?: number }) => {
  return (
    <SvgIcon
      component={generalIcons[icon]}
      inheritViewBox
      sx={{ color: color, fontSize: fontSize }}
      stroke={'currentColor'}
    />
  );
};
