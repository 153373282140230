import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { BoxFlexColumn, CarTableCell, ColoredButtonSmall } from '../result-table-styles';
import { ShoppingCartButton } from '../../../taxation-and-results-shared/buttons/shopping-cart-button';
import { Link, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

const LanguageButton = styled(ColoredButtonSmall)({
  height: '30px',
  width: '40px',
  marginBottom: '5px',
});

interface ResultUrlToExternalCellProps {
  car: CarResult;
}
export const ResultUrlToExternalCell = React.memo(
  ({ car }: ResultUrlToExternalCellProps) => (
    <CarTableCell width={40}>
      <BoxFlexColumn>
        {car.url && (
          <LanguageButton
            color="primary"
            component={Link}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target="_blank"
            href={car.url}
          >
            <LanguageIcon fontSize="small" />
          </LanguageButton>
        )}
        <ShoppingCartButton car={car} />
      </BoxFlexColumn>
    </CarTableCell>
  ),
  compareMemoProps(['car']),
);
