import React from 'react';
import { styled } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ColoredOutlinedButton } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { CarTableCell } from '../result-table-styles';
import { ellipsify } from '../../../utils/ellipsify';

const Wrapper = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const LicensePlate = styled(`span`)({
  marginBottom: '5px',
  whiteSpace: 'nowrap',
});

const CopyButton = styled(ColoredOutlinedButton)({
  fontSize: '10px',
  height: '20px',
  minWidth: '40px',
});

interface ResultCopyCarIdentifierProps {
  carIdentifier: string;
  replaceDashes?: boolean;
}
export const ResultCopyCarIdentifier = ({ carIdentifier, replaceDashes }: ResultCopyCarIdentifierProps) => {
  const { snackbarSuccessMessage } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onClickCopy = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let textToCopy = carIdentifier;
    if (replaceDashes) {
      textToCopy = textToCopy.replaceAll('-', '');
    }
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        snackbarSuccessMessage(`${carIdentifier} copied`);
      })
      .catch((err) => {
        console.log('Something went wrong', err);
      });
  };

  return (
    <CarTableCell>
      <Wrapper>
        <LicensePlate className="licensePlateValue">{ellipsify(carIdentifier, 30)}</LicensePlate>
        <CopyButton onClick={onClickCopy} className="copyButton">
          {t('COPY')}
        </CopyButton>
      </Wrapper>
    </CarTableCell>
  );
};
