import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { noop } from '../../../utils/noop';
import { InputWrapper, InputWrapperProps } from '../../filter';
import { NumberInput, NumberInputProps } from '../number-input/number-input';

const RangeInputLabel = styled(`span`)({
  fontSize: '13px',
  margin: '0px 12px',
});

export interface RangeInputProps
  extends Omit<NumberInputProps, 'value' | 'setValue' | 'defaultValue' | 'label'>,
    InputWrapperProps {
  value?: [number | undefined, number | undefined];
  defaultValue?: [number | undefined, number | undefined];
  setValue: (value: [number | undefined, number | undefined]) => void;
  title: string;
}

export const RangeInput = ({
  defaultValue,
  value = defaultValue,
  setValue,
  title,
  onReset = noop,
  hideResetButton,
  ...props
}: RangeInputProps) => {
  const { t } = useTranslation();

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onReset}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <NumberInput
          defaultValue={defaultValue?.[0]}
          value={value?.[0]}
          setValue={(val) => setValue([val, value?.[1]])}
          label={title}
          {...props}
        />
        <RangeInputLabel>{t('UNTIL')}</RangeInputLabel>
        <NumberInput
          defaultValue={defaultValue?.[1]}
          value={value?.[1]}
          setValue={(val) => setValue([value?.[0], val])}
          {...props}
        />
      </div>
    </InputWrapper>
  );
};
