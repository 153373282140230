import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterApr = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.APR_FROM, AppSearchParamKeys.APR_TO]}
    defaultValue={[0, 5]}
    min={0}
    max={5}
    labelTranslationKey="APR"
  />
);
