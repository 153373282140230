export enum Body {
  SMALL_CAR = 'SmallCar',
  LIMOUSINE = 'Limousine',
  ESTATE_CAR = 'EstateCar',
  OFF_ROAD = 'OffRoad',
  VAN = 'Van',
  SPORTS_CAR = 'SportsCar',
  MPV = 'MPV',
  CABRIO = 'Cabrio',
  VC_DOUBLE_CABIN = 'VanCommercialDoubleCabin',
  VAN_COMMERCIAL = 'VanCommercial',
  VC_OPEN_BOX_DOUBLE_CABIN = 'VanCommercialOpenBoxDoubleCabin',
  VC_REFRIGERATED = 'VanCommercialRefrigerated',
  VC_CLOSED_BOX = 'VanCommercialClosedBox',
  VC_OPEN_BOX = 'VanCommercialOpenBox',
  VC_TIPPER = 'VanCommercialTipper',
  VC_CHASSIS_CABIN = 'VanCommercialChassisCabin',
}
