import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { LoadingWrapper } from '../loading-wrapper/loading-wrapper';
import { useDebouncedEffect } from '../../hooks/use-debounced-effect';
import { dealerSearch, User, UserRoles } from '../../api/services';

interface DealersSelectProps {
  selectedDealer?: string;
  onSelect: (newDealer: string) => void;
  user: User;
}
export const DealersSelect = ({ onSelect, user, selectedDealer }: DealersSelectProps) => {
  const [dealers, setDealers] = useState<string[]>([]);
  const [value, setValue] = React.useState<string | undefined>(selectedDealer ?? '');
  const [inputValue, setInputValue] = useState<string>(selectedDealer ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setValue(selectedDealer);
  }, [selectedDealer]);

  useEffect(() => {
    if (user.role !== UserRoles.SUPER_ADMIN) {
      setDealers(user.allowed_dealers);
    }
  }, [user, user.allowed_dealers, user.role]);

  useDebouncedEffect(
    () => {
      if (user.role === UserRoles.SUPER_ADMIN) {
        if (inputValue.length > 0) {
          setIsLoading(true);
          dealerSearch({ name: inputValue })
            .then((res) => setDealers(res.results.map((value1) => value1.name)))
            .finally(() => setIsLoading(false));
        } else {
          setDealers([]);
        }
      }
    },
    500,
    [inputValue],
  );

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Autocomplete
        options={dealers}
        value={value}
        disableClearable
        autoHighlight
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelect(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            InputProps={{
              ...params.InputProps,
            }}
            variant="outlined"
          />
        )}
      />
    </LoadingWrapper>
  );
};
