import { makeStyles } from '@mui/styles';
import { DialogContent, DialogTitle, styled } from '@mui/material';

export const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

export const CommentBoxForm = styled(DialogContent)({
  minHeight: '95px',
  maxHeight: '95px',
  display: 'flex',
  position: 'relative',
});

export const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
});

export const CommentBoxText = styled(`input`)({
  width: '100%',
  border: '1px solid #dee1e2',
  padding: '20px 150px 20px 20px',
  maxWidth: '100%',
  maxHeight: '60px',
  height: 'auto !important',
  resize: 'none',
});
