import { styled } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ColoredButton, TextInput } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { RdwPageContainer } from './rdw-page-container';
import { getCarRdw } from '@japieglobal/shared/src/api/services';

// TODO use theme colors
const StyledDiv = styled(`div`)({
  ':nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  ':nth-of-type(even)': {
    backgroundColor: '#D8F7DD',
  },
});

export const RdwGetCar = () => {
  const [licensePlate, setLicensePlate] = useState<string | undefined>(undefined);
  const [response, setResponse] = useState<(string | number | boolean)[] | undefined>(undefined);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    getCarRdw(licensePlate!).then(setResponse).catch(snackbarErrorHandler);
  };

  return (
    <RdwPageContainer onSubmit={onSubmit}>
      <b>Get plate</b>
      <TextInput label={t('LICENSE_PLATE')} value={licensePlate} setValue={setLicensePlate} required />
      {response &&
        Object.entries(response).map(([k, v]) => (
          <StyledDiv key={k}>
            {k}: {v.toString()}
          </StyledDiv>
        ))}
      <ColoredButton type="submit">get</ColoredButton>
    </RdwPageContainer>
  );
};
