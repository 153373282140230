import React, { PropsWithChildren } from 'react';
import { Theme } from './theme-material-ui';
import { LocalStorageKeys } from '../types/local-storage-keys';
import { ThemeMode } from './types';
import { StyledEngineProvider, ThemeProvider } from '@mui/system';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ChristmasTheme } from './christmas-theme/christmas-theme';

export const MuiWrapper = ({ children }: PropsWithChildren) => {
  const localTheme = localStorage.getItem(LocalStorageKeys.THEME) as ThemeMode;
  if (localTheme !== ThemeMode.LIGHT && localTheme !== ThemeMode.DARK) {
    localStorage.setItem(LocalStorageKeys.THEME, ThemeMode.DARK);
  }
  const theme = Theme(localTheme ?? ThemeMode.DARK);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <span id="snow-container">
            <ChristmasTheme />
            {children}
          </span>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
