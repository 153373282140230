import { instance } from './instance';

export interface MapResponse {
  latitude: number;
  longitude: number;
  dealer_name: string;
  location_name: string;
  car_count: number;
  is_customer?: boolean;
}

export const getMap = async (): Promise<MapResponse[]> => instance.get(`/api/map/data`).then((res) => res.data);
