import React from 'react';
import { ColoredButton } from '../../../colored-button';
import { useTranslation } from 'react-i18next';
import { useAppSearchParams } from '../../hooks';

export interface FilterResetButtonProps {
  filterCount?: number;
  onClick?: () => void;
}
export const FilterResetButton = ({ filterCount, onClick }: FilterResetButtonProps) => {
  const [, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();

  const resetAllFilters = () => {
    setSearchParams({}, true);
  };

  return (
    <ColoredButton fullWidth onClick={onClick || resetAllFilters}>
      {t('RESET_ALL_FILTERS')} {typeof filterCount === 'number' && `(${filterCount})`}
    </ColoredButton>
  );
};
