import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterBuildYear = () => {
  const currentYear = new Date().getFullYear();
  return (
    <RangeFilter
      searchParamKey={[AppSearchParamKeys.BUILD_YEAR_FROM, AppSearchParamKeys.BUILD_YEAR_TO]}
      defaultValue={[2005, currentYear]}
      max={currentYear}
      min={2003}
      labelTranslationKey="BUILD_YEAR"
    />
  );
};
