import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { UserActivityWithId } from '../utils/admin-activity-list-mapper';
import { Link } from 'react-router-dom';
import { AdminPageType, AdminQueryStateKey } from '../hooks/admin-query-state';

const useStyles = makeStyles(() => ({
  tableBorder: {
    'borderCollapse': 'collapse',
    'fontSize': '9px',
    '& th': {
      border: '1px solid gray',
      width: '40px',
    },
    '& td': {
      paddingHorizontal: '5px',
      textAlign: 'center',
      border: '1px solid gray',
    },
  },
}));

interface AdminMainActivityListSwitchedRowColProps {
  userActivity: UserActivityWithId[];
  columns: string[];
  onClickRow?: (column: string) => void;
  userId?: string;
}
export const AdminMainActivityListSwitchedRowCol = ({
  userActivity,
  columns,
  onClickRow,
  userId,
}: AdminMainActivityListSwitchedRowColProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  function clickable(column: string) {
    return ['LAST_ACTIVITY', 'TOTAL'].indexOf(column) === -1;
  }
  const rowsWithoutLink = ['LAST_ACTIVITY', 'TOTAL'];

  function buildUrl(activityType, activity) {
    let dateFrom = activity.id;
    let dateTo = activity.id;
    if (!dateFrom) dateFrom = userActivity[0].id;
    if (!dateTo) dateTo = userActivity[userActivity.length - 2].id;
    const url = `/admin/${AdminPageType.ACTIVITY_DETAIL_LIST}?${AdminQueryStateKey.ACTIVITY_TYPE}=${activityType}&${AdminQueryStateKey.DATE_FROM}=${dateFrom}&${AdminQueryStateKey.DATE_TO}=${dateTo}`;
    return userId ? url + `&user_id=${userId}` : url;
  }

  return (
    <table
      style={
        // 20 columns is the amount that fits on the screen without having a wierd view that overflows.
        userActivity.length > 20
          ? { display: 'block', overflowX: 'auto', whiteSpace: 'nowrap' }
          : { display: 'table', width: '100%', whiteSpace: 'nowrap' }
      }
      className={styles.tableBorder}
    >
      <thead>
        <tr>
          <th>-</th>
          {userActivity.map((item) => {
            return <th key={item.label}>{item?.date?.substring(0, 5) || t(item?.label?.toString() || '')}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {columns.map((column) => {
          return (
            <tr key={column}>
              <td>
                {onClickRow && clickable(column) && (
                  <Button
                    style={{ fontSize: '9px', paddingTop: 1, paddingBottom: 1 }}
                    variant="text"
                    onClick={() => onClickRow(column)}
                  >
                    {t(column)}
                  </Button>
                )}
                {(!onClickRow || !clickable(column)) && <span>{t(column)}</span>}
              </td>
              {userActivity.map((value) => {
                return (
                  <td key={value.label}>
                    {value[column as keyof UserActivityWithId] && (
                      <>
                        {!rowsWithoutLink.includes(column) ? (
                          <Link to={buildUrl(column, value)}>{value[column as keyof UserActivityWithId]}</Link>
                        ) : (
                          value[column as keyof UserActivityWithId]
                        )}
                      </>
                    )}
                    {!value[column as keyof UserActivityWithId] && '-'}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
