import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminMainActivityDetailList } from './activity-lists/admin-main-activity-detail-list';
import { AdminMainActivityList } from './activity-lists/admin-main-activity-list';
import { AdminMainCsmReport } from './csm-report/admin-main-csm-report';
import { AdminMainEditUser } from './edit-user/admin-main-edit-user';
import { AdminMainGlobalActivityList } from './activity-lists/admin-main-global-activity-list';
import { AdminMainSuperAdmin } from './tables/admin-main-super-admin';
import { AdminMainUser } from './tables/admin-main-user';
import { AdminPageType, AdminQueryStateType } from './hooks/admin-query-state';
import { AdminMainDealer } from './tables/admin-main-dealer';
import { AdminMainAdmin } from './tables/admin-main-admin';
import { UserRoles } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { AdminSalesReport } from './sales-report/admin-main-sales-report';

interface AdminMainProps {
  adminQueryState: AdminQueryStateType;
  pageType: AdminPageType;
}
export const AdminMain = ({ adminQueryState, pageType }: AdminMainProps) => {
  const { user } = useContext(UserContext);

  const isSuperAdmin = useMemo(() => user.role === UserRoles.SUPER_ADMIN, [user.role]);
  const userFeatures = useMemo(() => user.permissions, [user.permissions]);

  const { t } = useTranslation();

  return (
    <>
      {pageType === AdminPageType.SUPER_ADMINS && <AdminMainSuperAdmin />}
      {pageType === AdminPageType.USERS && (
        <AdminMainUser adminQueryState={adminQueryState} isSuperAdmin={isSuperAdmin} userFeatures={userFeatures} />
      )}
      {pageType === AdminPageType.SERVICE_ACCOUNTS && (
        <AdminMainUser
          adminQueryState={adminQueryState}
          isSuperAdmin={isSuperAdmin}
          userFeatures={userFeatures}
          showServiceAccounts
        />
      )}
      {pageType === AdminPageType.ACTIVITY_PURCHASE_VIEW && (
        <AdminMainUser
          adminQueryState={adminQueryState}
          isSuperAdmin={isSuperAdmin}
          userFeatures={userFeatures}
          fixedActivityType={[
            'EXTERNAL_TINY_URL',
            'EXTERNAL_TINY_URL_AUCTION',
            'EXTERNAL_TINY_URL_PURCHASE',
            'TINY_URL_AUCTION',
            'TINY_URL_PURCHASE',
          ]}
          title={`${t('PURCHASE')} (External/Internal Tiny URLs)`}
        />
      )}
      {pageType === AdminPageType.PURCHASE_ACCOUNTS && (
        <AdminMainUser
          adminQueryState={adminQueryState}
          isSuperAdmin={isSuperAdmin}
          userFeatures={userFeatures}
          showServiceAccounts
          showSuperAdmins
        />
      )}
      {pageType === AdminPageType.KEY_USERS && (
        <AdminMainUser
          adminQueryState={adminQueryState}
          isSuperAdmin={isSuperAdmin}
          userFeatures={userFeatures}
          defaultFilters={[{ filterOn: 'is_key', filter: 'true' }]}
        />
      )}
      {pageType === AdminPageType.SCRAPE_ADMIN_USERS && (
        <AdminMainUser
          adminQueryState={adminQueryState}
          isSuperAdmin={isSuperAdmin}
          userFeatures={userFeatures}
          title={t('SCRAPE_ADMIN_USERS')}
          showSuperAdmins
          defaultFilters={[{ filterOn: 'role', filter: 'SCRAPE_USER' }]}
        />
      )}
      {pageType === AdminPageType.DEALERS && <AdminMainDealer adminQueryState={adminQueryState} />}
      {(pageType === AdminPageType.EDIT_USER || pageType === AdminPageType.ADD_USER) && (
        <AdminMainEditUser pageType={pageType} adminQueryState={adminQueryState} />
      )}
      {pageType === AdminPageType.ADMINS && <AdminMainAdmin adminQueryState={adminQueryState} />}
      {pageType === AdminPageType.ACTIVITY_LIST && <AdminMainActivityList adminQueryState={adminQueryState} />}
      {pageType === AdminPageType.GLOBAL_ACTIVITY_LIST && <AdminMainGlobalActivityList />}
      {pageType === AdminPageType.ACTIVITY_DETAIL_LIST && <AdminMainActivityDetailList />}
      {pageType === AdminPageType.CSM_REPORT && <AdminMainCsmReport />}
      {pageType === AdminPageType.SALES_REPORT && <AdminSalesReport />}
    </>
  );
};
