import React, { PropsWithChildren, createContext, useState } from 'react';

interface HintsContextState {
  hintsEnabled: boolean;
  setHints: (val: boolean) => void;
}

export const HintsContext = createContext<HintsContextState>({ hintsEnabled: false, setHints: () => undefined });

export const HintsProvider = ({ children }: PropsWithChildren) => {
  const [hintsEnabled, setHints] = useState(false);
  return <HintsContext.Provider value={{ hintsEnabled, setHints }}>{children}</HintsContext.Provider>;
};
