import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material';
import { ColoredButton } from '../colored-button';

const TinyResetButtonPositionLeft = styled(`span`)({
  position: 'absolute',
  left: '-30px',
  transform: 'translateY(-50%)',
  top: '50%',
});

const IconStyled = styled(RefreshIcon)({
  height: '14px',
  width: '14px',
});

// This components needs to change either a button component with props to be able to set a icon as content.
// Or a icon button component that needs to have the needed props.
export const TinyResetButton = ({ onClick }: { onClick: () => void }) => (
  <TinyResetButtonPositionLeft>
    <ColoredButton tabIndex={-1} onClick={onClick} sx={{ p: 0.5, minWidth: 20 }}>
      <IconStyled />
    </ColoredButton>
  </TinyResetButtonPositionLeft>
);
