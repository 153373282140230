import React from 'react';
import { Grid2 } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { Tooltip } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';

const useStyles = makeStyles({
  inActiveImage: {
    opacity: 0.6,
    transform: 'scale(0.8)',
  },
});

const DealerImage = styled(`img`)({
  width: '100%',
});

interface CarWindowDealerImageCellProps {
  car: CarResult;
  ownSupply: boolean;
  setOwnSupply: (val: boolean) => void;
  ownDealer: boolean;
}
export const CarWindowDealerImageCell = React.memo(
  ({ car, ownDealer, ownSupply, setOwnSupply }: CarWindowDealerImageCellProps) => {
    const styles = useStyles();
    const { snackbarSuccessMessage } = useSnackbarErrorHandler();

    const onRightClick = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      navigator.clipboard.writeText((car.license_plate || '').replaceAll('-', '')).then(() => {
        snackbarSuccessMessage(`${car.license_plate} copied`);
      });
    };

    const DealerImageContent = () => (
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          setOwnSupply(!ownSupply);
        }}
      >
        <Grid2 container>
          <DealerImage
            onContextMenu={onRightClick}
            className={ownDealer ? '' : styles.inActiveImage}
            src={car.dealer?.logo}
          />
        </Grid2>
      </a>
    );

    return (
      <Tooltip
        onHoverContent={
          <div>
            <p>{`${car.dealer?.name} - ${car.location_name} - ${car.license_plate}`}</p>
            <p style={{ textAlign: 'center', fontSize: 10 }}>(right click to copy)</p>
          </div>
        }
      >
        <DealerImageContent />
      </Tooltip>
    );
  },
);
