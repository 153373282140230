import React, { useContext } from 'react';
import { PageType } from './super-admin-page-container';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { ColoredButton } from '@japieglobal/shared';
import { Box, Link } from '@mui/material';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { settings } from '@japieglobal/shared/src/settings';

// TODO use theme colors
const useStyles = makeStyles(() => ({
  activePage: {
    backgroundColor: 'grey',
  },
}));

export const SuperAdminPageFilter = () => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const SuperAdminPageButton = ({ pageType }: { pageType: PageType }) => (
    <ColoredButton
      component={Link}
      href={`/super-admin/${pageType}`}
      className={`${location.pathname == `/super-admin/${pageType}` ? classes.activePage : ''}`}
    >
      {pageType}
    </ColoredButton>
  );

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
      {settings().country == 'nl' && (
        <>
          <SuperAdminPageButton pageType={PageType.PLATE} />
          <SuperAdminPageButton pageType={PageType.PLATES} />
          <SuperAdminPageButton pageType={PageType.REFRESH} />
          <SuperAdminPageButton pageType={PageType.MODEL_YEAR} />
          {user?.permissions.includes('systemAdmin') && (
            <>
              <SuperAdminPageButton pageType={PageType.MANUAL_ADJUSTMENT} />
              <SuperAdminPageButton pageType={PageType.CATALOG} />
              <SuperAdminPageButton pageType={PageType.CO2_MODEL_YEAR} />
              <SuperAdminPageButton pageType={PageType.TRANSLATIONS} />
              <SuperAdminPageButton pageType={PageType.OPTIONS_MANAGEMENT} />
            </>
          )}
        </>
      )}
      {user?.permissions.includes('systemAdmin') && <SuperAdminPageButton pageType={PageType.CLUSTER_MANAGEMENT} />}
      <SuperAdminPageButton pageType={PageType.MAP} />
      <SuperAdminPageButton pageType={PageType.CAR_ERRORS} />
      <SuperAdminPageButton pageType={PageType.CLICKS_CONFIG} />
    </Box>
  );
};
