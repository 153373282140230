import React from 'react';
import { MenuButton } from '../../menu-button';
import { TopRowMenuButtonProps } from '../../top-row';

export const TaxationButton = ({ internal, user }: TopRowMenuButtonProps) => (
  <MenuButton
    hoverTitle={'TAXATION'}
    display={user.permissions.includes('taxation')}
    internal={internal}
    href="/taxatie"
    style={{ width: 65 }}
  >
    <img src="/images/hyperlink_button_taxation.png" height={30} alt="" />
  </MenuButton>
);
