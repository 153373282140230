import React, { PropsWithChildren } from 'react';
import { TinyResetButton } from '../../../tiny-reset-button/tiny-reset-button';
import { noop } from '../../../../utils/noop';

export interface InputWrapperProps {
  hideResetButton?: boolean;
  onReset?: () => void;
}

export const InputWrapper = ({
  children,
  hideResetButton = false,
  onReset = noop,
}: PropsWithChildren<InputWrapperProps>) =>
  hideResetButton ? (
    <>{children}</>
  ) : (
    <div style={{ position: 'relative' }}>
      {children}
      <TinyResetButton onClick={onReset} />
    </div>
  );
