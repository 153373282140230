import { styled } from '@mui/material';

export const Table = styled(`table`)({
  borderCollapse: 'collapse',
  marginBottom: '10px',
});

export const TableCell = styled(`td`)({
  border: '1px solid #ddd',
  padding: '3px 5px',
});
