import { getDealerUsers } from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { DealerUser } from '@japieglobal/shared/src/types';
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

interface DealerUsersContextState {
  dealerUsers: DealerUser[];
  dealerUsersIsLoading: boolean;
}
export const DealerUsersContext = createContext<DealerUsersContextState>({
  dealerUsers: [],
  dealerUsersIsLoading: false,
});

export const DealerUsersProvider = ({ children }: PropsWithChildren) => {
  const [dealerUsers, setDealerUsers] = useState<DealerUser[]>([]);
  const [dealerUsersIsLoading, setDealerUsersIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  useEffect(() => {
    setDealerUsersIsLoading(true);
    getDealerUsers()
      .then(setDealerUsers)
      .catch(snackbarErrorHandler)
      .finally(() => setDealerUsersIsLoading(false));
  }, [setDealerUsers, snackbarErrorHandler]);

  return (
    <DealerUsersContext.Provider value={{ dealerUsers, dealerUsersIsLoading }}>{children}</DealerUsersContext.Provider>
  );
};
