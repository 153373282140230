import React, { useState } from 'react';
import { BaseTextInput } from '@japieglobal/shared/src/components/inputs/base-text-input/base-text-input';
import { InputAdornment, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface PasswordConfirmationProps {
  password: string;
  setPassword: (password: string) => void;
  labelTranslationKey: string;
}

export const PasswordInput = ({ password, setPassword, labelTranslationKey }: PasswordConfirmationProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <BaseTextInput
      required
      label={t(labelTranslationKey)}
      name="password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      setValue={setPassword}
      onChange={setPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
