import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterWindowSize = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.WINDOW_SIZE_FROM, AppSearchParamKeys.WINDOW_SIZE_TO]}
    defaultValue={[0, undefined]}
    min={0}
    labelTranslationKey="WINDOW_SIZE"
  />
);
