import { Link, styled } from '@mui/material';
import React from 'react';
import { useCarWindowStyles } from '../car-window-styles';

const ClickLinkStyled = styled(Link)({
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2px 3px',
  borderRadius: '3px',
});

export const ClickLink = React.memo(function ClickLink({
  children,
  value,
  setValue,
}: {
  children: React.ReactElement | string;
  value: boolean;
  setValue: (val: boolean) => void;
}) {
  const styles = useCarWindowStyles();
  return (
    <ClickLinkStyled
      className={value === true ? styles.activeLink : ''}
      href="#"
      onClick={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        setValue(!value);
      }}
    >
      {children}
    </ClickLinkStyled>
  );
});
