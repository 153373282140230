import React from 'react';
import { Box } from '@mui/material';
import { Droppable } from '@hello-pangea/dnd';
import { SelectOption } from '../inputs';
import { LoadingWrapper } from '../loading-wrapper/loading-wrapper';
import { ColoredButton } from '../colored-button';
import { SelectedListItem } from './selected-list-item';
import { useTranslation } from 'react-i18next';

export const DROPPABLE_SELECTED = 'droppable_selected';
export const DROPPABLE_LEFTOVERS = 'droppable_leftovers';
export type DroppableId = typeof DROPPABLE_SELECTED | typeof DROPPABLE_LEFTOVERS;

interface DroppableContainerProps {
  title: string;
  items: SelectOption[];
  droppableId: DroppableId;
  onSelectAll: (droppableId: DroppableId) => void;
  selectAllText: string;
  isLoading: boolean;
  onSetDefault?: () => void;
}
export const DroppableContainer = ({
  droppableId,
  items,
  title,
  onSelectAll,
  selectAllText,
  isLoading,
  onSetDefault,
}: DroppableContainerProps) => {
  const { t } = useTranslation();
  return (
    <Box flex={1}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          justifyContent: 'space-between',
          width: 'max-content',
        }}
      >
        <span>{title}</span>

        {onSetDefault ? (
          <div style={{ marginBottom: '10px' }}>
            {onSetDefault && (
              <ColoredButton
                sx={{ fontSize: '0.8rem', padding: '2px 8px', marginRight: '10px' }}
                onClick={onSetDefault}
              >
                {t('SELECT_DEFAULTS')}
              </ColoredButton>
            )}
            <ColoredButton sx={{ fontSize: '0.8rem', padding: '2px 8px' }} onClick={() => onSelectAll(droppableId)}>
              {selectAllText}
            </ColoredButton>
          </div>
        ) : (
          <ColoredButton
            sx={{ fontSize: '0.8rem', padding: '2px 8px', marginBottom: '10px' }}
            onClick={() => onSelectAll(droppableId)}
          >
            {selectAllText}
          </ColoredButton>
        )}
      </div>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div style={{ height: '100%' }} {...provided.droppableProps} ref={provided.innerRef}>
            <LoadingWrapper isLoading={isLoading}>
              {items.map((item, index) => (
                <SelectedListItem key={item.value} item={item} index={index} />
              ))}
            </LoadingWrapper>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};
