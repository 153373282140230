import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { RestoreFromTrash } from '@mui/icons-material';
import { Input } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '@japieglobal/shared';
import { banCar, unBanCar, UserRoles } from '@japieglobal/shared/src/api/services';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { settings } from '@japieglobal/shared/src/settings';
import { UserContext } from '@japieglobal/shared/src/user-context';
import Checkbox from '@mui/material/Checkbox';

interface CarWindowBanCellProps {
  ownDealer: boolean;
  ownCar: CarResult;
  car: CarResult;
  taxationView: boolean;
}

export const CarWindowBanCell = React.memo(({ ownCar, car, ownDealer, taxationView }: CarWindowBanCellProps) => {
  const { t } = useTranslation();
  const [showDeleteCarDialog, setShowDeleteCarDialog] = useState<boolean>(false);
  const [showRestoreCarDialog, setShowRestoreCarDialog] = useState<boolean>(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const navigate = useNavigate();
  const [banReason, setBanReason] = useState<string>('');
  const [globalBan, setGlobalBan] = useState(false);
  const { user } = useContext(UserContext);

  const handleDeleteCar = () => {
    let windowCarId, taxationUrl;
    if (taxationView) taxationUrl = window.location.href;
    else windowCarId = ownCar.id;
    banCar(car.id!, banReason, globalBan, windowCarId, taxationUrl)
      .then(() => navigate(0))
      .catch(snackbarErrorHandler)
      .finally(() => setShowDeleteCarDialog(false));
  };

  const handleRestoreCar = () => {
    unBanCar(car.id!)
      .then(() => navigate(0))
      .catch(snackbarErrorHandler)
      .finally(() => setShowRestoreCarDialog(false));
  };
  if (car.country?.toLowerCase() === settings().country) {
    return (
      <>
        {!(ownDealer && car.equipment?.in_stock) && !car.is_banned && (
          <DeleteForeverIcon color="warning" onClick={() => setShowDeleteCarDialog(true)} />
        )}
        {car.is_banned && <RestoreFromTrash onClick={() => setShowRestoreCarDialog(true)} />}
        {showDeleteCarDialog && (
          <ConfirmationDialog
            closeDialog={() => setShowDeleteCarDialog(false)}
            title={t('BAN_CAR_TITLE')}
            question={t('BAN_CAR_QUESTION')}
            yesAction={() => handleDeleteCar()}
            noAction={() => setShowDeleteCarDialog(false)}
            disabled={!banReason.length}
          >
            <>
              {t('BAN_REASON')} :
              <Input
                autoFocus
                style={{ marginLeft: '5px' }}
                value={banReason}
                onChange={(e) => setBanReason(e.target.value)}
              />
              {user.role === UserRoles.SUPER_ADMIN && (
                <div style={{ flexFlow: 'row' }}>
                  <span>{t('GLOBAL_BAN')}</span>
                  <Checkbox checked={globalBan} onChange={() => setGlobalBan(!globalBan)} />
                </div>
              )}
            </>
          </ConfirmationDialog>
        )}
        {showRestoreCarDialog && (
          <ConfirmationDialog
            closeDialog={() => setShowRestoreCarDialog(false)}
            title={t('UNBAN_CAR_TITLE')}
            question={t('UNBAN_CAR_QUESTION')}
            yesAction={handleRestoreCar}
            noAction={() => setShowRestoreCarDialog(false)}
          />
        )}
      </>
    );
  }
});
