import { CarWindowResult } from '@japieglobal/shared/src/api/services';
import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledWrapper = styled(`div`)({
  minHeight: '50vh',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '20px',
});

const StyledInner = styled(`div`)({
  margin: '20px',
});

interface TaxationEmptyView {
  windowResult?: CarWindowResult;
}
export const TaxationErrorView = ({ windowResult }: TaxationEmptyView) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledInner>
        {!windowResult && t('FILL_ALL_FIELDS_TO_APPRAISE')}
        {windowResult?.error && t(windowResult.error)}
      </StyledInner>
    </StyledWrapper>
  );
};
