import React from 'react';
import { SelectFilter, AppSearchParamKeys, SelectOption } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { DataType } from '@japieglobal/shared/src/api/services';

export const DataTypeFilter = () => {
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: DataType.MONITOR, label: t(DataType.MONITOR) },
    { value: DataType.PURCHASE, label: t(DataType.PURCHASE) },
    { value: DataType.API, label: t(DataType.API) },
  ];
  return (
    <SelectFilter
      hideResetButton
      searchParamKey={AppSearchParamKeys.DATA_TYPE}
      labelTranslationKey="DATA"
      staticOptions={options}
      defaultValue={options[0]}
    />
  );
};
