import React, { useCallback, useState } from 'react';
import { AuthLink } from './auth-styled-components';
import { AuthStyleContainer } from './auth-style-container';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { useTranslation } from 'react-i18next';
import { AuthTwoFactorComponent } from './auth-two-factor-component';
import { PasswordConfirmation } from '../components/auth/password-confirmation';
import { AppSearchParamKeys, ColoredButton, useAppSearchParams } from '@japieglobal/shared';
import { Box } from '@mui/material';
import { login, resetPassword } from '@japieglobal/shared/src/api/services';

export const AuthResetPage = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [searchParams] = useAppSearchParams();
  const [showTwoFactorComponent, setShowTwoFactorComponent] = useState<boolean>(false);
  const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(false);

  const { t } = useTranslation();
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const token = searchParams[AppSearchParamKeys.TOKEN];

  const submit = useCallback(() => {
    if (!token || !newPassword || !confirmNewPassword) {
      setAlertMessage(`Please fill out both passwords`);
      return;
    }

    resetPassword({ token, password: newPassword })
      .then((user) =>
        login({ email: user.email, password: newPassword }).then(() => {
          if (user.two_factor_authentication) {
            setShowTwoFactorComponent(true);
            setHasPhoneNumber(!!user.phone_number);
          } else window.location.reload();
        }),
      )
      .catch(snackbarErrorHandler);
  }, [confirmNewPassword, newPassword, snackbarErrorHandler, token]);

  return (
    <AuthStyleContainer
      alertMessage={alertMessage}
      infoMessage={showTwoFactorComponent ? t('TWO_FACTOR_EXPLANATION') : undefined}
    >
      {!showTwoFactorComponent ? (
        <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
          <span>{t('CHOOSE_NEW_PASSWORD')}</span>
          <PasswordConfirmation
            password={newPassword}
            setPassword={setNewPassword}
            passwordAgain={confirmNewPassword}
            setPasswordAgain={setConfirmNewPassword}
            setIsPasswordValid={setIsPasswordValid}
          />
          <ColoredButton disabled={!isPasswordValid} onClick={submit}>
            {t('SUBMIT')}
          </ColoredButton>
        </Box>
      ) : (
        <AuthTwoFactorComponent
          successAction={() => window.location.reload()}
          hasPhoneNumber={hasPhoneNumber}
          setHasPhoneNumber={setHasPhoneNumber}
        />
      )}
      <AuthLink to="/login">{t('BACK_TO_LOGIN')}</AuthLink>
    </AuthStyleContainer>
  );
};
