import React from 'react';
import { NumberFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const TaxationFilterMaturityMonths = () => (
  <NumberFilter
    max={120}
    labelTranslationKey="MATURITY_MONTHS"
    searchParamKey={AppSearchParamKeys.MATURITY_MONTHS}
    className={'maturityMonthsFilter'}
  />
);
