import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { MenuButton } from '../../menu-button';
import { setLanguage } from '../../../../i18n/utils';

export const TranslationButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { t, i18n } = useTranslation();

  const ActiveFlag = useMemo(() => {
    if (!i18n.language) return null;
    return <img alt="" width={20} src={`/images/flags/${i18n.language.toUpperCase()}.png`} />;
  }, [i18n.language]);

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleMenuItemClick = (lang: string) => () => {
    setAnchorEl(undefined);
    setLanguage(lang, i18n);
  };

  return (
    <>
      <MenuButton hoverTitle={'LANGUAGE'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {ActiveFlag}
      </MenuButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleMenuItemClick('nl')}>
          <img alt="Dutch flag" width={20} src="/images/flags/NL.png" />
          &nbsp;{t('DUTCH')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick('en')}>
          <img alt="English flag" width={20} src="/images/flags/EN.png" />
          &nbsp;{t('ENGLISH')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick('fr')}>
          <img alt="French flag" width={20} src="/images/flags/FR.png" />
          &nbsp;{t('FRENCH')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick('cs')}>
          <img alt="Czech flag" width={20} src="/images/flags/CS.png" />
          &nbsp;{t('CZECH')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick('de')}>
          <img alt="German flag" width={20} src="/images/flags/DE.png" />
          &nbsp;{t('GERMAN')}
        </MenuItem>
        <MenuItem onClick={handleMenuItemClick('se')}>
          <img alt="Sweden flag" width={20} src="/images/flags/SE.png" />
          &nbsp;{t('SWEDISH')}
        </MenuItem>
      </Menu>
    </>
  );
};
