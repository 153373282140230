import React, { PropsWithChildren } from 'react';
import { ColoredButton } from '../colored-button';
import { useNavigate } from 'react-router-dom';
import { ButtonProps } from '@mui/material';
import { Tooltip } from '../tooltip/tooltip';
import { useTranslation } from 'react-i18next';

export interface MenuButtonProps extends ButtonProps {
  internal?: boolean;
  link?: string;
  display?: boolean;
  hoverTitle: string;
}

export const MenuButton = ({
  internal,
  link,
  children,
  display = true,
  hoverTitle,
  // this has to be dissabled because if you use styled from system, you cant use sx anymore,
  // so we cant pass it to coloredButton
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sx,
  ...rest
}: PropsWithChildren<MenuButtonProps>) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!display) {
    return <></>;
  }

  const navigation = internal ? { onClick: () => navigate(link || '') } : { href: link };

  const buttonProps = link ? navigation : undefined;

  return (
    <Tooltip onHoverContent={t(hoverTitle)}>
      <ColoredButton style={{ height: '42px' }} {...rest} {...buttonProps}>
        {children}
      </ColoredButton>
    </Tooltip>
  );
};
