import React, { useEffect, useMemo, useState } from 'react';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { LoadingWrapper } from '@japieglobal/shared/src/components/loading-wrapper/loading-wrapper';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { buildActivitiesStats, UserActivityWithId } from '../utils/admin-activity-list-mapper';
import { useTimeSpan } from '../shared/period-select';
import { AdminMainActivityListSwitchedRowCol } from './admin-main-activity-list-switched-row-col';
import { AdminQueryStateType } from '../hooks/admin-query-state';
import { useTranslation } from 'react-i18next';
import { getUserActivity, getUserById } from '@japieglobal/shared/src/api/services';
import { User } from '@japieglobal/shared/src/api/services';

interface AdminMainActivityListProps {
  adminQueryState: AdminQueryStateType;
}
export const AdminMainActivityList = ({ adminQueryState }: AdminMainActivityListProps) => {
  const { userId } = adminQueryState;
  const [user, setUser] = useState<User | undefined>(undefined);
  const [activity, setActivity] = useState<UserActivityWithId[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const { t } = useTranslation();

  const { selectPeriod, dateFrom, dateTo } = useTimeSpan();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const title = useMemo(() => {
    if (!user) {
      return `${t('ACTIVITY_LIST_OF')}: `;
    }
    let name = `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim();
    if (name.length === 0) {
      name = `N/A`;
    }

    return `${t('ACTIVITY_LIST_OF')}: ${name} (${user.email})`;
  }, [user, t]);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserById({ userId })
        .then(setUser)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, userId]);

  useEffect(() => {
    if (userId && dateFrom) {
      setIsLoading(true);
      getUserActivity(userId, { date_from: dateFrom, date_to: dateTo })
        .then((res) => {
          const { rows, stats } = buildActivitiesStats(res);
          setColumns(rows);
          return stats;
        })
        .then(setActivity)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, dateFrom, dateTo, userId]);

  return (
    <AdminMainWrapper title={title} titleRightComponent={<div>{selectPeriod}</div>}>
      <LoadingWrapper isLoading={isLoading}>
        <AdminMainActivityListSwitchedRowCol userActivity={activity} columns={columns} userId={userId} />
      </LoadingWrapper>
    </AdminMainWrapper>
  );
};
