import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { SelectInput, SelectOption } from '@japieglobal/shared';
import { useTranslation } from 'react-i18next';
import { OptionSelectProps } from './type';
import { carHeader } from '@japieglobal/shared/src/api/services/common';
import { CarHeader } from '@japieglobal/shared/src/api/services';

export const ModelSelect = ({ setValue, value, standardOption }: OptionSelectProps) => {
  const [models, setModels] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t } = useTranslation();

  const getModels = useCallback(() => {
    if (standardOption?.make) {
      setIsLoading(true);
      carHeader({
        type: CarHeader.MODEL,
        make: standardOption.make,
        fuel: standardOption.fuel ?? undefined,
        body: standardOption.body ?? undefined,
        hp: standardOption.hp ?? undefined,
        build: standardOption.build ?? undefined,
        dealer_specific: false,
      })
        .then((data) => setModels((data.headers as string[]).map((model) => ({ label: model, value: model }))))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [snackbarErrorHandler, standardOption]);

  useEffect(() => {
    getModels();
  }, [getModels]);

  return (
    <SelectInput
      isLoading={isLoading}
      disabled={!standardOption?.make}
      label={t('MODEL')}
      options={models}
      setValue={setValue}
      value={value}
    />
  );
};
