import { LoadingWrapper } from '../../..';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useRef } from 'react';
import { BaseTextInput, BaseTextInputProps } from '../../base-text-input/base-text-input';
import { noop } from '../../../../utils/noop';

export interface SelectOption {
  value: string;
  label: string;
}
export interface SelectInputProps extends BaseTextInputProps<SelectOption> {
  isLoading?: boolean;
  options: SelectOption[];
  freeSolo?: boolean;
  disableSelectOnTab?: boolean;
  hideArrowIcon?: boolean;
}
export const emptySelectOption: SelectOption = { label: '', value: '' };

export const SelectInput = ({
  isLoading = false,
  setValue,
  value,
  options,
  disabled,
  defaultValue,
  freeSolo = false,
  disableSelectOnTab = false,
  tabOnEnter,
  hideArrowIcon,
  ...baseProps
}: SelectInputProps) => {
  const lastHighlightedOption = useRef<SelectOption | null>();

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Autocomplete
        popupIcon={hideArrowIcon === true ? false : undefined}
        onHighlightChange={(_, twee) => {
          if (lastHighlightedOption && !disableSelectOnTab) {
            lastHighlightedOption.current = twee;
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Tab' && lastHighlightedOption && !disableSelectOnTab) {
            setValue(lastHighlightedOption.current as SelectOption);
          }
        }}
        openOnFocus
        freeSolo={freeSolo}
        options={options}
        disableClearable
        autoHighlight
        isOptionEqualToValue={(option, value) => value.value === '' || option.value === value.value}
        disabled={disabled}
        value={value || defaultValue || emptySelectOption}
        getOptionLabel={(option) => (typeof option === 'string' ? option : String(option.label))}
        onInputChange={freeSolo ? (_, targetValue) => setValue({ label: targetValue, value: targetValue }) : undefined}
        onChange={!freeSolo ? (_, targetValue) => setValue(targetValue as SelectOption) : undefined}
        renderInput={(params) => (
          <BaseTextInput
            {...params}
            {...baseProps}
            tabOnEnter={tabOnEnter}
            onChange={noop}
            setValue={noop}
            size="small"
            margin="none"
          />
        )}
      />
    </LoadingWrapper>
  );
};
