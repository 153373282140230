import 'i18next';
import i18n from 'i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { getProtocolAndDomain } from '../api/utils';

export enum TranslationFileType {
  FRONTEND = 'frontend',
  MARKET = 'market',
  MOBILE_APP = 'mobile-app',
  ADMIN = 'admin',
  PURCHASE = 'purchase',
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const i18next = (fileType: TranslationFileType) =>
  i18n
    .use(ChainedBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ns: fileType,
      fallbackLng: false,
      returnNull: false,
      detection: {
        order: ['cookie'],
        caches: ['cookie'],
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            expirationTime: 24 * 60 * 60 * 1000, // 1 day
          },
          {
            loadPath: `${getProtocolAndDomain()}/translations?language={{lng}}&file_type={{ns}}`,
            withCredentials: true,
          },
        ],
      },
    });
