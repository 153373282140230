import React from 'react';
import { SelectFilter, AppSearchParamKeys, SelectOption } from '@japieglobal/shared/src/components';
import { Scope } from '@japieglobal/shared/src/types';
import { useTranslation } from 'react-i18next';

export const TaxationFilterScope = () => {
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: 'TAXATION', label: t(Scope.TAXATION) },
    { value: 'API', label: t(Scope.API) },
    { value: 'MONITORING', label: t(Scope.MONITORING) },
    { value: 'PURCHASE', label: t(Scope.PURCHASE) },
  ];
  return <SelectFilter searchParamKey={AppSearchParamKeys.SCOPE} labelTranslationKey="SCOPE" staticOptions={options} />;
};
