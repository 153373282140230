import { ClickState } from '../../../shared/src/types/click-state';

export const clickStateParser = (val: ClickState | undefined) => {
  if (val === ClickState.ALL || val === undefined) return ClickState.LOCK;
  if (val === ClickState.ANTI_LOCK) return ClickState.ALL;
  if (val === ClickState.LOCK) {
    return ClickState.ANTI_LOCK;
  }
  throw Error('should not happend');
};
