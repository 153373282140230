import React, { useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { MultiSelectFilter, SelectOption, StringListAppSearchParam } from '@japieglobal/shared';
import { ColoredButton } from '@japieglobal/shared';

interface HeaderMultiFilterProps {
  filters?: string[];
  searchParamKey: string;
  options: SelectOption[];
  disableClearable?: boolean;
}

export const HeaderMultiFilter = ({
  filters,
  searchParamKey,
  options,
  disableClearable = false,
}: HeaderMultiFilterProps) => {
  const [element, setElement] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setElement(event.currentTarget);
  };

  const handleClose = () => {
    setElement(null);
  };
  const open = Boolean(element);

  useEffect(() => {
    handleClose();
  }, [filters]);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <ColoredButton onClick={handleClick} sx={{ minWidth: 0 }}>
        <Badge badgeContent={filters ? filters.length : 0} color="secondary" variant="dot">
          <FilterAltIcon fontSize="medium" />
        </Badge>
      </ColoredButton>
      <Popover
        open={open}
        anchorEl={element}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 300 }}>
          <MultiSelectFilter
            hideArrowIcon
            hideResetButton
            labelTranslationKey={searchParamKey}
            staticOptions={options}
            searchParamKey={searchParamKey as StringListAppSearchParam}
            disableClearable={disableClearable}
          />
        </Box>
      </Popover>
    </div>
  );
};
